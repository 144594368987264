import React from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { Layout, Card } from '../../shared';
import SupportFooter from '../../shared/support-footer/SupportFooter';
import './not-found.sass';

const NotFound = () => {
  useTitle('Oida, noe gikk galt | RiksTV');

  return (
    <Layout className="main-layout" focusOnRender wide={true}>
      <Card className="not-found">
        <h1>Oida, noe gikk galt...</h1>

        <p className="top-text">
          Vi finner dessverre ikke siden du leter etter. <br />
          Gå tilbake til{' '}
          <a className="link-style" href="https://www.rikstv.no">
            forsiden
          </a>
          .
        </p>

        <p>Ønsker du å bestille? Ring oss eller ta kontakt på chat, så hjelper vi deg.</p>

        <SupportFooter displayHeading={false} />
      </Card>
    </Layout>
  );
};

export default NotFound;
