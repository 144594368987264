import React from 'react';

import './information-box.sass';

interface Props {
  icon: string;
  color: string;
  title: string;
  infoText: string;
  link?: string;
  linkText?: string;
}


const InformationBox: React.FC<Props> = ({icon, color, title, infoText, link, linkText }) => {
  return (
    <>
    <div className={'information-box ' + color}>
      <div className='top-container'>
        <img src={icon} alt="logo" className='information-icon'></img>
        <h4 className="title-4">{title}</h4>
      </div>
      <div style={{padding: '8px 0px 0px 35px'}}>
        <span style={{justifyContent: 'flex-end'}}>
          {infoText}
        </span>
        {
          link &&
          <a href={link} target="_blank">
            <h4 className="title-4" style={{color: 'black', fontSize: '18px', marginTop: '8px' }}>{linkText}</h4>
          </a>
        }
        
      </div>
    </div>
    </>
  );
};

export default InformationBox;