import { createSelector } from 'reselect';
import { RootState } from '..';
import { ChannelType } from '../../models/channel/ChannelType';
import { LoadingStatus } from '../../models/loading';

// Loading status
export const isLoading = (state: RootState) => state.channels.status === LoadingStatus.Loading;
export const isLoaded = (state: RootState) => state.channels.status === LoadingStatus.Loaded;
export const isFailed = (state: RootState) => state.channels.status === LoadingStatus.Failed;
export const getChannelList = (state: RootState) => state.channels.channelList;
export const getTvChannelList = createSelector([getChannelList], (channels) =>
  channels.filter((c) => c.channelType === ChannelType.TvChannel)
);
export const getOpenChannelList = createSelector([getChannelList], (channels) => channels.filter((c) => c.isOpen));
export const getAlaCarteChannelList = createSelector([getTvChannelList], (channels) => channels.filter((c) => c.isAlaCarte));
export const getStandardChannelList = createSelector([getTvChannelList], (channels) => channels.filter((c) => !c.isAlaCarte));

export const getRadioChannelList = createSelector([getChannelList], (channels) =>
  channels.filter((c) => c.channelType === ChannelType.RadioChannel)
);
export const getClosedTvChannelList = createSelector([getChannelList], (channels) =>
  channels.filter((c) => c.channelType === ChannelType.TvChannel && !c.isOpen)
);

export const getChannelCategories = (state: RootState) => {
  const categories = getChannelList(state)
    .map((c) => c.categories)
    .flatMap((x) => x);
  return Array.from(new Set(categories));
};

export const hasAlaCarteChannels = createSelector([getChannelList], (channels) => channels.findIndex((channel) => channel.isAlaCarte) > -1);

export const getChannelListWithCategories = (state: RootState) =>
  hasAlaCarteChannels(state)
    ? getChannelListWithCategoriesForAlacarteProducts(state)
    : getChannelListWithCategoriesForNormalProducts(state);

export const getChannelListWithCategoriesForNormalProducts = createSelector(
  [getChannelCategories, getTvChannelList],
  (categories, channels) => {
    const mapped = categories.map((category) => ({
      title: category,
      channelList: channels.filter((channel) => channel.categories.includes(category)),
    }));
    return mapped;
  }
);

export const getChannelListWithCategoriesForAlacarteProducts = createSelector(
  [getAlaCarteChannelList, getStandardChannelList],
  (alaCarteChannels, standardChannels) => [
    {
      title: 'Inkluderte kanaler',
      channelList: standardChannels,
    },
    {
      title: 'Valgfrie kanaler',
      channelList: alaCarteChannels,
    },
  ]
);
