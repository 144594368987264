import React, { useState } from 'react';
import classNames from 'classnames';

import { SanityGenericSubscription } from '../../../../../models/customer-filter/CustomerFilter';
import { Card, ExternalLink, Icon } from '../../../../shared';

const GenericSubscription: React.FC<{ genericSubscription: SanityGenericSubscription }> = ({ genericSubscription }) => {
  const [mobileExpanded, setMobileExpanded] = useState<boolean>(false);

  return (
    <Card className={classNames({ 'mobile-expanded': mobileExpanded })}>
      <h3 className="tag" onClick={() => setMobileExpanded(!mobileExpanded)}>
        {genericSubscription.name}
        <Icon type="chevron" className="chevron" size={2} flip={mobileExpanded ? 'vertical' : undefined} />
      </h3>

      <div className="mobile-expandable">
        {genericSubscription.price !== 0 && (
          <p className="price title-4">
            fra {genericSubscription.price} kr<span className="sub-body">/mnd</span>
          </p>
        )}
        <p className="description">{genericSubscription.description}</p>

        <ExternalLink className="link-style" href={genericSubscription.detailsUrl}>
          Les mer om {genericSubscription.name}
        </ExternalLink>
      </div>
    </Card>
  );
};

export default GenericSubscription;
