import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { checkout } from '../../../../../store';
import { composeValidators, email, phone, required, date, zip, text, minAge, invalidComma } from '../../../../../utils/validation';
import FormMetaToRedux from './form-meta-to-redux/FormMetaToRedux';
import FormValuesToRedux from './form-values-to-redux/FormValuesToRedux';
import { Icon, TextField } from '../../../../shared';
import { useSteps } from '../../components/steps/StepsContext';
import './checkout-form.sass';
import { useIsMobileView } from '../../../../../hooks/useMediaQuery';

const focusOnError = createDecorator();

const CheckoutForm = ({ formValues, submitForm }) => {
  const { isStepValid, currentStep, setOnNext, navigate } = useSteps();
  const ref = useRef(null);
  const isMobileView = useIsMobileView();

  const onNext = useCallback(() => {
    submitForm();
    if (!isStepValid(currentStep)) {
      // Dispatch submit even to trigger form focusing through react-final-form
      ref.current.dispatchEvent(new Event('submit', { cancelable: true }));
    } else {
      navigate(currentStep + 1);
    }
  }, [submitForm, currentStep, isStepValid, navigate]);

  useEffect(() => {
    setOnNext(currentStep, onNext);
  }, [setOnNext, currentStep, onNext]);

  return (
    <div className="checkout-form">
      <Form onSubmit={() => {}} decorators={[focusOnError]} initialValues={formValues}>
        {({ handleSubmit }) => (
          <form id="checkoutForm" ref={ref} onSubmit={handleSubmit}>
            <FormValuesToRedux />
            <FormMetaToRedux />
            {!isMobileView && (
              <>
                <div className="dual">
                  <Field
                    id="email"
                    name="email"
                    label="E-post"
                    autoComplete="email"
                    component={TextField}
                    className="email"
                    validate={composeValidators(required('E-postadressen må fylles ut'), email)}
                  />

                  <Field
                    type="tel"
                    id="mobilePhone"
                    name="mobilePhone"
                    label="Mobilnummer"
                    autoComplete="tel-national"
                    component={TextField}
                    className="phone"
                    disabled={formValues.mobilePhone ?? false}
                    validate={composeValidators(required('Mobilnummer må fylles ut'), phone)}
                  />
                </div>
                <div className="dual">
                  <Field
                    id="firstName"
                    name="firstName"
                    label="Fornavn"
                    autoComplete="given-name"
                    component={TextField}
                    className="name"
                    disabled={formValues.firstName ?? false}
                    validate={composeValidators(required('Fornavn må fylles ut'), text('Ugyldig fornavn'))}
                  />

                  <Field
                    id="lastName"
                    name="lastName"
                    label="Etternavn"
                    autoComplete="family-name"
                    component={TextField}
                    className="name"
                    disabled={formValues.lastName ?? false}
                    validate={composeValidators(required('Etternavn må fylles ut'), text('Ugyldig etternavn'))}
                  />
                </div>
              </>
            )}
            {isMobileView && (
              <>
                <Field
                  id="email"
                  name="email"
                  label="E-post"
                  autoComplete="email"
                  component={TextField}
                  className="email"
                  validate={composeValidators(required('E-postadressen må fylles ut'), email)}
                />

                <Field
                  type="tel"
                  id="mobilePhone"
                  name="mobilePhone"
                  label="Mobilnummer"
                  autoComplete="tel-national"
                  component={TextField}
                  className="phone"
                  disabled={formValues.phoneNumber ?? false}
                  validate={composeValidators(required('Mobilnummer må fylles ut'), phone)}
                />
                <Field
                  id="firstName"
                  name="firstName"
                  label="Fornavn"
                  autoComplete="given-name"
                  component={TextField}
                  className="name"
                  disabled={formValues.firstName ?? false}
                  validate={composeValidators(required('Fornavn må fylles ut'), text('Ugyldig fornavn'))}
                />

                <Field
                  id="lastName"
                  name="lastName"
                  label="Etternavn"
                  autoComplete="family-name"
                  component={TextField}
                  className="name"
                  disabled={formValues.lastName ?? false}
                  validate={composeValidators(required('Etternavn må fylles ut'), text('Ugyldig etternavn'))}
                />
              </>
            )}
            <hr />
            <Field
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              id="birthDate"
              name="birthDate"
              label="Fødselsdato (ddmmåå)"
              autoComplete="bday"
              hint="ddmmåå"
              component={TextField}
              className="birth-date"
              disabled={formValues.birthDate ?? false}
              validate={composeValidators(required('Fødselsdato må fylles ut'), date, minAge(18))}
            />
            <Field
              id="street"
              name="street"
              label="Adresse"
              autoComplete="street-address"
              component={TextField}
              className="address"
              validate={composeValidators(required('Adresse må fylles ut'), invalidComma)}
            />
            <div className="dual">
              <Field
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                id="zip"
                name="zip"
                label="Postnr."
                aria-label="Postnummer"
                autoComplete="postal-code"
                component={TextField}
                className="zip"
                validate={composeValidators(required('Postnummer må fylles ut'), zip)}
              />

              <Field
                id="city"
                name="city"
                label="Poststed"
                autoComplete="address-level2"
                component={TextField}
                className="city"
                validate={composeValidators(required('Poststed må fylles ut'), text('Ugyldig poststed'))}
              />
            </div>
          </form>
        )}
      </Form>
      <div className="information">
        <Icon type="informationCircleBold" className="icon" size={1} />
        <p>Du trenger ikke å spesifisere leilighetsnummer eller postboks i adressen</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  formValues: checkout.selectors.getFormValues(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      submitForm: checkout.creators.submitForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
