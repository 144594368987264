import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { app, RootState } from '../../../store';
import { useIsIframe } from '../../../hooks/useIsIframe';
import Icon from '../icons/Icon';

import './support-footer.sass';

interface Props {
  displayHeading?: boolean;
  accommodateShoppingCart?: boolean;
}

const SupportFooter = ({ displayHeading = true, accommodateShoppingCart = false }: Props) => {
  const { isSummaryOpen } = useSelector((state: RootState) => mapState(state));

  const isIframe = useIsIframe();

  if (isIframe) {
    return null;
  }

  const classNames = classnames('support-footer', { 'accommodate-shopping-cart': accommodateShoppingCart });

  return (
    <footer className={classNames} role="contentinfo" aria-hidden={isSummaryOpen}>
      {displayHeading && <h1 tabIndex={-1}>Lurer du på noe?</h1>}
      <ul>
        <li>
          <a href="tel:21010210" target="_blank" rel="noopener noreferrer">
            <Icon type="phone" size={1.5} />
            Ring oss på 210 10 210
          </a>
        </li>

        <li>
          <a
            href="https://www.rikstv.no/kontakt/chat/"
            target="_blank"
            rel="noopener noreferrer"
            data-track-id="footer-chat"
            data-track-link-name="Chat med oss">
            <Icon type="message" size={1.5} /> Chat med oss
          </a>
        </li>

        <li>
          <a
            href="mailto:kundeservice@rikstv.no"
            target="_blank"
            rel="noopener noreferrer"
            data-track-id="footer-email"
            data-track-link-name="Send oss en e-post">
            <Icon type="email" size={1.5} />
            Send oss en e-post
          </a>
        </li>
      </ul>
    </footer>
  );
};

const mapState = (state: RootState) => ({
  isSummaryOpen: app.selectors.isSummaryOpen(state),
});

export default SupportFooter;
