import { createAction } from '@reduxjs/toolkit';

const types = {
  OPEN_BUNDLE: 'APP_OPEN_BUNDLE',
  CLOSE_BUNDLE: 'APP_CLOSE_BUNDLE',
  SHOW_BUNDLE: 'APP_SHOW_BUNDLE',
  HIDE_BUNDLE: 'APP_HIDE_BUNDLE',
  TOGGLE_SUMMARY: 'APP_TOGGLE_SUMMARY',
  OPEN_NEXT_BUNDLE: 'APP_OPEN_NEXT_BUNDLE',
};

const creators = {
  openBundle: createAction<string>(types.OPEN_BUNDLE),
  closeBundle: createAction<string>(types.CLOSE_BUNDLE),
  showBundle: createAction<string>(types.SHOW_BUNDLE),
  hideBundle: createAction<string>(types.HIDE_BUNDLE),
  openNextBundle: createAction<string>(types.OPEN_NEXT_BUNDLE),
  toggleSummary: createAction(types.TOGGLE_SUMMARY),
};

export { types, creators };
