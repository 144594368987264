import { type FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Layout } from '../../../shared';
import VerificationProviderSelection from './VerificationProviderSelection';
import ContactKundeserviceButton from './ContactKundeserviceButton';
import { customerVerification } from '../../../../store';
import { useSteps } from '../components/steps/StepsContext';
import infoIcon from '../checkout/user-data-information-box/information-icon.svg?url';
import { startCustomerVerification } from './vipps/api';

import './verification-provider-selection.sass';

const Verification: FC = () => {
  const location = useLocation();
  const providerName = useSelector(customerVerification.selectors.GetSelectedVerificationProvider);
  const isProviderSelected = useSelector(customerVerification.selectors.HasVerificationProviderSelected);

  const { setOnNext, currentStep } = useSteps();

  useEffect(() => {
    const onNext = () => startCustomerVerification(providerName).then((res) => { window.location.href = res; });
    setOnNext(currentStep, onNext);
  }, [currentStep, providerName, setOnNext]);

  const searchParams = new URLSearchParams(location.search);
  const restartRequired = searchParams.get("restartRequired");

  return (
    <Layout focusOnRender={true} wide={true} aria-label="Steg 3 av 5 - Identifisering">
      <div className="verification card">
        {restartRequired &&
          <span style={{ color: 'red' }}>
            Det ser ut til at noe gikk galt da du prøvde å bekrefte deg selv. Vennligst prøv igjen.<br /><br />
          </span>
        }
        <h2 className="title-3">Velg en identifiseringsmåte</h2>
        <div>
          <div className="identification-information-text">
            <div style={{ marginBottom: '12px' }}>
              Gjør bestillingen din tryggere og sikrere ved å identifisere deg med Vipps eller BankID. Slik kan vi bekrefte at det er deg som kjøper utstyret. Har du ikke Vipps eller BankID ta <a href="https://www.rikstv.no/kontakt" style={{ color: 'black' }}>kontakt med kundeservice</a>. 
            </div>
            <span>RiksTV beholder opplysningene dine for å gjøre registreringen sikrere. Les mer i vår <a href="https://www.rikstv.no/personvern" target="_blank" style={{ color: 'black' }}>personvernerklæring</a>.</span>
          </div>
          <div className="vipps-container" style={{ marginBottom: '12px' }}>
            <VerificationProviderSelection />
          </div>
          {isProviderSelected &&
            <div>
              <img className='info-icon' src={infoIcon} alt="" /><span>Etter å ha klikket på “Neste”, blir du sendt til {providerName} for å identifisere deg.</span>
            </div>
          }
        </ div>
      </div>
    </Layout>
  );
};

export default Verification;
