import "./utils/polyfill";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import moment from "moment";
import "moment/locale/nb";
import history from "./history";
import { persistor, store } from "./store/configureStore";
import App from "./components/App";
import "./assets/styles/style.sass";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { getConfig } from "./config";
import { PersistGate } from "redux-persist/integration/react";

// Init libs
Sentry.init({ dsn: "https://c6b6d02950cb4c3ba6939ee94e412307@o575998.ingest.sentry.io/5743151", environment: process.env.NODE_ENV });
moment.locale("nb");

const rootElement = document.getElementById("root");
if (rootElement == null) {
  throw new Error("rootElement cannot be null");
}
const root = createRoot(rootElement);

const render = async () => {
  const config = await getConfig();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyClientId,
  });

  root.render(
    <LDProvider>
      <Sentry.ErrorBoundary fallback={<p>Noe gikk galt</p>}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <App />
            </Router>
          </PersistGate>
        </Provider>
      </Sentry.ErrorBoundary>
    </LDProvider>
  );
};

render();
