import config from '../../config';
import { Campaign } from '../../models/CampaignModel';

export const getCampaign = async (partnerAlias: string, dwellingUnit: string, campaignUrl: string): Promise<Campaign> => {
  return fetch(`${config.apiUrl}/campaign/${partnerAlias}/${dwellingUnit}/${campaignUrl}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json());
};
