import React from 'react';

import smartTV from './icons/SmartTV.svg?url';
import laptop from './icons/laptop.svg?url';
import tablet from './icons/tablet.svg?url';
import mobil from './icons/mobil.svg?url';
import strommeboks from './icons/strømmeboks.svg?url';
import chromecast from './icons/chromecast.svg?url';

import './approvedDevices.sass';

const ApprovedDevices: React.FC = () => {
    return (
        <div style={{ marginBottom: '32px' }}>
          <img src={smartTV} className='device tv' alt='TV' />
          <img src={laptop} className='device laptop' alt='laptop' />
          <img src={tablet} className='device' alt='tablet' />
          <img src={mobil} className='device mobil' alt='mobil' />
          <img src={strommeboks} className='device strommeboks' alt='strommeboks eller Apple TV' />
          <img src={chromecast} className='device' alt='chromecast' />
        </div>
    );
}

export default ApprovedDevices;
