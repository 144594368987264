import React from 'react';
import { ComparableSubscription } from '../../../models/subscription-comparison/ComparableSubscriptions';
import { ComparisonCategory } from '../../../models/subscription-comparison/ComparisonCategory';
import { FeatureIndicator } from '../../../models/subscription-comparison/FeatureIndicator';
import { FeatureIndicatorType } from '../../../models/subscription-comparison/FeatureIndicatorType';
import { FeaturesTable } from './features-table/FeaturesTable';
import { OverviewList } from './overview-list/OverviewList';
import { formatAmount } from '../../../utils/format';
import { useIsMobileView } from '../../../hooks/useMediaQuery';
import checkmark from './images/check.svg?url';

import './subscription-comparison.sass';
import { Button, Icon } from '..';
import { useHistory } from 'react-router';
import { useIsIframe } from '../../../hooks/useIsIframe';

interface Props {
  title: string;
  comparableSubscriptions: ComparableSubscription[];
  comparisonCategories: ComparisonCategory[];
  partnerAlias: string;
  dwellingUnit: string;
}

const SubscriptionComparison: React.FC<Props> = ({ title, comparableSubscriptions, comparisonCategories, partnerAlias, dwellingUnit }) => {
  const isMobileView = useIsMobileView();
  const isIframe = useIsIframe();
  const history = useHistory();

  const getConditions = (comparableSubscriptions: ComparableSubscription[]) => {
    const minPrice = comparableSubscriptions.map((sub) => {
      if (sub.offerPeriodInMonth !== null) {
        return `Min. tot. første 1. år for ${sub.name}: ${formatAmount(
          sub.currentPrice * sub.offerPeriodInMonth + sub.originalPrice * (12 - sub.offerPeriodInMonth)
        )}`;
      } else {
        return `Min. tot. første 1. år for ${sub.name}: ${formatAmount(sub.currentPrice * 12)}.`;
      }
    });

    return `*Tilbudet gjelder nye kunder og avtaleperiode i 12 mnd. ${minPrice.join(
      ' '
    )} **Gjelder Viaplay, Max, Eurosport, og TV 2 Sport 1 og 2. Kun én inkludert tilleggspakke per ny kunde.`;
  };

  return (
    <div className="subscription-comparison">
      <div className="introduction">
        <h1 className="title-2">{title}</h1>
      </div>

      <OverviewList
        comparableSubscriptions={comparableSubscriptions}
        comparisonCategories={comparisonCategories}
        partnerAlias={partnerAlias}
        dwellingUnit={dwellingUnit}
      />

      {!isMobileView && <FeaturesTable comparableSubscriptions={comparableSubscriptions} comparisonCategories={comparisonCategories} />}

      <div className="conditions sub-body">
        <p>{getConditions(comparableSubscriptions)}</p>
      </div>
      {!isIframe && (
        <Button
          className="back-button"
          type="primary blue"
          iconMargin="right"
          aria-label="Tilbake til start"
          onClick={() => history.push('/')}>
          <Icon type="arrow" flip="horizontal" fill="white" size={1.5} />
          Tilbake
        </Button>
      )}
    </div>
  );
};

export const getIndicatorItem = (featureIndicator: FeatureIndicator) => {
  switch (featureIndicator.type) {
    case FeatureIndicatorType.Check:
      return <img src={checkmark} alt="" aria-label="Inkludert" />;
    case FeatureIndicatorType.StrongText:
      return <span className="text sub-body">{featureIndicator.text}</span>;
    case FeatureIndicatorType.WeakText:
      return <span className="text sub-body subtle">{featureIndicator.text}</span>;
  }
};

export default SubscriptionComparison;
