import config from '../../config';
import moment from 'moment';
import { splitStreetAddresses } from '../../utils/address';
import { removeWhiteSpaces } from '../../utils/format';
import { FormValues } from '.';
import { CustomerType } from '../../models/CustomerType';
import { CustomerVerificationDetails } from '../customerVerification';

interface CheckoutRequest {
  offers: string[];
  potentialCustomer: {
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
    birthDate: string;
  };
  defaultAddress: {
    street: string;
    houseNo: string | undefined;
    houseNoAlpha: string | undefined;
    zip: string;
    city: string;
  };
  trackingCode: string;
  partnerAlias: string;
  isLaterActivation: boolean;
  customerType: CustomerType;
  customerVerificationDetails: CustomerVerificationDetails;
}

export const toCheckoutRequest = (
  customer: Partial<FormValues>,
  offers: string[],
  trackingCode: string,
  partnerAlias: string,
  isLaterActivation: boolean,
  customerType: CustomerType,
  customerVerificationDetails: CustomerVerificationDetails
): CheckoutRequest => {
  const { street, houseNo, houseNoAlpha } = splitStreetAddresses(customer.street);
  return {
    offers,
    potentialCustomer: {
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
      email: customer.email?.trim() || '',
      mobilePhone: removeWhiteSpaces(customer.mobilePhone),
      birthDate: customer.birthDate ? moment.utc(customer.birthDate, ['DDMMYY', 'DDMMYYYY']).format() : moment('1970-01-01').format(),
    },
    defaultAddress: {
      street: street,
      houseNo: houseNo,
      houseNoAlpha: houseNoAlpha,
      zip: customer.zip || '',
      city: customer.city || '',
    },
    trackingCode: trackingCode,
    partnerAlias,
    isLaterActivation,
    customerType,
    customerVerificationDetails
  };
};

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const checkout = async (request: CheckoutRequest): Promise<string> =>
  fetch(`${config.apiUrl}/checkout`, {
    method: 'POST',
    headers,
    body: JSON.stringify(request),
  }).then((response) => {
    if (!response.ok) { throw response; }
    return response.json();
  });
