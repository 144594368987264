import * as selectors from './selectors';
import { types, creators } from './actions';
import { Partner } from '../../models/CampaignModel';

export interface FormValues {
  email: string;
  mobilePhone: string;
  firstName: string;
  lastName: string;
  street: string;
  zip: string;
  birthDate: string;
  houseNumber: string;
  houseNoAlpha: string;
  city: string;
}

export interface CheckoutState {
  formValues: Partial<FormValues>;
  isFormValid: boolean;
  isSubmitFormPressed: boolean;
  receiptId?: string;
  partner?: Partner;
}

const INITIAL_STATE: CheckoutState = {
  formValues: {},
  isFormValid: false,
  isSubmitFormPressed: false,
};

export { selectors, types, creators, INITIAL_STATE };
