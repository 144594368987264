import React, { useEffect } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { Layout, Icon } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import SupportFooter from '../../shared/support-footer/SupportFooter';
import { removePersistedStore } from '../../../store/configureStore';
import { RootState, basket, checkout, customerVerification, campaign } from '../../../store';
import './receipt.sass';
import ApprovedDevices from './ApprovedDevices';
import { ProductType } from '../../../models/product/ProductType';
import { Link } from 'react-router-dom';

const Receipt: React.FC = () => {
  useTitle('Bekreftelse | RiksTV');
  const dispatch = useDispatch();

  useEffect(() => {
    removePersistedStore();
    dispatch(checkout.creators.resetUserData()); // Should we just merge this action into checkoutSucceeded?
    dispatch(customerVerification.creators.resetCutomerVerificationData());
  }, [dispatch]);
 
  const mainSubscriptionName = useSelector((state: RootState) => campaign.selectors.getMainSubscriptionName(state));
  const premiumProducts = useSelector((state: RootState) => basket.selectors.getProductsFromCategory(state, ProductType.Premium));

  return (
    <>
      <Layout focusOnRender aria-label={`Bestillingen din på RiksTV er i boks`}>
        <div className="receipt">
          <h1>
            Velkommen til RiksTV!
          </h1>

          <b>
            Bestillingen er i boks! Du har nå {mainSubscriptionName}
            { premiumProducts.length > 0 ?
                <>
                {premiumProducts.length == 1 && <>{' '}og tilleggstjenesten {premiumProducts.map(product => product.displayData.name)}.</> }
                {premiumProducts.length > 1 && 
                  <>
                     {' '}og tilleggstjenestene{' '}
                    {
                      [premiumProducts.slice(0, -1).map(product => product.displayData.name).join(', '), premiumProducts.slice(-1)[0].displayData.name].join(premiumProducts.length < 2 ? '' : ' and ')                        
                    }
                  </>
                }</> : <>.</>
            }
            
          </b>

          <div className="get-started-box">
            <h3 style={{ marginBottom: '8px'}}>
              Kom i gang
            </h3>
            <span>
              Om litt får du en e-post med alt du må vite for å komme i gang. For å kunne logge inn på RiksTV-kontoen din må du velge deg et passord. Passordet bruker du til å logge inn både på <a className="link-style" href="https://www.rikstv.no/">rikstv.no</a> og i appene våre.  
            </span>
            <br/>
            <br/>

            <a
                  href="https://login.rikstv.no/3/Account/RegisterUserViaMobile"
                  className="button-base primary"
                  aria-label={`Opprett passord`}>
                  Opprett passord
            </a>
          </div>

          <h2 style={{ marginTop: '32px', marginBottom: '8px' }}>Se det du vil allerede i dag</h2>
          <p>
            Utstyret ditt er på vei. Mens du venter kan du se RiksTV rett i{' '}
            <a className='link-style' href="https://play.rikstv.no/start?utm_source=adobe&utm_campaign=velkomstlop&utm_medium=email&utm_content=bakkenett_dag1_velkommen">nettleser</a>
            {' '}eller last ned som app på din{' '}
            <a className="link-style" href="https://www.rikstv.no/app-og-nett-tv/">mobil, nettbrett eller TV</a>
            . Noen strømmetjenester må aktiveres via Min Side. 
            For fullstendig oversikt over enheter vi støtter, se{' '}
            <a className="link-style" href="https://www.rikstv.no/kundeservice/godkjente-mottakere/">godkjente enheter</a>.
          </p>
          
          <ApprovedDevices />

          <h2>Vilkår og angrerett</h2>
          <p>Abonnementsavtalen, avtalevilkår og angrerettskjema sendes på e-post til deg.</p>

          <h2>Samtykke og reservasjoner</h2>
          <p>
            På Min side kan melde deg av nyhetsbrev på e-post og SMS. Her kan du også reservere deg mot at vi bruker dine data til å gi deg relevante tips og personlige tilpasninger.<br/>
          </p>

          <h2>Lurer du på noe?</h2>
          <p>
            Ta gjerne kontakt på chat eller med vårt kundeservice dersom du har noen spørsmål. <br />
            Trenger du hjelp til å komme i gang, kan du ta en titt på vår{' '}
            <a className="link-style" href="https://www.rikstv.no/kundeservice/kom-i-gang-med-rikstv">
              "kom i gang"-side
            </a>
            .
          </p>
        </div>
      </Layout>

      <SupportFooter displayHeading={false} />
    </>
  );
};

export default Receipt;
