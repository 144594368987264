import React from 'react';
import { Icon } from '../../../shared';
import './InfoModal.sass';
import useModal from '../../Modal';

interface Props {
    infoText: string,
}

const InfoModal: React.FC<Props> = ({ infoText })  => {
    
  const { close } = useModal();

  const handleClick = () => {
      close();
  };

  return (
    <div className="info-modal">
      <div>
        <Icon type="alertTriangle" size={2} fill="#f2a900" />
      </div>

      <p>{infoText}</p>
    </div>
  );
};

export default InfoModal;
