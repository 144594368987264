import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Card, DefaultBlockContent, ExternalLink, Button, Checkbox, Icon } from '../../../shared';
import { basket, checkout, campaign, RootState, customerVerification } from '../../../../store';
import { checkout as checkoutRequest, toCheckoutRequest } from '../../../../store/checkout/api';
import SimpleSummary from '../components/simple-summary/SimpleSummary';
import SimpleTotal from '../components/simple-total/SimpleTotal';
import { ProductType } from '../../../../models/product/ProductType';
import useModal, { ErrorModal } from '../../../modal';
import { useHistory } from 'react-router-dom';
import { removePersistedStore } from '../../../../store/configureStore';

import './summary.sass';

const label = (
  <div aria-label="Jeg har lest og godtar RiksTVs vilkår og personvernerklæring">
    Jeg har lest og godtar {''}
    <ExternalLink className="link-style" href="https://www.rikstv.no/produkter/vilkar/abonnementsvilkar">
      RiksTVs vilkår{' '}
    </ExternalLink>{' '}
    og {''}
    <ExternalLink className="link-style" href="https://www.rikstv.no/personvern">
      personvernerklæring
    </ExternalLink>
  </div>
);

const Summary: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { open } = useModal();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [isCheckoutAttempted, setIsCheckoutAttempted] = useState(false);

  const selectedProducts = useSelector((state: RootState) => basket.selectors.getBasketProductsWithPrices(state));
  const isDelayedActivation = useSelector((state: RootState) => basket.selectors.getIsDelayedActivation(state));
  const customer = useSelector((state: RootState) => checkout.selectors.getFormValues(state));
  const additionalTerms = useSelector((state: RootState) => campaign.selectors.getAdditionalTerms(state));

  const basketIds = useSelector((state: RootState) => basket.selectors.getBasketCompositeIds(state));
  const trackingCode = useSelector((state: RootState) => campaign.selectors.getCampaignTrackingCode(state)) || '';
  const partnerAlias = useSelector((state: RootState) => campaign.selectors.getPartnerAlias(state));
  const customerType = useSelector((state: RootState) => campaign.selectors.getCustomerType(state));
  const customerVerificationDetails = useSelector((state: RootState) => customerVerification.selectors.GetVerificationDetails(state));

  const mainSubscription = selectedProducts.find((product) => product && product.productType === ProductType.Subscription);
  const paidInstallationProducts = selectedProducts.filter(
    (product) => product && product.productType === ProductType.Installation && product.pricing.price !== 0
  );

  const termsError =
    isCheckoutAttempted && !acceptedTerms ? 'Vilkår og personvernerklæring må godtas for å kunne fullføre bestillingen' : undefined;

  const onCheckout = async () => {
    setIsCheckoutAttempted(true);
    if (!acceptedTerms || isCheckoutLoading) {
      return;
    }
    setIsCheckoutLoading(true);
    await checkoutRequest(toCheckoutRequest(customer, basketIds, trackingCode, partnerAlias, isDelayedActivation, customerType, customerVerificationDetails)).then(
      (recieptId: string) => {
        dispatch(checkout.creators.checkoutSucceeded(recieptId));
        removePersistedStore();
        dispatch(checkout.creators.resetUserData()); // Should we just merge this action into checkoutSucceeded?
        dispatch(customerVerification.creators.resetCutomerVerificationData());
        history.push('/bekreftelse');
      },
      (_) => {
        // Open error modal
        open(<ErrorModal />);
        setIsCheckoutLoading(false);
      }
    );
  };

  return (
    <Layout focusOnRender={true} wide={true} aria-label="Steg 4 av 4 - Se over bestilling">
      <div className="summary">
        <div className="section">
          <Card>
            <h2 className="title-3">
              <Icon type="cart" flip="horizontal" size={1.5} />
              Se over og fullfør bestillingen
            </h2>
            <SimpleSummary />
          </Card>
          <Card color="gray-dark">
            <SimpleTotal />
          </Card>
          {additionalTerms && (
            <div className="additional-terms">
              <DefaultBlockContent blocks={additionalTerms} />
            </div>
          )}
        </div>
        <div className="section instructions-and-conditions">
          <h2 className="title-3">
            <Icon type="formCheck" size={1.5} />
            {isDelayedActivation ? 'Aktivering og betaling' : 'Betaling'}
          </h2>
          <p>
            {isDelayedActivation
              ? 'TV-pakken aktiverer du enkelt på Min side når du er klar til å se TV. Abonnementskostnaden begynner ikke å løpe før du har aktivert TV-pakken. Faktura for utstyret mottar du sammen med utstyret i posten. '
              : 'Første faktura kommer i posten. '}
            Du kan sette opp AvtaleGiro eller eFaktura på Min side. Da slipper du fakturagebyr og sparer penger og miljø.
          </p>

          <h2 className="title-3">
            <Icon type="box" size={1.5} />
            Levering
          </h2>
          <p className="delivery-lead">Faktura og utstyr vil bli sendt til:</p>
          <Card>
            <div className="delivery-address button-or-bold">
              <p>
                {customer.firstName} {customer.lastName}
              </p>
              <p>{customer.street}</p>
              <p>
                {customer.zip} {customer.city}
              </p>
            </div>
          </Card>
          <p>Du kan hente utstyret på Posten, eller velge gratis hjemlevering. Dette valget får du når du mottar en SMS fra Bring.</p>

          {paidInstallationProducts.length > 0 && (
            <>
              <h2 className="title-3">
                <Icon type="wrench" size={1.5} />
                Montering
              </h2>
              <p>Montøren vil ta kontakt med deg i løpet av noen dager for å avtale montering.</p>
            </>
          )}

          <h2 className="title-3">
            <Icon type="form" size={1.5} />
            Forutsetninger og vilkår
          </h2>
          <p>
            Tilbudet forutsetter nytegning av et {mainSubscription?.displayData.name}-abonnement med 12 måneders binding. Det er 14 dagers
            angrefrist og du kan si opp abonnementet på Min side etter 9 mnd. Oppsigelsestid er inneværende, pluss to måneder.
          </p>
          <Card>
            <Checkbox
              checked={acceptedTerms}
              label={label}
              id="termsAndConditions"
              onChange={() => setAcceptedTerms(!acceptedTerms)}
              error={termsError}
              size={2}
              value="Godtar betingelser"
            />
          </Card>

          <Button type="primary" className="checkout-button" onClick={onCheckout}>
            Fullfør Bestilling
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Summary;
