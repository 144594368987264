import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SubscriptionComparison from '../../shared/subscription-comparison/SubscriptionComparison';
import { title, comparableSubscriptionsStaticData, comparisonCategories } from './placeholderSubscriptionComparisonData';
import { useParams } from 'react-router';
import { getSubscriptionsForPartner } from './api';
import { ComparableSubscription, SanitySubscription } from '../../../models/subscription-comparison/ComparableSubscriptions';
import { HousingTypeUrlParam } from '../customer-filter/CustomerFilter';
import SupportFooter from '../../shared/support-footer/SupportFooter';
import NotFound from '../not-found/NotFound';
import { Loader } from '../../shared';
import { removePersistedStore } from '../../../store/configureStore';
import { creators } from '../../../store/campaign/actions';

import './choose-subscription-page.sass';

interface ParamTypes {
  partnerAlias: string;
  dwellingUnit: string;
}

const ChooseSubscriptionPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { partnerAlias, dwellingUnit } = useParams<ParamTypes>();

  const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>('loading');
  const [sanitySubscriptions, setSanitySubscriptions] = useState<SanitySubscription[]>([]);
  const [comparableSubscriptions, setComparableSubscriptions] = useState<any[]>([]);

  const getDiscountPercentage = (originalPrice: number, currentPrice: number) => {
    return Math.floor((1 - currentPrice / originalPrice) * 100);
  };
  const getReadMoreUrl = (partnerAlias: string, campaignUrl: string) => {
    // TODO: Should check if it's fiber in a better way
    // Best way would be by businessUnitId, but then we need to fetch partner data
    // in the API using SalesPartnerRegistry service (to get the bUnitId from Sanity)
    if (partnerAlias === 'kampanje') {
      return `https://www.rikstv.no/tv-pakker/${campaignUrl}`;
    } else {
      return `https://www.rikstv.no/rikstv-via-fiber/partner/${partnerAlias}/${campaignUrl}/`;
    }
  };

  const isObsoleteCampaignUrl = (partnerAlias: string, dwellingUnit: string) => {
    // TODO: Delete when we are sure all links are updated on rikstv.no
    // Redirect backup if we forget to update all urls on rikstv.no
    // Ex: bestill.rikstv.no/kampanje/rikspakken => redirect to correct url
    const validPartnerAliases = ['kampanje', 'homenet', 'gp', 'nextgentel'];
    const validCampaignUrls = ['rikspakken', 'sportspakken', 'fleksipakken', 'strommepakken'];

    return validPartnerAliases.includes(partnerAlias) && validCampaignUrls.includes(dwellingUnit);
  };

  useEffect(() => {      
      dispatch(creators.resetSelectedCampaign());
      removePersistedStore();
      if (isObsoleteCampaignUrl(partnerAlias, dwellingUnit)) {
      history.replace(`/${partnerAlias}/${HousingTypeUrlParam.sdu}/${dwellingUnit}`); // {dwellingUnit} will in this case be 'rikspakken', 'basis' or 'favoritter'

      return;
    }

    getSubscriptionsForPartner(partnerAlias, dwellingUnit)
      .then((res: SanitySubscription[]) => {
        setSanitySubscriptions(res);
        setStatus('loaded');
      })
      .catch((err) => setStatus('error'));
  }, [partnerAlias, dwellingUnit, history]);

  useEffect(() => {
    const comparableSubscriptions: ComparableSubscription[] = sanitySubscriptions.map((sub) => {
      const staticData = comparableSubscriptionsStaticData.filter((s) => s.subscriptionIccProductId === sub.subscriptionIccProductId)[0];

      return {
        ...staticData,
        ...sub,
        discountPercentage: getDiscountPercentage(sub.originalPrice, sub.currentPrice),
        readMoreUrl: getReadMoreUrl(partnerAlias, sub.campaignUrl),
      };
    });

    setComparableSubscriptions(comparableSubscriptions.sort((a, b) => b.originalPrice - a.originalPrice));
  }, [sanitySubscriptions, partnerAlias]);

  if (status === 'error') {
    return <NotFound />;
  }
  if (status === 'loading') {
    return <Loader />;
  }

  return (
    <>
      <main className="choose-subscription-page main-layout container padded wide">
        {status === 'loaded' && (
          <SubscriptionComparison
            title={title}
            comparisonCategories={comparisonCategories}
            comparableSubscriptions={comparableSubscriptions}
            partnerAlias={partnerAlias}
            dwellingUnit={dwellingUnit}
          />
        )}
      </main>

      <SupportFooter />
    </>
  );
};

export default ChooseSubscriptionPage;
