import React from 'react';
import classnames from 'classnames';
import './layout.sass';

interface Props {
  isCampaignTitle?: boolean;
  hasSubtitle?: boolean;
  className?: string;
}

const MainTitle: React.FC<Props> = ({ children, isCampaignTitle = false, hasSubtitle = false, className = '' }) => {
  const mainClass = classnames('main-title', className);

  return isCampaignTitle ? (
    <h1 className={`campaign-title ${hasSubtitle && 'with-subtitle'}`}>{children}</h1>
  ) : (
    <h2 className={mainClass}>{children}</h2>
  );
};

export default MainTitle;
