import React from 'react';

interface Props {
  name: string;
  priceLabel: string;
  originalPrice?: string;
  details?: string;
  id?: string;
  isTotal?: boolean;
  optionalCurrentPriceTitle?: string;
}

const BasketListItem: React.FC<Props> = ({ name, id, originalPrice, priceLabel, details, isTotal = false, optionalCurrentPriceTitle }) => (
  <li>
    <div className="name-and-price">
      {isTotal ? (
        <h3 className="name body-text" aria-describedby={`original-price-${id} current-price-${id} details-${id}`}>
          {name}
        </h3>
      ) : (
        <h4 className="name body-text" aria-describedby={`original-price-${id} current-price-${id} details-${id}`}>
          {name}
        </h4>
      )}

      <div className="dotted-line"></div>

      <div className="price">
        {originalPrice && (
          <p className="original-price" id={`original-price-${id}`} aria-label={`Tidligere pris: ${originalPrice} kroner.`}>
            {originalPrice}
          </p>
        )}

        <p className="current-price" id={`current-price-${id}`} aria-label={`Du betaler: ${priceLabel}`}>
          {optionalCurrentPriceTitle || priceLabel}
        </p>
      </div>
    </div>

    {details && (
      <div className="details" id={`details-${id}`}>
        {details}
      </div>
    )}
  </li>
);

export default BasketListItem;
