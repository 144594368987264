import { createAction } from '@reduxjs/toolkit';
import { CustomerVerificaitonState, SetSelectedVerificationProvider } from '.';

const types = {  
  CUSTOMER_VERIFICATION_SUCCEEDED: 'CUSTOMER_VERIFICATION_SUCCEEDED',
  RESET_CUSTOMER_VERIFICATION: 'RESET_CUSTOMER_VERIFICATION',
  SET_SLECTED_VERIFICATION_PROVIDER: 'SET_SLECTED_VERIFICATION_PROVIDER',
};

const creators = {  
  resetCutomerVerificationData: createAction(types.RESET_CUSTOMER_VERIFICATION),
  customerVerificationSucceeded: createAction<CustomerVerificaitonState>(types.CUSTOMER_VERIFICATION_SUCCEEDED),
  setSelectedVerificationProvider: createAction<SetSelectedVerificationProvider>(types.SET_SLECTED_VERIFICATION_PROVIDER),
};

export { types, creators };
