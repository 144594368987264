import config from '../../../config';
import { SanitySubscription } from '../../../models/subscription-comparison/ComparableSubscriptions';
import { PartnerIframeConfig } from '../../../models/subscription-comparison/PartnerIframeConfig';

export const getSubscriptionsForPartner = async (partnerAlias: string, dwellingUnit: string): Promise<SanitySubscription[]> => {
  return fetch(`${config.apiUrl}/campaign/${partnerAlias}/${dwellingUnit}`).then((response) => response.json());
};

export const getPartnerIframeConfig = async (partnerAlias: string): Promise<PartnerIframeConfig> => {
  return fetch(`${config.apiUrl}/partner/${partnerAlias}/iframeconfig`).then((response) => response.json() as PartnerIframeConfig);
};
