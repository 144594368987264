import React from 'react';
import moment from 'moment';
import BasketListItem from './BasketListItem';
import { formatAmount, formatRecurringAmount } from '../../../../../../utils/format';
import { SelectableItem } from '../../../../../../models/CampaignModel';
import { ProductType } from '../../../../../../models/product/ProductType';

interface Props {
  product: SelectableItem;
  hideIfFree?: boolean;
}

const BasketProduct: React.FC<Props> = ({ product, hideIfFree = false }) => {
  const { price, originalPrice, offerPeriodInMonths, isRecurring, offerEndDate } = product.pricing;
  if (hideIfFree && price === 0) {
    return null;
  }
  const name = product.displayData.name;

  const isPremium = product.productType === ProductType.Premium;

  const isReducedRecurringOffer = isRecurring && price < originalPrice && offerPeriodInMonths && offerPeriodInMonths > 0;

  const endMoment = moment(offerEndDate);
  const priceSuffix = endMoment.isBefore(moment().add(1, 'months'))
    ? `til ${endMoment.format('DD.MM.YYYY')}.`
    : `/mnd i ${offerPeriodInMonths} mnd.`;

  const priceLabel = isReducedRecurringOffer
    ? `${formatAmount(price)}${priceSuffix}`
    : isPremium
    ? `${formatAmount(price)}/mnd`
    : `${formatAmount(price)}`;

  const details = isReducedRecurringOffer ? `Originalpris ${formatRecurringAmount(originalPrice)}` : ``;

  const originalPricing = originalPrice !== price ? formatAmount(originalPrice) : undefined;

  return (
    <BasketListItem
      name={name}
      priceLabel={priceLabel}
      originalPrice={originalPricing}
      details={details}
      id={product.id}
      optionalCurrentPriceTitle={product.displayData.optionalPriceTitle}
    />
  );
};

export default BasketProduct;
