import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { app, basket, campaign, RootState } from '../../../../../../store';
import SelectedProducts from './SelectedProducts';
import { useGroupConfig } from '../ProductGroup';
import { Button } from '../../../../../shared';
import { FlowMode } from '../../../../../../models/FlowMode';
import { ProductType } from '../../../../../../models/product/ProductType';
import { ProductBundle } from '../../../../../../models/CampaignModel';

interface Props {
  bundle: ProductBundle;
  index: number;
  isOpen: boolean;
  setProductCardToBeFocused: React.Dispatch<
    React.SetStateAction<{
      shouldFocus: boolean;
      productId: string;
    }>
  >;
}
const ProductBundleHeader: React.FC<Props> = ({ bundle, index, isOpen, setProductCardToBeFocused }) => {
  const { activeBundle, flowMode, deactivateBundle, type } = useGroupConfig();
  const { selected, selectedWithData, isVisible, config } = useSelector((state: RootState) => mapStateToProps(state, bundle.id, type));
  const dispatch = useDispatch();

  const animateProps = {
    duration: 300,
    animateOpacity: true,
    height: isOpen || !(selectedWithData.length > 0) ? 0 : 'auto',
  };

  const bundleTitle = config?.bundleTitle ? config.bundleTitle.replace('{n}', (index + 1).toString()) : '';
  const productName = selectedWithData.map((product) => product.displayData.name);

  const deselectProduct = () => {
    setProductCardToBeFocused({ shouldFocus: true, productId: selected[0].productId });
    dispatch(basket.creators.removeProduct(selected));
    dispatch(app.creators.openBundle(bundle.id));
  };

  const removeBundle = () => {
    dispatch(basket.creators.removeProduct(selected));
    deactivateBundle(index);
    dispatch(app.creators.hideBundle(bundle.id));
    dispatch(app.creators.closeBundle(bundle.id));
  };

  const RemoveBundleButton: React.FC = ({ children }) => {
    if (flowMode === FlowMode.Open || index === 0 || bundle.id !== activeBundle) {
      return null;
    }

    const removeLabel = `Slett kategori ${bundleTitle}` + (selected.length > 0 ? ` og fjern ${productName} fra handlekurv` : '');

    return (
      <Button
        type="link-style"
        onClick={removeBundle}
        aria-label={removeLabel}
        data-track-id="card-button"
        data-track-card-name={productName}>
        {children}
      </Button>
    );
  };

  return (
    <>
      {isVisible && bundleTitle && (
        <div className="product-bundle-title">
          <h3 className="title-3">{bundleTitle}</h3>
          <RemoveBundleButton>Angre</RemoveBundleButton>
        </div>
      )}
      <AnimateHeight {...animateProps}>
        <div>
          <SelectedProducts bundleId={bundle.id}>
            {!isOpen && bundle.id === activeBundle && (
              <Button
                type={'secondary'}
                className="product-bundle-button"
                aria-label={`Endre valg av ${productName}`}
                aria-expanded={isOpen}
                focusOnRender={true}
                onClick={deselectProduct}
                data-track-id="card-button"
                data-track-card-name={productName}>
                Endre
              </Button>
            )}
          </SelectedProducts>
        </div>
      </AnimateHeight>
    </>
  );
};

const mapStateToProps = (state: RootState, bundleId: string, type: ProductType) => ({
  config: campaign.selectors.getProductTypeConfig(state, type),
  isVisible: app.selectors.isBundleVisible(state, bundleId),
  selected: basket.selectors.getSelectedFromBundle(state, bundleId),
  selectedWithData: basket.selectors.getSelectedFromBundleWithData(state, bundleId),
});

export default ProductBundleHeader;
