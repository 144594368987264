import React from 'react';
import { PortableText } from '@portabletext/react';
import Banner from '../banner/Banner';

/**
 * @type {Partial<import('@portabletext/react').PortableTextReactComponents>}
 */
const defaultSerializers = {
  marks: {
    link: (props) => (
      <a target="_blank" href={props.value.href} rel="noopener noreferrer">
        {props.children}
      </a>
    ),
  },
  types: {
    banner: (props) => <Banner {...props} />,
  },
};

class DefaultBlockContent extends React.Component {
  render() {
    const { serializers, blocks, ...other } = this.props;
    if (!blocks) return null;
    return <PortableText components={{ ...defaultSerializers, ...serializers }} value={blocks} {...other} />;
  }
}

export default DefaultBlockContent;
