import { createAction } from '@reduxjs/toolkit';
import { Campaign } from '../../models/CampaignModel';
import { TrackingPoints } from '../../sagas/trackingSaga';

const types = {
  FETCH_REQUEST: 'CAMPAIGN_FETCH_REQUEST',
  FETCH_SUCCEEDED: 'CAMPAIGN_FETCH_SUCCEEDED',
  FETCH_FAILED: 'CAMPAIGN_FETCH_FAILED',
  CHANGE_TRACKING_POINT: 'CAMPAIGN_CHANGE_TRACKING_POINT',
  SET_PARTNER_ALIAS: 'CAMPAIGN_SET_PARTNER_ALIAS',
  SET_DWELLING_UNIT: 'CAMPAIGN_SET_DWELLING_UNIT',
  RESET_CAMPAIGN: 'RESET_CAMPAIGN'
};

const creators = {
  fetchRequest: createAction<{ partnerAlias: string; dwellingUnit: string; campaignUrl: string }>(types.FETCH_REQUEST),
  fetchSucceeded: createAction<Campaign>(types.FETCH_SUCCEEDED),
  fetchFailed: createAction(types.FETCH_FAILED),
  changeTrackingPoint: createAction<TrackingPoints>(types.CHANGE_TRACKING_POINT),
  setPartnerAlias: createAction<string>(types.SET_PARTNER_ALIAS),
  setDwellingUnit: createAction<string>(types.SET_DWELLING_UNIT),
  resetSelectedCampaign: createAction(types.RESET_CAMPAIGN),
};

export { types, creators };
