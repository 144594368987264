import * as selectors from './selectors';
import { types, creators } from './actions';

export interface AppState {
  bundles: {
    [key: string]: { isVisible?: boolean; isOpen?: boolean; shouldFocusFirstProduct?: boolean } | undefined;
  };
  isSummaryOpen: boolean;
}

const INITIAL_STATE: AppState = {
  bundles: {},
  isSummaryOpen: false,
};

export { selectors, types, creators, INITIAL_STATE };
