import { createAction } from '@reduxjs/toolkit';
import { Channel } from '../../models/channel/Channel';

const types = {
  CHANNEL_LIST_REQUESTED: 'CHANNEL_LIST_REQUESTED',
  CHANNEL_LIST_SUCCEEDED: 'CHANNEL_LIST_SUCCEEDED',
  CHANNEL_LIST_FAILED: 'CHANNEL_LIST_FAILED',
};

const creators = {
  channelListRequested: createAction<number>(types.CHANNEL_LIST_REQUESTED),
  channelListSucceeded: createAction<Channel[]>(types.CHANNEL_LIST_SUCCEEDED),
  channelListFailed: createAction(types.CHANNEL_LIST_FAILED),
};

export { types, creators };
