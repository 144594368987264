import React from 'react';
import { useSelector } from 'react-redux';
import { DefaultBlockContent, Button } from '../../../shared';
import { basket, RootState } from '../../../../store';
import { SelectableItem } from '../../../../models/CampaignModel';
import ProductPrice from '../../../routes/campaign/components/product-group/product-cards/ProductPrice';
import { ProductType } from '../../../../models/product/ProductType';
import useModal from '../..';
import './ProductModal.sass';
interface Props {
  product: SelectableItem;
  toggleProductSelection: () => void;
}

const ProductModal: React.FC<Props> = ({ product, toggleProductSelection }) => {
  const isSelected = useSelector((state: RootState) => basket.selectors.isProductSelected(state, product.id));

  const { close } = useModal();

  const handleClick = () => {
    toggleProductSelection();
    close();
  };

  const Description = () => {
    if (typeof product.displayData.description === 'object') {
      return <DefaultBlockContent className="description" blocks={product.displayData.description} renderContainerOnSingleChild />;
    }

    return <div className="description" dangerouslySetInnerHTML={{ __html: product.displayData.description }} />;
  };

  return (
    <div className="product-modal">
      <h1 className="title-3">{product.displayData.name}</h1>
      <Description />
      {!isSelected && (
        <div className="price-and-button">
          <ProductPrice
            pricing={product.pricing}
            receiver={product.productType === ProductType.Receiver}
            optionalPriceTitle={product.displayData.optionalPriceTitle}
          />
          <Button type="secondary" onClick={() => handleClick()} aria-label={`Legg til ${product.displayData.name} i handlekurv`}>
            Legg til
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProductModal;
