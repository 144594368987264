import React from 'react';
import { useSteps } from './StepsContext';
import { Button, Icon } from '../../../../shared';
import './steps.sass';
import { app, RootState } from '../../../../../store';
import { useSelector } from 'react-redux';

const StepNavigation = () => {
  const { isSummaryOpen } = useSelector((state: RootState) => mapState(state));
  const { currentStep, navigate, steps } = useSteps();

  const isLastStep = currentStep === steps.length - 1;
  const onNext = () => (steps[currentStep].onNavigateNext ? steps[currentStep].onNavigateNext?.() : navigate(currentStep + 1));

  return (
    <nav className="step-navigation container padded wide" aria-hidden={isSummaryOpen}>
      {!isLastStep && (
        <Button type="primary blue" iconMargin="left" aria-label="Neste steg" onClick={() => onNext()}>
          Neste
          <Icon type="arrow" fill="white" size={1.5} />
        </Button>
      )}
      {!isLastStep && (
        <Button type="primary blue" iconMargin="right" aria-label="Tilbake til forrige steg" onClick={() => navigate(currentStep - 1)}>
          <Icon type="arrow" flip="horizontal" fill="white" size={1.5} />
          Tilbake
        </Button>
      )}
    </nav>
  );
};

const mapState = (state: RootState) => ({
  isSummaryOpen: app.selectors.isSummaryOpen(state),
});

export default StepNavigation;
