import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { app, basket, RootState } from '../../../../../../store';
import ProductCardHat from './ProductCardHat';
import ProductPrice from './ProductPrice';
import ProductCardImage from './ProductCardImage';
import { SelectableItem, ProductBundle, ConflictingProduct } from '../../../../../../models/CampaignModel';
import { SelectedIcon } from '../components';
import { Button, DefaultBlockContent } from '../../../../../shared';
import { ProductType } from '../../../../../../models/product/ProductType';
import useModal, { ProductModal } from '../../../../../modal/';
import InfoModal from '../../../../../modal/modals/InfoModal/InfoModal';

interface Props {
  bundle: ProductBundle;
  product: SelectableItem;
  toggleProductSelection: () => void;
  shouldFocus: boolean;
}

const ProductCardFace: React.FC<Props> = ({ shouldFocus, bundle, product, toggleProductSelection }) => {
  const selectors = useSelector((state: RootState) => mapState(state));
  const isSelected = useSelector((state: RootState) => basket.selectors.isProductSelected(state, product.id));
  const {
    displayData: { name, description, shortDescription, backgroundImageUrl, infoTag, optionalPriceTitle, conflictingProducts },
    productType,
  } = product;
  const cardClass = classnames('card-face', productType, { 'is-selected': isSelected, 'with-background': !!backgroundImageUrl });
  const isOpen = useSelector((state: RootState) => app.selectors.isBundleOpen(state, bundle.id));

  const { open } = useModal();

  const aria = {
    'aria-label': isSelected ? `${name} lagt til i handlekurv, trykk igjen for å angre` : `Legg til ${name} i handlekurv`,
    'aria-expanded': productType === ProductType.Receiver || productType === ProductType.Equipment ? isOpen : undefined,
  };

  const cardHeaderClass =
    product.productType === ProductType.Installation || product.productType === ProductType.Equipment ? 'card-header-flex' : '';

  const changeStatusOnProduct = (conflictingProducts: ConflictingProduct[]) => {
    if ( conflictingProducts == null ) {
      toggleProductSelection();
    }

    const conflictingBundle = conflictingProducts?.find(conflictingProduct => {
      return (selectors.conflictingBundleInBasket(conflictingProduct.iccProductId))
    });

    if (conflictingBundle) {
      open(<InfoModal infoText={"Det er ikke mulig å kombinere " + product.displayData.name + " og " + conflictingBundle.name + "."}/>);
    } else {
      toggleProductSelection();
    }
  }

  return (
    <div className={cardClass}>
      <ProductCardHat product={product} />

      {isSelected && <SelectedIcon />}

      <div className="card-content">
        <div className={cardHeaderClass}>
          <ProductCardImage product={product} />

          <strong className="title-4 card-name" aria-label={isSelected ? `Valgt ${name}` : name}>
            {name}
          </strong>
        </div>

        {shortDescription && (
          <div className="sub-body">
            <DefaultBlockContent blocks={shortDescription} />
          </div>
        )}

        {infoTag && <p className="metadata card-infotag-text">{infoTag}</p>}

        <ProductPrice pricing={product.pricing} receiver={productType === ProductType.Receiver} optionalPriceTitle={optionalPriceTitle} />
      </div>

      <div className="card-footer">
        {description && (
          <Button
            type="tertiary"
            onClick={() => open(<ProductModal product={product} toggleProductSelection={toggleProductSelection} />)}
            aria-label={`Les mer om ${name}`}
            data-track-id="card-button"
            data-track-card-name={name}>
            Les mer
          </Button>
        )}
        <Button type="secondary" focusOnRender={shouldFocus} onClick={() => changeStatusOnProduct(product.displayData.conflictingProducts)} {...aria}>
          {isSelected ? 'Angre' : 'Legg til'}
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  conflictingBundleInBasket: (iccCommercialProductId: number) => basket.selectors.getSelectedBundleInBasketWithCpid(state, iccCommercialProductId)
});


export default ProductCardFace;
