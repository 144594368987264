import React from 'react';
import { useSelector } from 'react-redux';
import { app, RootState } from '../../../../../../store';
import { useFocusOnProductInBundle } from '../../../../../../hooks/useFocus';
import { ProductBundle as IProductBundle } from '../../../../../../models/CampaignModel';
import { AdditionalComponents } from '../components';
import ProductBundleHeader from './ProductBundleHeader';
import ProductBundleItems from './ProductBundleItems';

import './product-bundle.sass';
interface Props {
  bundle: IProductBundle;
  index: number;
}

const ProductBundleComponent: React.FC<Props> = ({ bundle, index }) => {
  const { isOpen, shouldFocusFirstBundleProduct } = useSelector((state: RootState) => mapStateToProps(state, bundle.id));
  // WCAG: useFocusOnProductInBundle handles if the angre/legg til button of a collapsible product card should get focus.
  // See the implementation for detailed explanation
  const { productCardToBeFocused, setProductCardToBeFocused } = useFocusOnProductInBundle(shouldFocusFirstBundleProduct, bundle);

  return (
    <div className="product-bundle">
      <ProductBundleHeader bundle={bundle} index={index} setProductCardToBeFocused={setProductCardToBeFocused} isOpen={isOpen} />
      <ProductBundleItems bundle={bundle} index={index} productCardToBeFocused={productCardToBeFocused} isOpen={isOpen} />
      {isOpen && <AdditionalComponents bundleId={bundle.id} />}
    </div>
  );
};

/* this || false is kind of a code smell. We should look into handling that in the selectors */
const mapStateToProps = (state: RootState, bundleId: string) => ({
  isOpen: app.selectors.isBundleOpen(state, bundleId) || false,
  shouldFocusFirstBundleProduct: app.selectors.shouldFocusFirstBundleProduct(state, bundleId) || false,
});

export default ProductBundleComponent;
