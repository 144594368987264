import { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { ProductBundle } from '../models/CampaignModel';

export const useFocus = () => {
  const ref = useRef<HTMLElement>(null);

  const setFocus = useCallback((tabIndex: number = -1) => {
    if (ref.current) {
      ref.current.tabIndex = tabIndex;
      ref.current.focus();
    }
  }, []);

  return { ref, setFocus };
};

export const useButtonFocus = () => {
  const ref = useRef<HTMLButtonElement>(null);

  const setFocus = useCallback(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  useCallback(() => {}, []);

  return { ref, setFocus };
};

/* WCAG: useFocusOnProductInBundle handles if the angre/legg til button of a collapsible product card should get focus:
  1) When selecting a collapsible card, the angre button should get focus
  2) When hitting angre the corresponding legg til btn should focus
  3) When adding new product row the legg til button of the first  product card should get focus (openNextBundle handler)
*/
export const useFocusOnProductInBundle = (shouldFocusFirstBundleProduct: boolean, bundle: ProductBundle) => {
  const initialFocusCard = useMemo(
    () =>
      shouldFocusFirstBundleProduct === true
        ? { shouldFocus: true, productId: bundle.offers[0].id }
        : {
            shouldFocus: false,
            productId: '',
          },
    [shouldFocusFirstBundleProduct, bundle.offers]
  );

  const [productCardToBeFocused, setProductCardToBeFocused] = useState(initialFocusCard);

  useEffect(() => {
    setProductCardToBeFocused(initialFocusCard);
  }, [initialFocusCard]);

  return { productCardToBeFocused, setProductCardToBeFocused };
};
