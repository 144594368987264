import React from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { ProductBundle, SelectableItem } from '../../../../../../models/CampaignModel';
import { basket, campaign, RootState } from '../../../../../../store';
import ProductCard from '../product-cards/ProductCard';

interface Props {
  bundle: ProductBundle;
  index: number;
  isOpen: boolean;
  productCardToBeFocused: { shouldFocus: boolean; productId: string };
}

const ProductBundleItems: React.FC<Props> = ({ bundle, index, isOpen, productCardToBeFocused }) => {
  const selectedInBundle = useSelector((state: RootState) => basket.selectors.getSelectedFromBundleWithData(state, bundle.id));
  const visibleItems: SelectableItem[] = useSelector((state: RootState) =>
    campaign.selectors.getBundleVisibleItems(state, selectedInBundle, bundle.id)
  );
  const hasOffers = bundle && bundle.offers && bundle.offers.length > 0;
  const animateProps = {
    duration: 300,
    animateOpacity: true,
    height: isOpen ? 'auto' : 0,
  };

  return (
    <AnimateHeight {...animateProps}>
      <ul className="product-bundle-items">
        {hasOffers &&
          visibleItems.map((product, key) => (
            <ProductCard
              key={product.id}
              shouldFocus={productCardToBeFocused.shouldFocus && product.id === productCardToBeFocused.productId}
              bundle={bundle}
              index={index}
              product={product}
            />
          ))}
      </ul>
    </AnimateHeight>
  );
};

export default ProductBundleItems;
