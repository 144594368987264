import usePersistedState from './usePersistedState';

const initalState = {
  basket: false,
};
export type ABConfig = typeof initalState;
type ABFlag = keyof ABConfig;

export const isABConfigFlag = (item: string): item is ABFlag => {
  return !!Object.keys(initalState).includes(item);
};

const useABConfig = () => {
  const key = window.ABConfig.ABConfigKey;

  const { state, setState } = usePersistedState<Record<ABFlag, boolean>>(key, initalState);

  const setABFlag = (flagKey: ABFlag, value: boolean) => {
    setState({ ...state, [flagKey]: value });
  };

  const getFlag = (key: ABFlag) => state[key];

  return { ABFlags: state, setABFlag, getFlag };
};

export default useABConfig;
