import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { campaign, RootState } from '../../../store';
import { Loader } from '../../shared';
import { useParams } from 'react-router-dom';
import Steps from './components/steps/StepsContext';
import SupportFooter from '../../shared/support-footer/SupportFooter';
import NotFound from '../not-found/NotFound';
import './campaign.sass';

interface ParamTypes {
  partnerAlias: string;
  dwellingUnit: string;
  campaignUrl: string;
}

const Campaign: React.FC = () => {
  const { partnerAlias, dwellingUnit, campaignUrl } = useParams<ParamTypes>();

  const dispatch = useDispatch();
  const { isCampaignLoaded, isCampaignFailed, isCampaignLoading, currentCampaign } = useSelector((state: RootState) => mapStateToProps(state));

  useEffect(() => {
    dispatch(campaign.creators.setPartnerAlias(partnerAlias));
    dispatch(campaign.creators.setDwellingUnit(dwellingUnit));
    if (!isCampaignLoaded || campaignUrl != currentCampaign?.url ){
      dispatch(campaign.creators.fetchRequest({ partnerAlias, dwellingUnit, campaignUrl }));
    }
  }, [dispatch, partnerAlias, dwellingUnit, campaignUrl]);

  if (isCampaignFailed) {
    return <NotFound />;
  }
  if (isCampaignLoading || !isCampaignLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <Steps />
      <SupportFooter accommodateShoppingCart />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isCampaignLoaded: campaign.selectors.isCampaignLoaded(state),
  isCampaignFailed: campaign.selectors.isCampaignFailed(state),
  isCampaignLoading: campaign.selectors.isCampaignLoading(state),
  currentCampaign: campaign.selectors.getCurrentCampaign(state),
});

export default Campaign;
