interface Config {
  isDevelopment: boolean;
  apiUrl: string;
  customerIdentificationServiceUrl: string;
  myPageLink: string;
  chatLink: string;
}

interface RemoteConfig {
  launchDarklyClientId: string;
}

const config: Config = {
  isDevelopment: import.meta.env.DEV,
  apiUrl: import.meta.env.VITE_API_API_URL || '/api',
  myPageLink: 'https://minside.rikstv.no/',
  chatLink: 'https://www.rikstv.no/chat/',
  customerIdentificationServiceUrl: 'https://integration-vipps.uat.rikstv.no/1',
};

export default config;

export const getConfig = async (): Promise<RemoteConfig> => fetch('/api/remoteconfig').then((response) => response.json());
