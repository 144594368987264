import { IProductPrice } from '../models/product/ProductPrice';
// Use pricing object to format current price
export const formatPrice = (data: IProductPrice): string =>
  data.isRecurring ? formatRecurringAmount(data.price) : formatAmount(data.price);

// Use pricing info tom format original price
export const formatOriginalPrice = (data: IProductPrice): string =>
  data.isRecurring ? formatRecurringAmount(data.originalPrice) : formatAmount(data.originalPrice);

export const formatOfferPrice = (price: number | undefined, offerAppliesToNumberOfMonths: number) =>
  `${price || 0} kr i ${offerAppliesToNumberOfMonths} måneder`;

/**
 * Intl lib was included in polyfill.io that was deleted due to malware. This function might not work anymore.
 */
const formatter = (value: number): string =>
  new Intl.NumberFormat('nb-no', {
    maximumFractionDigits: 0,
  }).format(value);

export const formatAmount = (amount: number = 0): string => {
  return `${formatter(amount)},-`;
};

export const formatRecurringAmount = (amount: number = 0): string => {
  return `${formatter(amount)},- per mnd.`;
};

export const formatKroners = (amount: number = 0): string => {
  return `kr ${formatter(amount)}`;
};

export const removeWhiteSpaces = (str: string | undefined): string => {
  return str?.replace(/\s/g, '') || '';
};

export const removeComas = (str: string | undefined): string => {
  return str?.replace(',', '') || '';
};

export const removePhoneCountryCode = (str: string | undefined): string | undefined => {
  return str?.startsWith('47') && str?.length == 10 ? str.substring(2) : str;
};