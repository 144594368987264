import React from 'react';
import { useSelector } from 'react-redux';
import { basket, RootState } from '../../../../../store';
import { ProductType } from '../../../../../models/product/ProductType';
import BasketList from '../basket-list/BasketList';
import BasketProduct from '../basket-list/basket-list-item/BasketProduct';

interface Props {
  onlyActivelyChosenProducts?: boolean;
}

const SimpleSummary: React.FC<Props> = ({ onlyActivelyChosenProducts = false }) => {
  const selectedProducts = useSelector((state: RootState) => basket.selectors.getBasketProductsWithPrices(state));

  const hasEquipment =
    selectedProducts.filter(
      (product) =>
        product.productType === ProductType.Equipment ||
        product.productType === ProductType.Receiver ||
        product.productType === ProductType.Installation
    ).length > 0;

  const hasPremiums = selectedProducts.filter((product) => product.productType === ProductType.Premium).length > 0;

  const getProductsFromCategory = (category: ProductType, hideIfFree = false) =>
    selectedProducts
      .filter((x) => x.productType === category)
      .map((x, key) => <BasketProduct key={key} product={x} hideIfFree={hideIfFree} />);

  return (
    <>
      <BasketList title="Abonnement">{getProductsFromCategory(ProductType.Subscription)}</BasketList>

      {hasPremiums && <BasketList title="Tilleggspakker">{getProductsFromCategory(ProductType.Premium)}</BasketList>}

      {hasEquipment && (
        <BasketList title="Utstyr">
          {getProductsFromCategory(ProductType.Receiver)}
          {getProductsFromCategory(ProductType.Equipment)}
          {getProductsFromCategory(ProductType.Installation, onlyActivelyChosenProducts)}

          {!onlyActivelyChosenProducts && getProductsFromCategory(ProductType.Shipping)}
        </BasketList>
      )}
    </>
  );
};

export default SimpleSummary;
