import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import './loader.sass';

const Loader = () => (
  <div className="loader">
    <div className="loader-icon">
      <ClipLoader size={16} />
    </div>
    Laster ...
  </div>
);

export default Loader;
