import React from 'react';

interface ExternalLinkProps {
  href: string;
  className?: string;
  ariaLabel?: string;
  target?: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ href, className, ariaLabel, target = '_blank', children }) => (
  <a className={className} href={href} aria-label={ariaLabel} target={target} rel="noopener noreferrer">
    {children}
  </a>
);

export default ExternalLink;
