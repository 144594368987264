import React from 'react';
import { Channel } from '../../../../../models/channel/Channel';
import useModal, { ChannelModal } from '../../../../modal';

interface ChannelListProps {
  channelList: Channel[];
  isRadioChannels?: boolean;
}

const ChannelList: React.FC<ChannelListProps> = ({ channelList, isRadioChannels = false }) => {
  const { open } = useModal();

  return (
    <div className="channel-list">
      {channelList.map((channel, key) => (
        <button
          key={key}
          className="channel"
          aria-label={`Les mer om ${channel.name}`}
          onClick={() => open(<ChannelModal channel={channel} />)}>
          {channel.logoImageUrl && (
            <img src={channel.logoImageUrl} className="logoImage" alt={`${channel.name} logo`} title={channel.name} />
          )}
        </button>
      ))}
      {!isRadioChannels && <p className="requires-internet">Noen av kanalene forutsetter at du har tilgang til internett.</p>}
    </div>
  );
};

export default ChannelList;
