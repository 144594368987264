import React from 'react';
import { connect } from 'react-redux';
import { Notification, ProductConditionSet } from '../../../models/product/Notifications';
import { basket, RootState } from '../../../store';
import { Icon } from '../';

import './product-notification.sass';
interface Props {
  notification: Notification;
  validateProductConditions: Function;
}

const ProductNotification: React.FC<Props> = ({ notification, validateProductConditions }) => {
  const isVisible = validateProductConditions(notification);
  return isVisible ? (
    <div className="product-notification">
      <Icon type="informationCircle" fill="#195A89" size={2} />
      <div>{notification && notification.title}</div>
    </div>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  validateProductConditions: (conditions: ProductConditionSet) => basket.conditions.validateProductConditions(state, conditions),
});

export default connect(mapStateToProps)(ProductNotification);
