import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { creators, AddProductPayload } from './actions';
import { BasketProduct } from '../../models/product/BasketProduct';
import { BasketState, INITIAL_STATE } from '.';
import { SelectionMode } from '../../models/SelectionModel';

const onCreate = (state: BasketState, action: PayloadAction<BasketProduct[]>) => {
  state.products = action.payload;
};

const onAddProduct = (state: BasketState, action: PayloadAction<AddProductPayload>) => {
  switch (action.payload.selectionMode) {
    case SelectionMode.Single:
    case SelectionMode.BestDiscount:
      const oldProducts = state.products.filter((product) => product.bundleId !== action.payload.bundleId);
      state.products = [...oldProducts, ...action.payload.products];
      return;
    case SelectionMode.Multiple:
      state.products = [...state.products, ...action.payload.products];
      return;
    default:
      return;
  }
};

const onRemoveProduct = (state: BasketState, action: PayloadAction<BasketProduct[]>) => {
  const ids = action.payload.map((product) => product.productId);
  state.products = state.products.filter((product) => !ids.includes(product.productId));
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(creators.create, (state, action) => onCreate(state, action))
    .addCase(creators.addProduct, (state, action) => onAddProduct(state, action))
    .addCase(creators.removeProduct, (state, action) => onRemoveProduct(state, action))
    .addCase(creators.setIsLaterActivation, (state, action) => {
      state.isDelayedActivation = action.payload;
    });
});
