import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { app, basket, RootState } from '../../../../../store';
import { creators as appCreators } from '../../../../../store/app';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';
import { Button, Card, Icon } from '../../../../shared';
import { ProductType } from '../../../../../models/product/ProductType';
import { useIsMobileView } from '../../../../../hooks/useMediaQuery';
import useEventListener from '../../../../../hooks/useEventListener';
import SimpleSummary from '../simple-summary/SimpleSummary';
import SimpleTotal from '../simple-total/SimpleTotal';

import './expandable-basket-test-version.sass';

const ExpandableBasketTestVersion = () => {
  const dispatch = useDispatch();
  const isMobileView = useIsMobileView();

  const { selectedProducts, isOpen } = useSelector((state: RootState) => mapState(state));

  const activelyChosenProducts = selectedProducts.filter(
    (product) =>
      product &&
      product.productType !== ProductType.Shipping &&
      !(product.productType === ProductType.Installation && product.pricing.price === 0)
  );

  const hasPremiums = selectedProducts.filter((product) => product.productType === ProductType.Premium).length > 0;
  const name = activelyChosenProducts.length > 0 ? activelyChosenProducts[0].displayData.name : null;

  const parentDivClass = classNames('expandable-basket-test-version', { open: isOpen });
  const summaryContainer = classNames('summary-container', { dual: !isMobileView });
  const premiumAdded = classNames({ 'premium-added': hasPremiums });

  const parentDivAriaLabel = `Handlekurv, ${activelyChosenProducts.length} ${activelyChosenProducts.length === 1 ? 'vare' : 'varer'}`;
  const itemCounterAriaLabel = `${activelyChosenProducts.length} ${activelyChosenProducts.length === 1 ? 'vare' : 'varer'} i handlekurven`;
  const toggleButtonAriaLabel = isOpen ? 'Lukk detaljer for handlekurv' : 'Vis detaljer for handlekurv';

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        dispatch(appCreators.toggleSummary());
      }
    },
    [dispatch]
  );

  useEventListener('keydown', onKeyDown, isOpen);

  return (
    <FocusTrap active={isOpen}>
      <div className={parentDivClass} role="complementary" aria-label={parentDivAriaLabel} tabIndex={-1}>
        <div className="overlay" onClick={() => dispatch(appCreators.toggleSummary())}></div>

        <div className="basket">
          <div className="head" onClick={() => dispatch(appCreators.toggleSummary())}>
            <div className="head-container">
              <div className="head-inner-container">
                <div className="basket-content">
                  {isMobileView && name ? (
                    <h2 className="subscription-text button-or-bold">{name}</h2>
                  ) : (
                    <h2 className="title tag">Min bestilling</h2>
                  )}

                  <div className="flex-container">
                    {!isOpen && (
                      <div className={`icon-and-counter ${premiumAdded}`}>
                        <Icon type="cart" fill="white" size={1.8} />

                        <div
                          className="item-counter button-or-bold"
                          aria-label={itemCounterAriaLabel}
                          aria-live="polite"
                          aria-atomic="true">
                          <span aria-hidden>{activelyChosenProducts.length}</span>
                        </div>
                      </div>
                    )}

                    {!isMobileView && name && <h3 className="subscription-text button-or-bold">{name}</h3>}
                  </div>
                </div>

                <Button type="secondary" iconMargin="left" aria-label={toggleButtonAriaLabel} aria-expanded={isOpen}>
                  {isOpen && isMobileView && <p>Lukk</p>}
                  {isOpen && !isMobileView && <p>Lukk detaljer</p>}
                  {!isOpen && isMobileView && <p>Vis mer</p>}
                  {!isOpen && !isMobileView && <p>Vis detaljer</p>}
                  {!isMobileView && (
                    <Icon type="chevron" fill="white" size={isMobileView ? 1.5 : 2} flip={isOpen ? undefined : 'vertical'} />
                  )}
                </Button>
              </div>
            </div>
          </div>

          {isOpen && (
            <div className="expandable" aria-hidden={!isOpen}>
              <div className={summaryContainer}>
                <Card className="card-left">
                  <SimpleSummary onlyActivelyChosenProducts={true} />
                </Card>
                <Card className={premiumAdded} color="gray-dark">
                  <SimpleTotal />
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
};

const mapState = (state: RootState) => ({
  selectedProducts: basket.selectors.getBasketProductsWithPrices(state),
  isOpen: app.selectors.isSummaryOpen(state),
});

export default ExpandableBasketTestVersion;
