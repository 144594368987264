import React, { useState } from 'react';
import classNames from 'classnames';

import { Icon } from '../../../shared';

import './collapsible-step.sass';

const CollapsibleStep: React.FC<{
  title: string;
  completed: boolean;
  completedText: string | undefined;
  animatedEntry?: boolean;
  className?: string;
}> = ({ children, title, completed, completedText, animatedEntry, className }) => {
  const [userExpanded, setUserExpanded] = useState<boolean>(false);
  const userExpandedOrNotCompleted = userExpanded || !completed;
  const collapsibleStepClassNames = classNames(className, 'collapsible-step', {
    completed: completed,
    expanded: userExpandedOrNotCompleted,
    'animated-entry': animatedEntry,
  });

  return (
    <div className={collapsibleStepClassNames}>
      <h3 className="tag" onClick={() => setUserExpanded(!userExpanded)}>
        {title}
        {completed && <span className="completed-text body-text">{completedText}</span>}
      </h3>

      {completed && (
        <button
          className="chevron-button"
          onClick={() => setUserExpanded(!userExpanded)}
          aria-label={userExpanded ? `Lukk ${title}` : `Åpne ${title}`}>
          <Icon type="chevron" className="chevron" size={2} flip={userExpandedOrNotCompleted ? 'vertical' : undefined} />
        </button>
      )}
      {userExpandedOrNotCompleted && <div className="expandable">{children}</div>}
    </div>
  );
};

export default CollapsibleStep;
