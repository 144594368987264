import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import OnRouteChange from './on-route-change/OnRouteChange';
import ChooseSubscriptionPage from './routes/choose-subscription/ChooseSubscriptionPage';
import Campaign from './routes/campaign/Campaign';
import Receipt from './routes/receipt/Receipt';
import CustomerFilter from './routes/customer-filter/CustomerFilter';
import CustomerVerificationCallback from './routes/callback/CustomerVerificationCallback';
import NotFound from './routes/not-found/NotFound';
import Header from './header/Header';
import useIsDeveloper from '../hooks/useIsDeveloper';
import { Modal } from './modal/Modal';
import DeveloperPage from './routes/developer';
const App: React.FC = () => {
  const { isDeveloper } = useIsDeveloper();

  return (
    <div id="app">
      <OnRouteChange>
        <Modal>
          <Header />

          <Switch>
            <Route path="/callback" component={CustomerVerificationCallback} />
            <Route path="/bekreftelse" component={Receipt} />
            {isDeveloper && <Route path="/developer" component={DeveloperPage} />}
            <Route path="/:partnerAlias/:dwellingUnit/:campaignUrl/:step?" component={Campaign} />
            <Route exact path="/:partnerAlias/:dwellingUnit" component={ChooseSubscriptionPage} />
            <Route exact path="/" component={CustomerFilter} />
            <Route component={NotFound} />
          </Switch>
        </Modal>
      </OnRouteChange>
    </div>
  );
};

export default withRouter(App);
