import moment from 'moment';
import { removeWhiteSpaces } from './format';

// We need to override this function because by default, two digit years above 68 are assumed to be in the 1900's and years below 68 are assumed to be in the 2000's
moment.parseTwoDigitYear = function (input) {
  return parseInt(input, 10) + (parseInt(input, 10) > 16 ? 1900 : 2000);
};

const isEmpty = (value: string): boolean => !value || value === undefined || value === null || value === '';

const isInt = (value: string) => {
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) {
    return false;
  }
  return (floatValue | 0) === floatValue;
};

type Validator = (args: string) => any;

export const composeValidators = (...validators: Validator[]) => (value: string) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const dateFormats = ['DDMMYYYY', 'DDMMYY', 'DD.MM.YYYY', 'DD.MM.YY', 'DD/MM/YYYY', 'DD/MM/YY'];

export const phone = (value: string) => {
  const valueWithoutSpaces = removeWhiteSpaces(value);

  if (!isEmpty(valueWithoutSpaces) && !/^\d{8}$/.test(valueWithoutSpaces)) {
    return 'Mobilnummer må bestå av 8 siffer';
  } else if (!isEmpty(value) && /.*\s\s.*/.test(value)) {
    return 'Mobilnummeret inneholder for mange mellomrom';
  }
};

export const date = (value: string) => {
  const isValidDate = moment(value, dateFormats, true).isValid();
  if (!isValidDate) return 'Fødselsdato må skrives på formatet ddmmåå';
};

export const minAge = (noOfYears: number) => (value: string) => {
  const minDate = moment().subtract(noOfYears, 'y');

  if (!isEmpty(value) && !moment(value, dateFormats).isBefore(minDate)) {
    return `Må være eldre enn ${noOfYears} år`;
  }
};

export const required = (message = 'Påkrevd') => (value: string) => {
  if (isEmpty(value)) {
    return message;
  }
};

export const invalidComma = (value: string) => {
  if (value.match(',')) {
    return 'Adressefeltet kan ikke inneholde komma';
  }
};

export const zip = (value: string) => {
  if (value.length !== 4 || !isInt(value)) {
    return 'Postnummer må bestå av 4 siffer';
  }
};

export const email = (value: string): string | undefined => {
  // Autofill on Chrome on iOS adds single space at the end, so we allow this (but trim in checkoutSaga)
  const trimmedValue = value.trim();

  if (
    (!isEmpty(trimmedValue) &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        trimmedValue
      )) ||
    (!isEmpty(value) && /.*\s\s*./.test(value))
  ) {
    return 'Ugyldig e-post';
  }
};

export const text = (message = 'Ugyldig') => (value: string) => {
  if (!isEmpty(value) && /([0-9])/.test(value)) return message;
};
