import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductBundle as IProductBundle } from '../../../../../models/CampaignModel';
import { FlowMode } from '../../../../../models/FlowMode';
import { ProductType } from '../../../../../models/product/ProductType';
import { RootState, app, campaign, basket } from '../../../../../store';
import { creators as appCreators } from '../../../../../store/app/actions';
import { ProductLimit, Button, Icon } from '../../../../shared';
import ErrorLabel from '../../../../shared/error-label/ErrorLabel';
import ProductGroupHeader from './components/product-group-header/ProductGroupHeader';
import ProductBundle from './product-bundle/ProductBundle';
import './product-group.sass';

interface IGroupConfigContext {
  flowMode: FlowMode;
  type: ProductType;
  activeBundle: string;
  deactivateBundle: (index: number) => void;
}

const GroupConfigContext = React.createContext<IGroupConfigContext | null>(null);

export const useGroupConfig = () => {
  const context = useContext(GroupConfigContext);
  if (!context) {
    throw new Error('Make sure the component you are using this hook from is within the GroupContext');
  }
  return context;
};

interface Props {
  group: IProductBundle[];
  flowMode: FlowMode;
  type: ProductType;
  error?: string;
}

const ProductGroup: React.FC<Props> = ({ flowMode, group, type, error }) => {
  const dispatch = useDispatch();
  const selectors = useSelector((state: RootState) => mapState(state));
  const [activeBundle, setActiveBundle] = useState('');

  const hasAnyBundles = group.length > 0;

  const onInit = useCallback(() => {
    /* Sets up initial configurations based on flowMode */
    const openBundle = (bundleId: string) => dispatch(appCreators.openBundle(bundleId));
    const showBundle = (bundleId: string) => dispatch(appCreators.showBundle(bundleId));

    if (flowMode === FlowMode.Progressive) {
      // Close and hide all bundles on initialization
      group.forEach((bundle) => {
        dispatch(appCreators.closeBundle(bundle.id));
        dispatch(appCreators.hideBundle(bundle.id));
      });
      const selectedBundles = group.filter((bundle) => selectors.hasSelectedInBundle(bundle.id));
      // If none are selected before
      if (selectedBundles.length === 0) {
        openBundle(group[0].id);
        showBundle(group[0].id);
        setActiveBundle(group[0].id);
        return;
      }

      // Show all bundles with selected products
      selectedBundles.forEach((bundle) => showBundle(bundle.id));
      // Set the last bundle of those to the active one
      setActiveBundle(selectedBundles[selectedBundles.length - 1].id);
      return;
    }

    // FlowMode.Open
    group.forEach((bundle) => {
      showBundle(bundle.id);
      openBundle(bundle.id);
    });
    setActiveBundle(group[group.length - 1].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowMode, group]);

  useEffect(() => {
    if (!hasAnyBundles) {
      return;
    }
    onInit();
  }, [hasAnyBundles, onInit]);

  // If the product group contains no bundles we render nothing
  if (!hasAnyBundles) {
    return null;
  }

  const config = selectors.getProductTypeConfig(type);

  const NextRowButton = () => {
    const [iconColor, setIconColor] = useState('#000000');
    const nextRowLabel = config?.buttonLabel || 'Jeg trenger et produkt til';
    const getNextRowId = () => group.find((bundle) => !selectors.isVisible(bundle.id))?.id;
    const shouldShowNextRowButton = () => selectors.hasSelectedInBundle(activeBundle) && getNextRowId();

    const onShowNextRow = () => {
      const bundleId = getNextRowId();
      if (bundleId) {
        dispatch(appCreators.openNextBundle(bundleId));
        setActiveBundle(bundleId);
      }
    };

    if (!shouldShowNextRowButton()) {
      return null;
    }
    return (
      <Button
        type="secondary"
        onClick={onShowNextRow}
        iconMargin="right"
        onMouseEnter={() => setIconColor('#FFFFFF')}
        onMouseLeave={() => setIconColor('#000000')}>
        <Icon type="plus" fill={iconColor} size={1.5} />
        {nextRowLabel}
      </Button>
    );
  };
  const shouldShowProductLimit = group.filter((bundle) => selectors.hasSelectedInBundle(bundle.id)).length === group.length;

  const deactivateBundle = (index: number) => (index !== 0 ? setActiveBundle(group[index - 1].id) : () => {});

  return (
    <div className="product-group">
      {config && <ProductGroupHeader config={config} />}

      <ErrorLabel hasError={!!error} focusOnError={true}>
        {error}
      </ErrorLabel>

      <GroupConfigContext.Provider value={{ flowMode, type, activeBundle, deactivateBundle }}>
        {group.map((bundle, index) => (
          <ProductBundle bundle={bundle} index={index} key={index} />
        ))}

        <NextRowButton />
        {shouldShowProductLimit && <ProductLimit />}
      </GroupConfigContext.Provider>
    </div>
  );
};

const mapState = (state: RootState) => ({
  isVisible: (bundleId: string) => app.selectors.isBundleVisible(state, bundleId),
  hasSelectedInBundle: (bundleId: string) => basket.selectors.hasSelectedInBundle(state, bundleId),
  getProductTypeConfig: (productType: ProductType) => campaign.selectors.getProductTypeConfig(state, productType),
});

export default ProductGroup;
