import * as campaign from './campaign';
import * as channels from './channels';
import * as checkout from './checkout';
import * as basket from './basket';
import * as customerVerification from './customerVerification';
import * as app from './app';
import appReducer from './app/reducers';
import basketReducer from './basket/reducers';
import campaignReducer from './campaign/reducers';
import channelsReducer from './channels/reducers';
import checkoutReducer from './checkout/reducers';
import customerVerificationReducer from './customerVerification/reducers';

import { store } from './configureStore';

export const INITIAL_ROOT_STATE = {
  app: app.INITIAL_STATE,
  basket: basket.INITIAL_STATE,
  campaign: campaign.INITIAL_STATE,
  channels: channels.INITIAL_STATE,
  checkout: checkout.INITIAL_STATE,
  customerVerification: customerVerification.INITIAL_STATE,
};

export const reducers = {
  app: appReducer,
  basket: basketReducer,
  campaign: campaignReducer,
  channels: channelsReducer,
  checkout: checkoutReducer,
  customerVerification: customerVerificationReducer,
};

export type RootState = ReturnType<typeof store.getState>;

export { basket, campaign, channels, checkout, customerVerification, app };
