import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useFocus } from '../../../hooks/useFocus';
import './layout.sass';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  wide?: boolean;
  focusOnRender: boolean;
}

const Layout: React.FC<Props> = ({ wide = false, focusOnRender = false, children, ...rest }) => {
  const { ref, setFocus } = useFocus();

  useEffect(() => {
    if (focusOnRender) {
      setFocus();
    }
  }, [focusOnRender, setFocus]);

  const styles = classnames('container padded', { wide: wide });

  return (
    <main className="main-layout" ref={ref} {...rest}>
      <div className={styles}>{children}</div>
    </main>
  );
};

export default Layout;
