import { createReducer } from '@reduxjs/toolkit';
import { creators } from './actions';
import { INITIAL_STATE } from '.';

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(creators.fetchRequest, (state, action) => {
      state.status = 'loading';
    })
    .addCase(creators.fetchSucceeded, (state, action) => {
      state.status = 'loaded';
      state.campaign = action.payload;
    })
    .addCase(creators.fetchFailed, (state, action) => {
      state.status = 'failed';
    })
    .addCase(creators.changeTrackingPoint, (state, action) => {
      state.trackingPoint = action.payload;
    })
    .addCase(creators.setPartnerAlias, (state, action) => {
      state.partnerAlias = action.payload;
    })
    .addCase(creators.setDwellingUnit, (state, action) => {
      state.dwellingUnit = action.payload;
    })
    .addCase(creators.resetSelectedCampaign, (state) => {
      state.status = "toReload";
    });
});
