import React from 'react';

import GenericSubscription from './generic-subscription/GenericSubscription';
import { SanityGenericSubscription } from '../../../../models/customer-filter/CustomerFilter';
import './generic-subscriptions.sass';

const GenericSubscriptions: React.FC<{ genericSubscriptions: SanityGenericSubscription[] }> = ({ genericSubscriptions }) => {
  return (
    <ul className="generic-subscriptions">
      {genericSubscriptions.map((genericSubscription: SanityGenericSubscription, index: number) => (
        <li key={index}>
          <GenericSubscription genericSubscription={genericSubscription} />
        </li>
      ))}
    </ul>
  );
};

export default GenericSubscriptions;
