import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { basket, RootState } from '../../../../../../store';
import { SelectedIcon } from '../components';
import { ProductType } from '../../../../../../models/product/ProductType';
import ProductPrice from '../product-cards/ProductPrice';

interface Props {
  bundleId: string;
}

const SelectedProducts: React.FC<Props> = ({ children, bundleId }) => {
  const selected = useSelector((state: RootState) => basket.selectors.getSelectedFromBundleWithData(state, bundleId));

  const SelectedImage = ({ url, type, name }: { url: string; type: ProductType; name: string }) => {
    const styles = classNames('selected-product-image', type);
    return (
      <div>
        <img src={url} className={styles} alt={name} />
      </div>
    );
  };
  return (
    <div className="selected-products">
      <SelectedIcon />
      {selected &&
        selected.map((product, key) => (
          <div key={key} className="selected-item">
            <SelectedImage url={product.displayData.imageUrl} type={product.productType} name={product.displayData.name} />

            <div className="selected-product-info">
              <div className="name">{product.displayData.name}</div>
              <ProductPrice pricing={product.pricing} receiver={product.productType === ProductType.Receiver} />
            </div>

            <div className="selected-product-buttons">{children}</div>
          </div>
        ))}
    </div>
  );
};

export default SelectedProducts;
