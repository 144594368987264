import React from 'react';
import { Channel } from '../../../../../models/channel/Channel';
import { Collapsible } from '../../../../shared';
import ChannelList from './ChannelList';
import './channels.sass';

interface ChannelCategoryProps {
  title: string;
  channelList: Channel[];
  isRadioChannels?: boolean;
  toggleChannelCategory: () => void;
  isOpen: boolean;
}

const ChannelCategory: React.FC<ChannelCategoryProps> = ({
  channelList,
  title,
  isRadioChannels = false,
  toggleChannelCategory,
  isOpen,
}) => {
  const header = (
    <div className="category-header">
      <h4 className="category-title">{title}</h4>
      {!isOpen && <span className="channel-names">{channelList.map((x) => x.name).join(', ')}</span>}
    </div>
  );
  return (
    <Collapsible header={header} className="channel-category" toggleExpanded={toggleChannelCategory} isOpen={isOpen} category={title}>
      <ChannelList channelList={channelList} isRadioChannels={isRadioChannels} />
    </Collapsible>
  );
};

export default ChannelCategory;
