interface Address {
  street: string;
  houseNo?: string;
  houseNoAlpha?: string;
}

const trimWhiteSpace = (str: string) => str.trimStart().trimEnd().replace(/  +/g, ' ');

export const splitStreetAddresses = (address: string | undefined): Address => {
  if (!address) {
    return { street: '', houseNo: '', houseNoAlpha: undefined };
  }
  // Trim whitespace, start, end and multiple occurances
  const parts = address.split(/([0-9]+)/).map((str) => trimWhiteSpace(str));
  const [street, houseNo] = parts;
  const houseNoAlpha = parts.slice(2).join('') || undefined;

  if (houseNoAlpha && houseNoAlpha.length > 10) {
    return { street: address, houseNo: undefined, houseNoAlpha: undefined };
  }

  return { street, houseNo, houseNoAlpha };
};
