export const selectCustomStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    position: 'relative',
    padding: 20,
    borderBottom: '2px solid #e9e9e9',
    backgroundColor: state.isSelected || state.isFocused ? '#e9e9e9' : '#ffffff',
    fontWeight: state.isSelected ? 500 : 400,
    color: '#333',
    '&:hover': {
      backgroundColor: '#e9e9e9',
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    height: 50,
    boxShadow: state.isFocused ? '0 0 0 1.5px #195a89' : 'none',
    borderColor: '#000000',
    '&:hover': {
      borderColor: '#000000',
    },
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: 248,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
