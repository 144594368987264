import React from "react";
import Icon from "../icons/Icon";
import "./banner.sass";

interface Props {
  value: {
    icon: string;
    title: string;
  };
}

const Banner: React.FC<Props> = ({ value: { title } }) => {
  return (
    <div className="banner">
      <Icon type="gift" fill="#00816D" size={2} />
      <div>{title}</div>
    </div>
  );
};

export default Banner;
