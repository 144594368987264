import React, { useEffect } from 'react';
import classname from 'classnames';
import { useButtonFocus } from '../../../hooks/useFocus';
import './button.sass';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  focusOnRender?: boolean;
  type: 'primary' | 'primary blue' | 'secondary' | 'tertiary' | 'link-style';
  iconMargin?: 'left' | 'right';
}

const Button: React.FC<Props> = ({ children, disabled, iconMargin, focusOnRender, className, onClick, type, ...rest }) => {
  const { ref, setFocus } = useButtonFocus();

  useEffect(() => {
    if (focusOnRender) {
      setFocus();
    }
  }, [focusOnRender, setFocus]);

  if (disabled) {
    throw Error('We do not have support for disabled buttons');
  }

  const iconClass = iconMargin ? `icon-${iconMargin}` : undefined;
  const styles = classname('button-base button-or-bold', type, iconClass, className);

  return (
    <button ref={ref} className={styles} disabled={disabled} onClick={onClick} {...rest}>
      {children}
    </button>
  );
};

export default Button;
