import { takeLatest, call, put } from 'redux-saga/effects';
import * as campaignApi from '../store/campaign/api';
import { types as campaignTypes, creators as campaignCreators } from '../store/campaign/actions';

function* fetchCampaign(action) {
  try {
    const campaign = yield call(
      campaignApi.getCampaign,
      action.payload.partnerAlias,
      action.payload.dwellingUnit,
      action.payload.campaignUrl
    );
    yield put(campaignCreators.fetchSucceeded(campaign));
  } catch (err) {
    yield put(campaignCreators.fetchFailed(err));
  }
}

function* main() {
  yield takeLatest(campaignTypes.FETCH_REQUEST, fetchCampaign);
}

export default main;
