import { createAction } from '@reduxjs/toolkit';
import { FormValues } from '.';

const types = {
  CHECKOUT_SUCCEEDED: 'CHECKOUT_SUCCEEDED',
  UPDATE_FORM_VALUES: 'CHECKOUT_UPDATE_FORM_VALUES',
  UPDATE_FORM_META: 'CHECKOUT_UPDATE_FORM_META',
  SUBMIT_FORM: 'CHECKOUT_SUBMIT_FORM',
  RESET_USER_DATA: 'CHECKOUT_RESET_USER_DATA',
};

const creators = {
  checkoutSucceeded: createAction<string>(types.CHECKOUT_SUCCEEDED),
  updateFormValues: createAction<{ values: Partial<FormValues> }>(types.UPDATE_FORM_VALUES),
  updateFormMeta: createAction<{ valid: boolean; pristine: boolean }>(types.UPDATE_FORM_META),
  submitForm: createAction(types.SUBMIT_FORM),
  resetUserData: createAction(types.RESET_USER_DATA),
};

export { types, creators };
