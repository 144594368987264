import { RootState } from '..';
import { ConditionType, ProductCondition, ProductConditionSet } from '../../models/product/Notifications';
import { ProductWithBundleId } from '../campaign/selectors';
import { getBasketProductsWithData } from './selectors';

// PRODUCT CONDITIONS
export const validateProductConditions = (state: RootState, conditions: ProductConditionSet) => {
  const basketProducts = getBasketProductsWithData(state);
  let result = true;

  if (conditions && conditions.productConditionsAny !== null) {
    let hits = evaluateConditionGroup(basketProducts, conditions.productConditionsAny);
    if (hits.length === 0) result = false;
  }

  if (conditions && conditions.productConditionsAll !== null) {
    let hits = evaluateConditionGroup(basketProducts, conditions.productConditionsAll);
    if (hits.length !== conditions.productConditionsAll.length) result = false;
  }

  return result;
};

export const evaluateConditionGroup = (products: ProductWithBundleId[], conditions: ProductCondition[]) =>
  conditions.map((condition) => evaluateCondition(products, condition)).filter((x) => x === true);

export const evaluateCondition = (products: ProductWithBundleId[], condition: ProductCondition) => {
  const quantityInBasket = products.filter((x) => x.offer.commercialProductId === condition.commercialProductId).length;
  switch (condition.conditionType) {
    case ConditionType.LessOrEqual:
      return quantityInBasket <= condition.quantity;
    case ConditionType.Equal:
      return quantityInBasket === condition.quantity;
    case ConditionType.GreaterOrEqual:
      return quantityInBasket >= condition.quantity;
    default:
      return false;
  }
};
