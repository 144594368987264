export interface SelectionConfig {
  selectionMode: SelectionMode;
  maxItems: number;
  maxDiscountedItems: number;
  showProductHats: boolean;
}

export enum SelectionMode {
  Single = 'single',
  Multiple = 'multiple',
  BestDiscount = 'bestDiscount',
}
