import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useTitle } from '../../../../../hooks/useTitle';
import { app, basket, campaign, checkout, customerVerification } from '../../../../../store';
import { MainTitle } from '../../../../shared';
import ExpandableBasketTestVersion from '../expandable-basket/ExpandableBasketTestVersion';
import stepConfig, { type Step, StepId } from './StepConfig';
import StepList from './StepList';
import StepNavigation from './StepNavigation';

interface StepContext {
  currentStep: number;
  steps: Step[];
  navigate: (stepNumber: number) => void;
  pathForStep: (stepNumber: number) => string;
  isStepValid: (stepNumber: number) => boolean;
  setOnNext: (stepNumber: number, onNext: (() => void) | undefined) => void;
}

const StepsContext = React.createContext<StepContext | null>(null);

export const useSteps = () => {
  const context = useContext(StepsContext);
  if (!context) {
    throw new Error('Make sure the component you are using this hook from is within the StepsContext');
  }
  return context;
};

const Steps: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const productComparisonRoute = useSelector(campaign.selectors.getProductComparisonRoute);
  const campaignRoute = useSelector(campaign.selectors.getCampaignRoute);
  const subscription = useSelector(campaign.selectors.getMainSubscriptionName);
  const isFiberOrder = useSelector(campaign.selectors.isFiberOrder);
  const partnerDetails = useSelector(campaign.selectors.getPartnerDetails);
  const hasOrderedReceiver = useSelector(basket.selectors.hasOrderedReceiver);
  const isCheckoutFormValid = useSelector(checkout.selectors.isCheckoutFormValid);
  const isSummaryOpen = useSelector(app.selectors.isSummaryOpen);
  const isVerifiedUser = useSelector(customerVerification.selectors.IsVerifiedUser);
  const hasVerificationProvider = useSelector(customerVerification.selectors.HasVerificationProviderSelected);

  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(stepConfig);
  const [pageTitle, setPageTitle] = useState('');

  useTitle(pageTitle);

  const isStepValid = useCallback(
    (stepNumber: number): boolean => {
      const stepId = steps[stepNumber].id;
      switch (stepId) {
        case StepId.Content:
          return true;
        case StepId.Equipment:
          return hasOrderedReceiver; // Should not be able to proceed from here without a receiver selected.
        case StepId.Verification:
          return hasVerificationProvider;
        case StepId.ContactInformation:
          return isCheckoutFormValid && isVerifiedUser; // Should not be able to proceed from here without a valid form and being verified user.
        case StepId.Summary:
          return false; // Should not be able to proceed without having terms accepted.
        default:
          throw new Error('All steps should be explicitly have the validity defined.');
      }
    },
    [hasOrderedReceiver, isCheckoutFormValid, steps, isVerifiedUser, hasVerificationProvider]
  );

  const pathForStep = useCallback(
    (stepNumber: number) => (stepNumber >= 0 ? `${campaignRoute}/${steps[stepNumber].path}` : `/${productComparisonRoute}`),
    [steps, campaignRoute, productComparisonRoute]
  );

  const navigate = useCallback((stepNumber: number) => history.push(pathForStep(stepNumber)), [history, pathForStep]);

  const setOnNext = useCallback((stepNumber: number, onNextFunction: (() => void) | undefined) => {
    steps[stepNumber].onNavigateNext = onNextFunction;
    setSteps(steps);
  }, [steps]);

  /* This will ensure that the steps are reflected off the URL,
    and that the user is allowed to be there. */
  useEffect(() => {
    // Find the step loaded by the url (Skip the first step, as it will always match this since it's path is '')
    const loadedStep = steps.findIndex((step) => location.pathname.includes(step.path) && step.id !== StepId.Content);
    if (loadedStep > 0) {
      const firstInvalidStep = steps.findIndex((_, index) => !isStepValid(index));

      // User navigated to a step, and it is valid or loaded into the first invalid step.
      if (isStepValid(loadedStep) || loadedStep === firstInvalidStep) {
        setCurrentStep(loadedStep);
        return;
      }
      navigate(firstInvalidStep); // Recursively calls this to set CurrentStep
    } else {
      setCurrentStep(0); // User navigated to the first step, which has to be valid.
    }
  }, [location, steps, isStepValid, navigate]);

  // Updates the redux store with the current steps, and set the page title
  useEffect(() => {
    const step = steps[currentStep];

    setPageTitle(`Bestill ${subscription} - Steg ${currentStep + 1}: ${step.title} | RiksTV`);
    dispatch(campaign.creators.changeTrackingPoint(step.trackingPoint));
  }, [currentStep, subscription, dispatch, steps]);

  const context = {
    currentStep,
    steps,
    navigate,
    pathForStep,
    setOnNext,
    isStepValid,
  };

  const enableExpandableBasket = steps[currentStep].id !== StepId.Summary;

  return (
    <StepsContext.Provider value={context}>
      <div className="campaign light">
        <MainTitle isCampaignTitle={true} hasSubtitle={isFiberOrder}>
          Bestilling av {subscription}
        </MainTitle>
        {isFiberOrder && <h2 className="campaign-subtitle">via fiber fra {partnerDetails?.name}</h2>}
        <div className="steps" aria-hidden={isSummaryOpen}>
          <nav className="steps-header" aria-label="Stegindikator bestillingsløp" tabIndex={-1}>
            <StepList />
          </nav>
          <Switch>
            {stepConfig.map((step, key) => (
              <Route exact key={key} path={`${campaignRoute}/${step.path}`} render={() => step.component} />
            ))}
          </Switch>
        </div>
        {enableExpandableBasket && <ExpandableBasketTestVersion />}
      </div>
      <StepNavigation />
    </StepsContext.Provider>
  );
};

export default Steps;
