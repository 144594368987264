import React from 'react';
import classnames from 'classnames';
import { SelectableItem } from '../../../../../../models/CampaignModel';

interface ProductCardImageProps {
  product: SelectableItem;
}

const ProductCardImage: React.FC<ProductCardImageProps> = ({ product }) => {
  const { productType, displayData } = product;
  const { imageUrl, backgroundImageUrl } = displayData;
  const imageClass = classnames('product-card-image', productType);
  const backgroundImageClass = classnames('product-card-background-image', productType);
  return (
    <div>
      {backgroundImageUrl ? (
        <img className={backgroundImageClass} src={backgroundImageUrl} alt="" />
      ) : (
        <img className={imageClass} src={imageUrl} alt="" />
      )}
    </div>
  );
};

export default ProductCardImage;
