import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionMode } from '../../../../../../models/SelectionModel';
import { app, basket, RootState } from '../../../../../../store';
import { FlowMode } from '../../../../../../models/FlowMode';
import { useGroupConfig } from '../ProductGroup';
import ProductCardFace from './ProductCardFace';
import { ProductBundle, SelectableItem } from '../../../../../../models/CampaignModel';

import './product-card.sass';

interface Props {
  bundle: ProductBundle;
  index: number;
  product: SelectableItem;
  shouldFocus: boolean;
}

const ProductCard: React.FC<Props> = ({ bundle, index, product, shouldFocus }) => {
  const { flowMode } = useGroupConfig();
  const dispatch = useDispatch();
  const selectedFromBundle = useSelector((state: RootState) => basket.selectors.getSelectedFromBundle(state, bundle.id));
  const isSelected = useSelector((state: RootState) => basket.selectors.isProductSelected(state, product.id));
  const productToHandle = useSelector((state: RootState) => basket.selectors.getProductsToHandle(state, bundle, product));

  const { selectionMode, maxItems } = bundle.selectionConfig;

  const actionData = {
    selectionMode: bundle.selectionConfig.selectionMode,
    bundleId: bundle.id,
    products: productToHandle,
  };

  const canRemoveProduct = selectionMode === SelectionMode.Single || selectionMode === SelectionMode.Multiple || index !== 0;

  const getAction = () => {
    if (selectionMode === SelectionMode.BestDiscount) {
      return () => dispatch(basket.creators.addProduct(actionData));
    }
    if (isSelected && canRemoveProduct) {
      return () => dispatch(basket.creators.removeProduct(actionData.products));
    }
    if (!isSelected && selectionMode === SelectionMode.Single) {
      return () => {
        dispatch(basket.creators.addProduct(actionData));
        if (flowMode === FlowMode.Progressive) {
          dispatch(app.creators.closeBundle(bundle.id));
        }
      };
    }
    if (!isSelected && selectionMode === SelectionMode.Multiple) {
      if (maxItems === 0 || selectedFromBundle.length < maxItems) {
        // maxItems = 0 equals no restriction
        return () => dispatch(basket.creators.addProduct(actionData));
      }
    }
    if (!isSelected) {
      return () => dispatch(basket.creators.addProduct(actionData));
    }
    return () => {};
  };

  return (
    <li className="product-card">
      <ProductCardFace shouldFocus={shouldFocus} toggleProductSelection={getAction()} product={product} bundle={bundle} />
    </li>
  );
};

export default ProductCard;
