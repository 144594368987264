import React from 'react';
import { useIsIframe } from '../../hooks/useIsIframe';
import logo from '../../assets/images/rikstv-logo.svg?url';

import './header.sass';

const Header = () => {
  const isIframe = useIsIframe();

  if (isIframe) {
    return null;
  }

  return (
    <header>
      <a className="logo" href="https://www.rikstv.no" aria-label="Gå til RiksTVs hjemmeside" title="Gå til RiksTVs hjemmeside">
        <img src={logo} alt="RiksTV logo" />
      </a>
    </header>
  );
};

export default Header;
