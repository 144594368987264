import React from 'react';
import classNames from 'classnames';

import './card.sass';

interface Props {
  color?: 'blue' | 'gray-dark';
  className?: string;
}

const Card: React.FC<Props> = ({ children, color = '', className = '' }) => (
  <div className={classNames('card', color, className)}>{children}</div>
);

export default Card;
