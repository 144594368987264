import React from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import Icon from '../icons/Icon';
import './collapsible.sass';

interface CollapsibleProps {
  header?: JSX.Element;
  category?: string;
  className?: string;
  isOpen: boolean;
  toggleExpanded: () => void;
}

const Collapsible: React.FC<CollapsibleProps> = ({ children, header, category, className, isOpen, toggleExpanded }) => {
  const bodyAnimateProps = {
    duration: 300,
    animateOpacity: true,
    height: isOpen ? 'auto' : 0,
  };
  const mainClassName = classnames('collapsible', className);
  return (
    <div className={mainClassName} data-channels-accordion={category ? category : 'header'}>
      <button className="header" onClick={toggleExpanded} aria-expanded={isOpen}>
        {header}
        <Icon type="chevron" flip={isOpen ? 'vertical' : undefined} size={2.5} />
      </button>
      <AnimateHeight {...bodyAnimateProps}>{children}</AnimateHeight>
    </div>
  );
};

export default Collapsible;
