import { Action, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ChannelsState, INITIAL_STATE } from '.';
import { LoadingStatus } from '../../models/loading';
import { Channel } from '../../models/channel/Channel';
import { creators } from './actions';

const onChannelListRequested = (state: ChannelsState, action: PayloadAction<number>) => {
  state.status = LoadingStatus.Loading;
};

const onChannelListSucceeded = (state: ChannelsState, action: PayloadAction<Channel[]>) => {
  state.channelList = action.payload;
  state.status = LoadingStatus.Loaded;
};

const onChannelListFailed = (state: ChannelsState, _: Action) => {
  state.status = LoadingStatus.Failed;
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(creators.channelListRequested, (state, action) => onChannelListRequested(state, action))
    .addCase(creators.channelListSucceeded, (state, action) => onChannelListSucceeded(state, action))
    .addCase(creators.channelListFailed, (state, action) => onChannelListFailed(state, action));
});
