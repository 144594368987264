import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as campaign from '../store/campaign';
import * as channels from '../store/channels';

function* fetchChannels(action) {
  try {
    yield put(channels.creators.channelListRequested());
    const commercialProductId = yield select(campaign.selectors.getMainSubscriptionCommercialProductId);
    const list = yield call(channels.api.fetchActiveChannels, commercialProductId);
    yield put(channels.creators.channelListSucceeded(list));
  } catch (error) {
    yield put(channels.creators.channelListFailed(error));
  }
}

function* main() {
  yield takeLatest(campaign.types.FETCH_SUCCEEDED, fetchChannels);
}

export default main;
