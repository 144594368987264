import * as selectors from './selectors';
import * as conditions from './conditions';
import { BasketProduct } from '../../models/product/BasketProduct';
import { types, creators } from './actions';

export interface BasketState {
  products: BasketProduct[];
  isDelayedActivation: boolean;
}

const INITIAL_STATE: BasketState = {
  products: [],
  isDelayedActivation: false,
};

export { selectors, types, creators, conditions, INITIAL_STATE };
