import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkout as checkoutStore, customerVerification } from '../../../store';
import { useTitle } from '../../../hooks/useTitle';
import { Layout, Card } from '../../shared';
import SupportFooter from '../../shared/support-footer/SupportFooter';
import { getAuthToken, getVerifiedUserData, toFormValues } from '../campaign/verification/vipps/api';
import { useHistory, useLocation } from "react-router-dom";
import { campaign} from '../../../store';

const CustomerVerificationCallback = () => {
  useTitle('Verification | RiksTV');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const campaignRoute = useSelector(campaign.selectors.getCampaignRoute);
  const selectedProvider = useSelector(customerVerification.selectors.GetSelectedVerificationProvider);
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  useEffect(() => {
    if (code !== null) {
      getAuthToken(code, selectedProvider)
      .then(authResp => {
          getVerifiedUserData(authResp.access_token, selectedProvider)
          .then(userResp => {
              const formValues = toFormValues(userResp);
              dispatch(checkoutStore.creators.updateFormValues({ values: formValues }));
              dispatch(customerVerification.creators.customerVerificationSucceeded ({ isVerified: true, verificationDetails: { provider: selectedProvider, token: authResp.access_token } }));              
              history.replace(`${campaignRoute}/kontaktinfo`);
            });
        }, (err) => {
          console.error("Could not get required data, please restart verification process", err);
          history.replace(`${campaignRoute}/identifisering?restartRequired=true`);
        });
    }
    else {
      history.replace(`${campaignRoute}/identifisering`);
    };
  }, [dispatch, history.replace, code, campaignRoute, selectedProvider]);

  return (
    <Layout className="main-layout" focusOnRender wide={true}>
      <Card className="not-found">
        <h1>Venter på verifisering...</h1>
        <p className="top-text">
          Dette kan ta noen sekunder, vennligst ikke lukk denne siden. <br />
        </p>
        <SupportFooter displayHeading={false} />
      </Card>
    </Layout>
  );
};

export default CustomerVerificationCallback;