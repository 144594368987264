import React from 'react';
import { Checkbox } from '../shared';
import useABConfig, { ABConfig, isABConfigFlag } from '../../hooks/useABConfig';

const DeveloperPage = () => {
  const { ABFlags, setABFlag } = useABConfig();

  const onClick = (key: keyof ABConfig) => {
    setABFlag(key, !ABFlags[key]);
  };

  const ABFlagList = ({ flags }: { flags: ABConfig }) => (
    <>
      {Object.keys(flags)
        .filter(isABConfigFlag)
        .map((key) => (
          <li key={key}>
            <Checkbox checked={flags[key]} onClick={() => onClick(key)} label={key} />
          </li>
        ))}
    </>
  );

  return (
    <>
      <h1> Developer page </h1>
      <div>
        <h2> A/B Tests </h2>
        <ul>
          <ABFlagList flags={ABFlags} />
        </ul>
      </div>
    </>
  );
};

export default DeveloperPage;
