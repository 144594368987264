import React from 'react';
import { Channel } from '../../../../models/channel/Channel';
import './ChannelModal.sass';

interface Props {
  channel: Channel;
}

const ChannelModal: React.FC<Props> = ({ channel }) => {
  return (
    <div className="channel-modal">
      <div className="header">
        {channel.logoImageUrl && <img src={channel.logoImageUrl} alt="" className="channel-logo" />}
        <h1 className="title-3">{channel.name}</h1>
      </div>
      <p>{channel.description}</p>
    </div>
  );
};

export default ChannelModal;
