import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { campaign, basket, RootState } from '../../../../store';

import ProductGroup from '../components/product-group/ProductGroup';
import { Layout } from '../../../shared';
import { useSteps } from '../components/steps/StepsContext';
import { FlowMode } from '../../../../models/FlowMode';
import { ProductType } from '../../../../models/product/ProductType';

const Equipment = () => {
  const { selectableProducts, hasOrderedReceiver } = useSelector((state: RootState) => ({
    selectableProducts: campaign.selectors.getSelectableProducts(state),
    hasOrderedReceiver: basket.selectors.hasOrderedReceiver(state),
  }));

  const { setOnNext, isStepValid, currentStep } = useSteps();
  const [receiverError, setReceiverError] = useState('');

  // Clear error when receiver
  useEffect(() => {
    if (hasOrderedReceiver) {
      setReceiverError('');
    }
  }, [hasOrderedReceiver]);

  useEffect(() => {
    const onNext = isStepValid(currentStep) ? undefined : () => setReceiverError('Du må velge minst én TV-boks.');
    setOnNext(currentStep, onNext);
  }, [isStepValid, setReceiverError, currentStep, setOnNext]);

  if (selectableProducts === undefined) {
    return null;
  }
  return (
    <Layout focusOnRender={true} wide={true} aria-label="Steg 2 av 5 - Utstyr">
      <ProductGroup group={selectableProducts.receiver} flowMode={FlowMode.Progressive} type={ProductType.Receiver} error={receiverError} />
      <ProductGroup group={selectableProducts.equipment} flowMode={FlowMode.Progressive} type={ProductType.Equipment} />
      <ProductGroup group={selectableProducts.installation} flowMode={FlowMode.Open} type={ProductType.Installation} />
    </Layout>
  );
};

export default Equipment;
