import React from 'react';
import { Notification } from '../../../../../../../models/product/Notifications';
import { ProductTypeConfig } from '../../../../../../../models/product/ProductTypeConfig';
import { DefaultBlockContent } from '../../../../../../shared';
import ProductNotification from '../../../../../../shared/productNotification/ProductNotification';

interface ProductGroupHeaderProps {
  config: ProductTypeConfig;
}
const ProductGroupHeader: React.FC<ProductGroupHeaderProps> = ({ config }) => (
  <div className="product-group-header">
    {config.title && <h2 className="product-group-title title-2">{config.title}</h2>}
    {config.productNotifications?.map((notification: Notification, index: number) => (
      <ProductNotification key={index} notification={notification} />
    ))}
    <DefaultBlockContent blocks={config.description} className="product-group-description" />
  </div>
);

export default ProductGroupHeader;
