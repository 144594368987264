import { createSelector } from 'reselect';
import { RootState } from '..';
import { ProductBundle, SelectableItem } from '../../models/CampaignModel';
import { CustomerType } from '../../models/CustomerType';
import { IProductPrice } from '../../models/product/ProductPrice';
import { ProductType } from '../../models/product/ProductType';
import { defaultProductTypeConfig, ProductTypeConfig } from '../../models/product/ProductTypeConfig';
import { SelectionMode } from '../../models/SelectionModel';

export const getCurrentCampaign = (state: RootState) => state.campaign.campaign;
export const isCampaignLoading = (state: RootState) => state.campaign.status === 'loading';
export const isCampaignLoaded = (state: RootState) => state.campaign.status === 'loaded';
export const isCampaignFailed = (state: RootState) => state.campaign.status === 'failed';
export const getCampaignCode = (state: RootState) => (state.campaign.campaign ? state.campaign.campaign.campaignCode : undefined);
export const getAdditionalTerms = (state: RootState) => (state.campaign.campaign ? state.campaign.campaign.additionalTerms : []);
export const getCampaignTrackingCode = (state: RootState) => state.campaign.campaign?.campaignCode;
export const getSelectableProducts = (state: RootState) => state.campaign.campaign?.products;

// Fiber & partners
export const getPartnerAlias = (state: RootState) => state.campaign.partnerAlias;
export const getPartnerDetails = (state: RootState) => state.campaign.campaign?.partner;
export const getDwellingUnit = (state: RootState) => state.campaign.dwellingUnit;
export const getCustomerType = (state: RootState) => state.campaign.campaign?.customerType as CustomerType;

// Fiber order includes customer type "IP Normal" and "IP Normal i BRL"
export const isFiberOrder = (state: RootState) =>
  state.campaign.campaign?.customerType === CustomerType.IpNormal || state.campaign.campaign?.customerType === CustomerType.IpNormalBRL;

// Routing & steps
export const getCampaignRoute = (state: RootState): string | undefined =>
  state.campaign.campaign ? `/${getPartnerAlias(state)}/${getDwellingUnit(state)}/${state.campaign.campaign.url}` : undefined;

export const getProductComparisonRoute = (state: RootState): string | undefined => `${getPartnerAlias(state)}/${getDwellingUnit(state)}`;

export const getProductTypeConfig = (state: RootState, type: ProductType): ProductTypeConfig => {
  const currentCampaign = state.campaign.campaign;
  if (currentCampaign && currentCampaign.configs) {
    const configs = currentCampaign.configs;
    switch (type) {
      case ProductType.Premium:
        return configs.premium;
      case ProductType.Receiver:
        return configs.receiver;
      case ProductType.Equipment:
        return configs.equipment;
      case ProductType.Installation:
        return configs.installation;
      default:
        return defaultProductTypeConfig;
    }
  }
  return defaultProductTypeConfig;
};

// Main subscription data
export const getMainSubscription = createSelector([getSelectableProducts], (products) => products?.subscription[0]?.offers[0]);
export const getMainSubscriptionName = createSelector([getMainSubscription], (subscription) => subscription?.displayData.name || '');
export const getMainSubscriptionCommercialProductId = createSelector(
  [getMainSubscription],
  (subscription) => subscription?.offer?.commercialProductId
);

// Rename to GetAllBundles
export const getFlatBundleList = (state: RootState) => {
  if (state.campaign.campaign === undefined || state.campaign.campaign.products === undefined) {
    return [];
  }
  const products = state.campaign.campaign.products;
  const bundles: ProductBundle[] = [
    ...products.equipment,
    ...products.installation,
    ...products.premium,
    ...products.receiver,
    ...products.shipment,
    ...products.subscription,
  ];
  return bundles;
};

export type ProductWithBundleId = SelectableItem & { bundleId: string };
export const getFlatProductList = createSelector([getFlatBundleList], (bundles) => {
  return bundles.flatMap((bundle) =>
    bundle.offers.map((offer) => {
      return { bundleId: bundle.id, ...offer };
    })
  );
});

export const getAvailableOffersForCommercialProductId = (state: RootState, commercialProductId: number) => {
  const offers = getFlatProductList(state);
  return offers.filter((product) => product.offer.commercialProductId === commercialProductId);
};

// Best discount selection
export const isDiscountedOffer = (offer: SelectableItem) => calculateSavingsFirstYear(offer.pricing) > 0;
export const containsCommercialProduct = (offerList: SelectableItem[], currentOffer: any) =>
  !!offerList.find((offer) => offer.offer.commercialProductId === currentOffer.offer.commercialProductId);
export const containsCompositeProduct = (offerList: SelectableItem[], currentOffer: any) =>
  !!offerList.find((offer) => offer.offer.compositeId === currentOffer.offer.compositeId);

export const getBundleVisibleItems = (state: RootState, selectedInBundle: SelectableItem[], bundleId: string) => {
  const bundle = getFlatBundleList(state).find((bundle) => bundle.id === bundleId);
  if (bundle === undefined) {
    return [];
  }

  if (bundle.selectionConfig.selectionMode === SelectionMode.BestDiscount) {
    return getBestDiscountVisibleOffers(state, selectedInBundle, bundle);
  }

  return bundle.offers;
};
const calculateSavingsFirstYear = (price: IProductPrice): number => {
  if (!price.isRecurring) {
    return price.originalPrice - price.price;
  }
  // If no months defined, we default to one year
  return (price.originalPrice - price.price) * (price.offerPeriodInMonths ?? 12);
};

export const getBestDiscountVisibleOffers = (state: RootState, selectedInBundle: SelectableItem[], bundle: ProductBundle) => {
  const maxDiscountedItems = bundle.selectionConfig.maxDiscountedItems;
  let filteredOffers: any = [];

  // Get a unique list of commercialProductIds from the bundles.
  const originalProductOrder = bundle.offers
    .map((offer) => offer.offer.commercialProductId)
    .filter((value, index, arr) => arr.indexOf(value) === index);

  // Remap & sort by savings
  let bundleOffers = bundle.offers.slice();

  bundleOffers.sort((a, b) => calculateSavingsFirstYear(b.pricing) - calculateSavingsFirstYear(a.pricing));

  // Item limit
  const currentDiscountedItems = selectedInBundle.filter((offer) => isDiscountedOffer(offer)) || [];
  const hasReachedDiscountLimit = currentDiscountedItems.length >= maxDiscountedItems;

  // Get one offer for each commercial product
  // 1. If it is single offer, display this offer
  // 2. If the offer is in basket, display this offer
  // 3. If the offer is discounted and limit is not reached, display discounted offer
  // 4. If the limit is reached, display normal offer
  for (const commercialProductId of originalProductOrder) {
    const productSubset = bundleOffers.filter((offer) => offer.offer.commercialProductId === commercialProductId);
    for (const offer of productSubset) {
      const isSingleOffer = productSubset.length === 1;
      const selectedThisOffer = containsCompositeProduct(selectedInBundle, offer);
      const selectedThisProduct = containsCommercialProduct(selectedInBundle, offer);
      if (
        isSingleOffer ||
        selectedThisOffer ||
        (isDiscountedOffer(offer) && !hasReachedDiscountLimit) ||
        (!isDiscountedOffer(offer) && hasReachedDiscountLimit && !selectedThisProduct)
      ) {
        filteredOffers.push(offer);
        break;
      }
    }
  }
  return filteredOffers;
};

export const getStartingBasket = createSelector([getFlatProductList], (products) => {
  return products
    .filter((product) => product.isPreChosen === true)
    .map((product) => ({ bundleId: product.bundleId, productId: product.id }));
});

export const getTrackableCampaign = (state: RootState) => ({
  campaignCode: state.campaign.campaign?.campaignCode,
  campaignVariant: state.campaign.campaign?.url,
});
