import React from 'react';
import { TrackingPoints } from '../../../../../sagas/trackingSaga';
import Checkout from '../../checkout/Checkout';
import Content from '../../content/Content';
import Equipment from '../../equipment/Equipment';
import Summary from '../../summary/Summary';
import Verification from '../../verification/Verification';

export enum StepId {
  Content,
  Equipment,
  Verification,  
  ContactInformation,
  Summary,
}

export interface Step {
  id: StepId;
  title: string;
  path: string;
  component: JSX.Element;
  trackingPoint: TrackingPoints;
  onNavigateNext?: () => void;
}
const stepConfig: Step[] = [
  {
    id: StepId.Content,
    title: 'Innhold',
    path: '',
    trackingPoint: TrackingPoints.Content,
    component: <Content />,
  },
  {
    id: StepId.Equipment,
    title: 'Utstyr',
    path: 'utstyr',
    trackingPoint: TrackingPoints.Equipment,
    component: <Equipment />,
  },
  {
    id: StepId.Verification,
    title: 'Identifisering',
    path: 'identifisering',
    trackingPoint: TrackingPoints.Verification,
    component: <Verification />,
  },
  {
    id: StepId.ContactInformation,
    title: 'Kontakt',
    path: 'kontaktinfo',
    trackingPoint: TrackingPoints.ContactInformation,
    component: <Checkout />,
  },
  {
    id: StepId.Summary,
    title: 'Se over',
    path: 'oppsummering',
    trackingPoint: TrackingPoints.Summary,
    component: <Summary />,
  },
];
export default stepConfig;
