import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useSteps } from './StepsContext';
import { Icon } from '../../../../shared/';
import './steps.sass';

const getStepType = (stepNumber: number, currentStepNumber: number) => {
  if (currentStepNumber > stepNumber) return 'previous';
  if (currentStepNumber === stepNumber) return 'current';
  else return 'next';
};

interface StepIconProps {
  title: string;
  stepPath: string;
  stepType: 'previous' | 'current' | 'next';
  stepNumber: number;
}
const StepIcon = ({ title, stepPath, stepType, stepNumber }: StepIconProps) => {
  const displayedStepnumber = stepNumber + 1;

  const className = classnames('step', stepType);
  if (stepType === 'previous') {
    return (
      <li className={className}>
        <Link to={stepPath} aria-label={`Gå tilbake til steg ${displayedStepnumber}, ${title}`}>
          <div className="step-icon">
            <Icon type="checkmark" size={1.5} />
          </div>
          <div className="step-title">{title}</div>
        </Link>
      </li>
    );
  }

  let ariaLabel = `Steg ${displayedStepnumber} av 4, ${title}`;
  if (stepType !== 'current') {
    ariaLabel = ariaLabel + ', naviger til påfølgende sider via Neste-knapp i bunnen av siden';
  }
  return (
    <li className={className} aria-label={ariaLabel} aria-current={stepType === 'current' ? 'step' : 'false'}>
      <div className="step-icon">{displayedStepnumber}</div>
      <div className="step-title">{title}</div>
    </li>
  );
};

const StepList = () => {
  const { currentStep, steps, pathForStep } = useSteps();
  const stepList = steps.map((step, index) => {
    const stepPath = pathForStep(index);
    const stepType = getStepType(index, currentStep);
    return <StepIcon title={step.title} key={step.id} stepNumber={index} stepPath={stepPath} stepType={stepType} />;
  });
  return <ul className="step-list"> {stepList} </ul>;
};

export default StepList;
