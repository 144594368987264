import React from 'react';
import { useSelector } from 'react-redux';
import { basket, RootState } from '../../../../../../../store';
interface Props {
  bundleId: string;
}

/* I don't think this is used anymore? I dont know how to toggle it on in the CMS. */
const AdditionalComponents: React.FC<Props> = ({ bundleId }) => {
  const components = useSelector((state: RootState) => basket.selectors.getAdditionalComponentsForBundle(state, bundleId));
  if (components.length === 0) {
    return null;
  }
  const createComponent = (component: string, key: number) => React.createElement(component, { key: key });
  return <div>{components.map((component, key) => component && createComponent(component, key))}</div>;
};
export default AdditionalComponents;
