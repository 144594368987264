import React from 'react';
import { checkout, RootState } from '../../../store';
import ErrorLabel from '../error-label/ErrorLabel';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import './fields.sass';
import './text-field.sass';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  input: any;
  className: string;
  meta: { active: boolean; error: boolean; touched: boolean };
  disabled: boolean;
}

const TextField: React.FC<Props> = ({ id, label, input, meta, className, disabled, ...rest }: Props) => {
  const submitPressed = useSelector((state: RootState) => checkout.selectors.isSubmitFormPressed(state));
  const hasErrors = meta.error && (meta.touched || submitPressed);
  const fieldClasses = classNames('field text-field', className, {
    active: meta.active,
    focused: meta.touched || meta.active || input.value,
    'sub-body': meta.touched || meta.active,
    'has-errors': hasErrors,
  });
  return (
    <div className={fieldClasses}>
      <div className="label-wrapper">
        <label htmlFor={id} className={`label ${disabled ? 'label-disabled' : ''}`}>
          {label}
        </label>
      </div>
      <div className="input-wrapper">
        <input {...input} id={id} className="input body-text" required {...rest} disabled={disabled ?? false}/>
      </div>
      <ErrorLabel id={'error' + id} hasError={hasErrors} typography="metadata">
        {meta.error}
      </ErrorLabel>
    </div>
  );
};

export default TextField;
