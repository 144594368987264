import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import ErrorLabel from '../error-label/ErrorLabel';
import Icon from '../icons/Icon';
import './fields.sass';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  label?: string | JSX.Element;
  error?: string;
  onAction?: () => void;
  size?: number;
  value?: string;
}

const Checkbox: React.FC<Props> = ({ checked, label, onAction = () => {}, size = 2.5, id, error = '', value, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!!error) {
      ref?.current?.focus();
    }
  }, [error]);

  const mainClassName = classNames('field checkbox-field', { agreed: checked });
  const sizeStyle = { width: `${size}em`, height: `${size}em` };
  return (
    <>
      <div className={mainClassName}>
        <div className="checkbox-action">
          {/* Input is transparent, but overlaying the checkedIcon*/}
          <input
            id={id}
            {...rest}
            type="checkbox"
            value={checked ? value : ''}
            aria-invalid={!!error}
            aria-errormessage={'error-msg'}
            aria-describedby={'error-msg'}
            style={sizeStyle}
            ref={ref}
          />
          <div className="check-icon">
            {checked && <Icon type="checkboxChecked" size={size} />}
            {!checked && <Icon type="checkboxUnchecked" size={size} />}
          </div>
        </div>
        <div className="label-wrapper">
          <label htmlFor={id}>{label && label}</label>
        </div>
      </div>
      <ErrorLabel hasError={!!error} typography={'sub-body'} id="error-msg">
        {error}
      </ErrorLabel>
    </>
  );
};

export default Checkbox;
