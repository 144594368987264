import React from 'react';
import { customerVerification } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import vippsLogo from './icons/vipps_logo.svg?url';
import bankIdLogo from './icons/bankid_logo.svg?url';
import unchecked from '../../../shared/icons/images/radio-unchecked.svg?url';
import checked from '../../../shared/icons/images/radio-checked.svg?url';

import './verification.sass';

const VerificationProviderSelection: React.FC = () => {
  const selectedProvider = useSelector(customerVerification.selectors.GetSelectedVerificationProvider);
  const dispatch = useDispatch();
  const handleProviderChange = (e: any) => {
    const { value } = e.target;
    dispatch(customerVerification.creators.setSelectedVerificationProvider({ provider: value }));
  };

  const providerConfig = [
    {
      key: "Vipps",
      displayName: "Vipps",
      imgSrc: vippsLogo,
    },
    {
      key: "BankID",
      displayName: "BankID",
      imgSrc: bankIdLogo,
    }
  ]

  return (
    <div>
      {GetProvidersRadiobuttons(providerConfig)}
    </div>
  );

  function GetProvidersRadiobuttons(providersConfig: any) {
    return providersConfig.map((provider: any) => {
      const radioButtonId = provider.key + "RadioBtn";
      const isChecked = selectedProvider === provider.key;
      return (
        <div className='radio-verification-button' key={"key" + radioButtonId}>
          <input className='left' id={radioButtonId} type="radio" value={provider.key} name="verificationProvider" onChange={handleProviderChange} checked={isChecked} />
          <label className='click-area' htmlFor={radioButtonId}>
            <img className="radio-icon" src={isChecked ? checked : unchecked} />
            <span className="display-text">{provider.displayName}</span>
            <img className='provider-logo' src={provider.imgSrc} alt="" />
          </label>
        </div>
      );
    })
  }
};

export default VerificationProviderSelection;