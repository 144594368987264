import config from '../../../../../config';
import moment from 'moment';
import { FormValues } from '../../../../../store/checkout';
import { removeWhiteSpaces, removeComas, removePhoneCountryCode } from '../../../../../utils/format';

export interface AuthToken {
  access_token: string;
  id_token?: string;
}

export interface VerifiedUserData {  
  address?: {
    streetWithNumber: string,
    postalCode: string,
    city: string,
  };
  familiyName?: string;
  givenName?: string;  
  name?: string;
  phoneNumber?: string;
  birthdate?: string;
  email?: string;  
}

export const toFormValues = (verificationServiceResponse: VerifiedUserData): Partial<FormValues> => {
  const resp = verificationServiceResponse;
  return {
    street: removeComas(resp.address?.streetWithNumber || ''),
    zip: resp.address?.postalCode || '',
    city: resp.address?.city || '',    
    lastName: resp.familiyName?.trim() || '',
    firstName: resp.givenName?.trim() || '', //or resp.name
    //name: resp.name?.trim() || '',
    email: resp.email?.trim() || '',
    mobilePhone: removeWhiteSpaces(removePhoneCountryCode(resp.phoneNumber || "")),
    birthDate: resp.birthdate ? moment.utc(resp.birthdate, ['YYYY-MM-DD']).format('DDMMYY') : '',
  };
};

export const startCustomerVerification = async (providerName: string): Promise<string> => {
  return fetch(`${config.apiUrl}/customerverification/init?provider=${providerName}&callbackUrl=${location.origin}/callback`).then((response) => response.text());
};

export const getAuthToken = async (code: string, providerName: string): Promise<AuthToken> => {
  return fetch(`${config.apiUrl}/customerverification/getAuthToken?code=${code}&provider=${providerName}&callbackUrl=${location.origin}/callback`).then((response) => response.json());
};

export const getVerifiedUserData = async (accessToken: string, providerName: string): Promise<VerifiedUserData> => {
  return fetch(`${config.apiUrl}/customerverification/getVerifiedUserData?accessToken=${accessToken}&provider=${providerName}`).then((response) => response.json());
};

export const registerContactKSButtonClick = (): Promise<string> => {
  return fetch(`${config.apiUrl}/customerverification/registerContactKSButtonClick`).then((response) => response.text());
};