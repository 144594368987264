import { Notification } from './Notifications';
export interface Configs {
  receiver: ProductTypeConfig;
  premium: ProductTypeConfig;
  subscription: ProductTypeConfig;
  equipment: ProductTypeConfig;
  installation: ProductTypeConfig;
}

export interface ProductTypeConfig {
  title: string | null;
  description: any;
  bundleTitle: string | null;
  buttonLabel: string | null;
  productNotifications: Notification[];
}

export const defaultProductTypeConfig: ProductTypeConfig = {
  title: '',
  bundleTitle: '',
  buttonLabel: '',
  description: null,
  productNotifications: [
    {
      title: '',
      productConditionsAll: null,
      productConditionsAny: null,
    },
  ],
};
