export interface HatConfig {
  hatMode: HatMode;
  customHatText: string;
}

export enum HatMode {
  Default = 'default',
  Custom = 'custom',
  Off = 'off',
}
