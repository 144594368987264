import { Action, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AppState, INITIAL_STATE } from '../app';
import { creators } from './actions';

const onBundleOpen = (state: AppState, action: PayloadAction<string>) => {
  state.bundles[action.payload] = {
    ...state.bundles[action.payload],
    isOpen: true,
  };
};

const onBundleClose = (state: AppState, action: PayloadAction<string>) => {
  state.bundles[action.payload] = {
    ...state.bundles[action.payload],
    isOpen: false,
    shouldFocusFirstProduct: false,
  };
};

const onBundleShow = (state: AppState, action: PayloadAction<string>) => {
  state.bundles[action.payload] = {
    ...state.bundles[action.payload],
    isVisible: true,
  };
};

const onBundleHide = (state: AppState, action: PayloadAction<string>) => {
  state.bundles[action.payload] = {
    ...state.bundles[action.payload],
    isVisible: false,
    shouldFocusFirstProduct: false,
  };
};

const onOpenNextBundle = (state: AppState, action: PayloadAction<string>) => {
  state.bundles[action.payload] = {
    ...state.bundles[action.payload],
    isVisible: true,
    isOpen: true,
    shouldFocusFirstProduct: true,
  };
};

const onToggleSummary = (state: AppState, _: Action) => {
  state.isSummaryOpen = !state.isSummaryOpen;
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(creators.openBundle, (state, action) => onBundleOpen(state, action))
    .addCase(creators.closeBundle, (state, action) => onBundleClose(state, action))
    .addCase(creators.showBundle, (state, action) => onBundleShow(state, action))
    .addCase(creators.hideBundle, (state, action) => onBundleHide(state, action))
    .addCase(creators.openNextBundle, (state, action) => onOpenNextBundle(state, action))
    .addCase(creators.toggleSummary, (state, action) => onToggleSummary(state, action));
});
