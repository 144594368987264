import React from 'react';
import { Icon } from '../../../shared';
import './ErrorModal.sass';

const ErrorModal: React.FC = () => {
  return (
    <div className="error-modal">
      <div>
        <Icon type="alertTriangle" size={2} fill="#f2a900" />
      </div>

      <p>Hmm... Noe gikk galt. Prøv gjerne igjen om litt, eller kontakt oss så hjelper vi deg.</p>

      <div className="links">
        <Icon type="phone" size={1.5} />
        <a href="tel:21010210" className="link-style" target="_blank" rel="noopener noreferrer">
          Ring oss på 210 10 210
        </a>
      </div>
    </div>
  );
};

export default ErrorModal;
