import React from 'react';

import './basket-list.sass';

interface Props {
  title?: string;
}
const BasketList: React.FC<Props> = ({ children, title }) => (
  <>
    {title && <h3 className="basket-list-title button-or-bold">{title}</h3>}

    <ul className="basket-list">{children}</ul>
  </>
);

export default BasketList;
