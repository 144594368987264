import config from '../../config';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const fetchActiveChannels = async (commercialProductId: number) =>
  fetch(`${config.apiUrl}/channels/${commercialProductId}/active`, {
    method: 'GET',
    headers,
  }).then((response) => response.json());
