import { takeLatest, select } from 'redux-saga/effects';

import { getPartnerDetails, getTrackableCampaign } from '../store/campaign/selectors';
import { app, basket, campaign, checkout, customerVerification } from '../store';
import { PayloadAction } from '@reduxjs/toolkit';
import { Partner } from '../models/CampaignModel';

export enum TrackingPoints {
  CustomerFilter = 1,
  ProductComparison = 2,
  Content = 3,
  Equipment = 4,
  Verification = 5,
  ContactInformation = 6,
  Summary = 7,
}

enum EventTypes {
  Checkout = 'checkout',
  CampaignLoaded = 'campaignLoaded',
  AddToCart = 'addToCart',
  ExpandableBasketOpen = 'expandable-basket-open',
  ExpandableBasketClose = 'expandable-basket-close',
  Purchase = 'purchase',
  CustomerFilter = 'customer_filter',
}
interface StepChangeEvent {
  event: EventTypes.Checkout;
  checkout_step: number;
  verification_method?: 'vipps' | 'bankid';
}

interface CampaignLoadedEvent {
  event: EventTypes.CampaignLoaded;
  campaignCode: string;
  campaignVariant: string;
  products: any;
  partner: string;
}

interface AddProductEvent {
  event: EventTypes.AddToCart;
  ecommerce: {
    add: {
      products: any;
    };
  };
}

interface CustomerFilterEvent {
  event: EventTypes.CustomerFilter;
  step: number;
  distribution_model?: 'DTT' | 'IP';
  distribution_partner?: string;
  dwelling_unit?: 'SDU' | 'MDU';
}

interface ExpandableBasketEvent {
  event: EventTypes.ExpandableBasketOpen | EventTypes.ExpandableBasketClose;
  ecommerce: undefined;
}

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#make_a_purchase_or_issue_a_refund
 */
interface PurchaseEvent {
  event: EventTypes.Purchase;
  ecommerce: {
    currency: 'NOK';
    /** The unique identifier of a transaction. Avoids duplicate events */
    transaction_id: string;
    /** Monetary value of event */
    value: number;
    /** The coupon name/code associated with the event. */
    coupon?: string;
    /** Shipping cost associated with a transaction. */
    shipping?: number;
    /** Tax cost associated with a transaction. */
    tax?: number;
    /** Purchased items */
    items: Array<{
      item_id: string;
      item_name: string;
      price: number;
      /** A product affiliation to designate a supplying company or brick and mortar store location. */
      affiliation?: string;
    }>;
  };
}
type Event = StepChangeEvent | CampaignLoadedEvent | AddProductEvent | ExpandableBasketEvent | PurchaseEvent | CustomerFilterEvent;

const send = (event: Event) => window.dataLayer.push(event);

export const trackCustomerFilter = (
  step: number,
  distribution_model?: 'DTT' | 'IP',
  distribution_partner?: string,
  dwelling_unit?: 'SDU' | 'MDU'
) => {
  send({ event: EventTypes.CustomerFilter, step, distribution_model, distribution_partner, dwelling_unit });
};

// Campaign - view
function* trackCampaignView() {
  const trackableCampaign = yield select(getTrackableCampaign);
  const products = yield select(basket.selectors.getTrackingBasket);
  const partner: Partner = yield select(getPartnerDetails);

  const event: CampaignLoadedEvent = {
    event: EventTypes.CampaignLoaded,
    campaignCode: trackableCampaign.campaignCode,
    campaignVariant: trackableCampaign.campaignVariant,
    products: products,
    partner: partner.name,
  };

  send(event);
}

const trackProductsEvent = (products: any): AddProductEvent => {
  const event: AddProductEvent = {
    event: EventTypes.AddToCart,
    ecommerce: {
      add: {
        products: products,
      },
    },
  };
  return event;
};

export const trackSteps = (checkout_step: TrackingPoints, verification_method: StepChangeEvent['verification_method']) => {
  const event: StepChangeEvent = {
    event: EventTypes.Checkout,
    checkout_step,
  };
  // add to params only if provided
  if (verification_method) {
    event.verification_method = verification_method;
  }
  send(event);
};

function* trackStepChange(action: PayloadAction<TrackingPoints>) {
  if (action.payload === TrackingPoints.ContactInformation) {
    // Special case: track verification provider after callback
    const verificationProvider = yield select(customerVerification.selectors.GetSelectedVerificationProvider);
    trackSteps(action.payload, verificationProvider?.toLowerCase() || undefined);
  } else {
    trackSteps(action.payload, undefined);
  }
  // On navigation to 2nd and 3rd step, track producs
  if (action.payload === TrackingPoints.Equipment || action.payload === TrackingPoints.ContactInformation) {
    const products = yield select(basket.selectors.getTrackingBasket);
    if (products) {
      send(trackProductsEvent(products));
    }
  }
}

// Basket - toggle
function* trackSummaryToggle() {
  const isOpen = yield select(app.selectors.isSummaryOpen);
  const eventName = isOpen ? EventTypes.ExpandableBasketOpen : EventTypes.ExpandableBasketClose;

  const event: ExpandableBasketEvent = {
    event: eventName,
    ecommerce: undefined,
  };

  send(event);
}

// Checkout
function* trackOrder() {
  const receiptId: ReturnType<typeof checkout.selectors.getReceiptId> = yield select(checkout.selectors.getReceiptId);
  const campaignCode: ReturnType<typeof campaign.selectors.getCampaignCode> = yield select(campaign.selectors.getCampaignCode);
  const products: ReturnType<typeof basket.selectors.getTrackingBasket> = yield select(basket.selectors.getTrackingBasket);
  const summary: ReturnType<typeof basket.selectors.getBasketSummary> = yield select(basket.selectors.getBasketSummary);
  const partner: ReturnType<typeof getPartnerDetails> = yield select(getPartnerDetails);

  const event: PurchaseEvent = {
    event: EventTypes.Purchase,
    ecommerce: {
      transaction_id: receiptId!,
      currency: 'NOK',
      value: summary.costFirstYear,
      shipping: 0,
      tax: 0,
      coupon: campaignCode,
      items: products.map((product) => ({
        item_id: product.id.toString(),
        item_name: product.name,
        price: product.price,
        affiliation: partner?.name,
      })),
    },
  };

  send(event);
}

function* main() {
  yield takeLatest(basket.creators.create.type, trackCampaignView);
  yield takeLatest(campaign.creators.changeTrackingPoint.type, trackStepChange);
  yield takeLatest(app.creators.toggleSummary.type, trackSummaryToggle);
  yield takeLatest(checkout.creators.checkoutSucceeded.type, trackOrder);
}

export default main;
