import * as api from './api';
import * as selectors from './selectors';
import { types, creators } from './actions';
import { Campaign } from '../../models/CampaignModel';
import { TrackingPoints } from '../../sagas/trackingSaga';

export interface CampaignState {
  status: string;
  trackingPoint: null | TrackingPoints;
  campaign: Campaign | undefined;
  partnerAlias: string;
  dwellingUnit: string;
}

const INITIAL_STATE: CampaignState = {
  status: '',
  trackingPoint: null,
  campaign: undefined,
  partnerAlias: '',
  dwellingUnit: '',
};

export { api, selectors, types, creators, INITIAL_STATE };
