import { createAction } from '@reduxjs/toolkit';
import { BasketProduct } from '../../models/product/BasketProduct';
import { SelectionMode } from '../../models/SelectionModel';

const types = {
  CREATE: 'BASKET_CREATE',
  ADD_PRODUCT: 'BASKET_ADD_PRODUCT',
  REMOVE_PRODUCT: 'BASKET_REMOVE_PRODUCT',
  SET_IS_LATER_ACTIVATION: 'BASKET_SET_IS_LATER_ACTIVATION',
};

export interface AddProductPayload {
  selectionMode: SelectionMode;
  bundleId: string;
  products: BasketProduct[];
}

const creators = {
  create: createAction<BasketProduct[]>(types.CREATE),
  addProduct: createAction<AddProductPayload>(types.ADD_PRODUCT),
  removeProduct: createAction<BasketProduct[]>(types.REMOVE_PRODUCT),
  setIsLaterActivation: createAction<boolean>(types.SET_IS_LATER_ACTIVATION),
};

export { types, creators };
