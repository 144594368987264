import React, { SVGProps } from 'react';
import classNames from 'classnames';
import { IconList, Icons } from './images';
import './Icon.sass';

/**
 * - It is imporant that you set height/width attributes to 100% in the SVG
 *    - Set fill in all the paths inside to inherit
 */
interface Props extends SVGProps<SVGSVGElement> {
  type: Icons;
  size?: number;
  flip?: 'horizontal' | 'vertical' | 'both';
  rotation?: number;
}

const Icon: React.FC<Props> = ({ type, size = '1', flip, className, rotation = 0, fill = '#333333', ...rest }) => {
  const Svg = IconList[type];
  const iconClass = classNames('icon', flip, className);
  return (
    <div className={iconClass} style={{ height: `${size}em`, width: `${size}em` }}>
      <Svg style={{ transform: `rotate(${rotation}deg)` }} fill={fill} {...rest}></Svg>
    </div>
  );
};

/* You can use this one to preview all the icons */
export const PreviewIcons = () => {
  const Icons = Object.keys(IconList).map((key) => key as Icons);
  return (
    <>
      {Icons.map((key) => (
        <div>
          <Icon type={key} />
          {key}
        </div>
      ))}
    </>
  );
};
export default Icon;
