import combinate from 'combinate';
import { createSelector } from 'reselect';
import { RootState, campaign } from '..';
import { ProductBundle, SelectableItem } from '../../models/CampaignModel';
import { CustomerType } from '../../models/CustomerType';
import { OrderVariant } from '../../models/OrderVariant';
import { IProductPrice } from '../../models/product/ProductPrice';
import { ProductType } from '../../models/product/ProductType';
import { SelectionMode } from '../../models/SelectionModel';
import { noUndefined } from '../../utils/arrays';
import { getCustomerType, ProductWithBundleId } from '../campaign/selectors';

export const getBasketProducts = (state: RootState) => state.basket.products;

export const getIsDelayedActivation = (state: RootState) => state.basket.isDelayedActivation;

export const getBasketProductsWithData = createSelector([campaign.selectors.getFlatProductList, getBasketProducts], (products, selected) =>
  selected.map((item) => products.find((product) => product.id === item.productId)).filter(noUndefined)
);

export const getBasketCompositeIds = createSelector([getBasketProductsWithData], (products) =>
  products.map((product) => product?.offer?.compositeId)
);

// Hardcoded installation commercial product ID
export const isInstallation = (compositeId: string | undefined) => compositeId?.split('-')[0] === '97';

export const hasOrderedInstallation = (state: RootState) =>
  getBasketCompositeIds(state).length > 0 && getBasketCompositeIds(state).find((compositeId) => isInstallation(compositeId));

export const hasOrderedReceiver = createSelector(
  [getBasketProductsWithData],
  (products) => products.filter((product) => product?.productType === ProductType.Receiver).length > 0
);

export const getBasketProductsWithPrices = createSelector([getBasketProductsWithData], (products) => products);

export const isProductSelected = (state: RootState, id: string) => getBasketProducts(state).find((product) => product.productId === id);

export const getSelectedFromBundle = (state: RootState, bundleId: string) =>
  getBasketProducts(state).filter((product) => product.bundleId === bundleId);

export const getSelectedFromBundleWithData = (state: RootState, bundleId: string) => {
  const ids = getSelectedFromBundle(state, bundleId).map((x) => x.productId);
  return campaign.selectors.getFlatProductList(state).filter((x) => x.bundleId === bundleId && ids.includes(x.id));
};

export const getSelectedCommercialProductsFromBundle = (state: RootState, bundleId: string) =>
  getSelectedFromBundleWithData(state, bundleId)
    .map((product) => product?.offer.commercialProductId)
    .filter((v, i, a) => a.indexOf(v) === i);

export const hasSelectedInBundle = (state: RootState, bundleId: string) => getSelectedFromBundle(state, bundleId).length > 0;

export const getSelectedBundleInBasketWithCpid = (state: RootState, iccCommercialProductId: number) => {
  return getBasketProductsWithData(state).find(basketProduct => basketProduct.offer.commercialProductId == iccCommercialProductId);
}

export const getProductsToHandle = (state: RootState, bundle: ProductBundle, product: SelectableItem) => {
  if (bundle.selectionConfig.selectionMode === SelectionMode.BestDiscount) {
    return getBestDiscountCombination(state, bundle, product);
  }
  return [
    {
      bundleId: bundle.id,
      productId: product.id,
    },
  ];
};

interface OfferCombination {
  productId: string;
  bundleId: string;
  savings: number;
}
export const getBestDiscountCombination = (state: RootState, bundle: ProductBundle, product: SelectableItem) => {
  let productIds = getSelectedCommercialProductsFromBundle(state, bundle.id);

  if (productIds.includes(product.offer.commercialProductId)) {
    productIds = productIds.filter((id) => id !== product.offer.commercialProductId); // Remove product
  } else {
    productIds.push(product.offer.commercialProductId); // Add product
  }

  let availableOffers: { [commercialId: string]: OfferCombination[] } = {};

  productIds.forEach((commercialProductId) => {
    availableOffers[`${commercialProductId}`] = campaign.selectors
      .getAvailableOffersForCommercialProductId(state, commercialProductId)
      .map((product: SelectableItem) => ({
        productId: product.id,
        bundleId: bundle.id,
        savings: originalCostFirstYear(product.pricing) - calculateCostFirstYear(product.pricing),
      }));
  });

  // Get possible combinations for the offers
  const combinations = combinate(availableOffers) || [];
  if (combinations.length === 0) {
    return [];
  }

  // Get a summary of the different combinatins
  const comboSummary = combinations
    .map((combination) => ({
      products: Object.keys(combination).map((id) => ({ bundleId: combination[id].bundleId, productId: combination[id].productId })),
      totalSavings: Object.keys(combination).reduce((acc, id) => acc + combination[id].savings, 0),
      discountedItems: Object.keys(combination).filter((id) => combination[id].savings > 0).length,
    }))
    .filter((x) => x.discountedItems <= bundle.selectionConfig.maxDiscountedItems); // Remove combinations with too many discounted items

  comboSummary.sort((a, b) => b.totalSavings - a.totalSavings);
  return comboSummary[0].products; // Return the best discount
};

const originalCostFirstYear = (price: IProductPrice): number => {
  if (!price.isRecurring) {
    return price.originalPrice;
  }
  return price.originalPrice * 12;
};

const calculateCostFirstYear = (price: IProductPrice): number => {
  if (!price.isRecurring) {
    return price.price;
  }
  if (!price.offerPeriodInMonths && price.price === price.originalPrice) {
    return price.originalPrice * 12;
  }
  if (price.offerPeriodInMonths) {
    const monthsLeft = 12 - price.offerPeriodInMonths;
    return price.price * price.offerPeriodInMonths + price.originalPrice * monthsLeft;
  }
  return price.price * 12;
};

const calculateCostIntervals = (price: IProductPrice, offerPeriodInMonths: number): number => {
  if (!price.isRecurring) {
    return price.price;
  }
  if (!price.offerPeriodInMonths && price.price === price.originalPrice) {
    return price.price;
  }
  if (price.offerPeriodInMonths) {
    if (price.offerPeriodInMonths > offerPeriodInMonths) {
      return price.price;
    }
    return price.originalPrice;
  }
  return price.price;
};

const getOfferPeriods = (selectedProducts: ProductWithBundleId[]) => {
  const offerPeriods = selectedProducts
    .map((product) => product.pricing.offerPeriodInMonths)
    .filter(function (e) {
      return e;
    });
  offerPeriods.sort((n1, n2) => n1! - n2!);

  return offerPeriods;
};

export const getOfferPeriodsInMonthsSorted = createSelector([getBasketProductsWithData], getOfferPeriods);

const getSummary = (products: ProductWithBundleId[]) => {
  const recurring = products.filter((product) => product.pricing.isRecurring);
  const nonRecurring = products.filter((product) => !product.pricing.isRecurring);
  const offerPeriods = getOfferPeriods(products);

  const monthly = recurring.reduce((a, b) => a + b.pricing.price, 0);
  const costIntervals = [monthly];
  const costFirstYear = products.reduce((a, b) => a + calculateCostFirstYear(b.pricing), 0);
  const costNonRecurring = nonRecurring.reduce((n, price) => n + price.pricing.price, 0);

  offerPeriods.forEach((offerPeriodInMonths) =>
    costIntervals.push(recurring.reduce((a, b) => a + calculateCostIntervals(b.pricing, offerPeriodInMonths!), 0))
  );

  return {
    monthly,
    costIntervals,
    costFirstYear,
    costNonRecurring,
  };
};

export const getBasketSummary = createSelector([getBasketProductsWithData], getSummary);

export const getTrackingBasket = createSelector([getBasketProductsWithData, getCustomerType], (products, getCustomerType) =>
  products.map((product) => ({
    id: product.offer.commercialProductId,
    name: product.displayData.name,
    price: calculateCostFirstYear(product.pricing),
    category: product.productType,
    quantity: 1,
    variant: getTrackingProductVariant(product.productType, getCustomerType),
  }))
);

const getTrackingProductVariant = (category: ProductType, getCustomerType: CustomerType) => {
  if (category === ProductType.Subscription) {
    switch (getCustomerType) {
      case CustomerType.Normal:
        return OrderVariant.Normal;
      case CustomerType.IpNormal:
        return OrderVariant.IpNormal;
      case CustomerType.IpNormalBRL:
        return OrderVariant.IpNormalBrl;
      default:
        return 'Unknown';
    }
  } else {
    return '';
  }
};

export const getAdditionalComponentsForBundle = (state: RootState, bundleId: string) =>
  getSelectedFromBundleWithData(state, bundleId).map((product) => product.displayData.additionalComponent);

export const getProductsFromCategory = (state: RootState, category: ProductType) =>
  getBasketProductsWithPrices(state)
      .filter((x) => x.productType === category)

  // Premiums
export const hasPremiums = (state: RootState) =>
  getBasketProductsWithPrices(state).filter((product) => product.productType === ProductType.Premium).length > 0;

