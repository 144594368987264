import React from 'react';
import classnames from 'classnames';
import { formatAmount, formatRecurringAmount } from '../../../../../../utils/format';
import { IProductPrice } from '../../../../../../models/product/ProductPrice';

const formatPrice = (price: number, offerAppliesToNumberOfMonths: number | undefined) => {
  if (offerAppliesToNumberOfMonths) {
    return `${price || 0} kr i ${offerAppliesToNumberOfMonths} måneder`;
  }
  return `${price || 0} kr`;
};

interface Props {
  receiver: boolean;
  pricing: IProductPrice;
  optionalPriceTitle?: string;
}

const ProductPrice: React.FC<Props> = ({ pricing, optionalPriceTitle }) => {
  if (!pricing) {
    return null;
  }
  const { price, originalPrice, isRecurring, offerPeriodInMonths } = pricing;
  const isReduced = originalPrice > 0 && originalPrice > price;
  const hasDuration = isRecurring && isReduced && !!offerPeriodInMonths;
  const productPriceClass = classnames('product-price', { 'once-off': !isRecurring });
  const priceClass = classnames('button-or-bold price', { reduced: isReduced });

  let formattedCurrentPrice = '';

  if (!hasDuration && isRecurring) {
    formattedCurrentPrice = `${formatRecurringAmount(price)}`;
  } else if (hasDuration) {
    formattedCurrentPrice = `${formatPrice(price, offerPeriodInMonths)}`;
  } else {
    formattedCurrentPrice = `${formatAmount(price)}`;
  }

  const formattedOriginalPrice = isRecurring ? `Originalpris ${formatRecurringAmount(originalPrice)}` : `${formatAmount(originalPrice)}`;

  return (
    <div className={productPriceClass}>
      <div className={priceClass} aria-label={`Du betaler: ${optionalPriceTitle || formattedCurrentPrice}`}>
        {optionalPriceTitle || formattedCurrentPrice}
      </div>
      {isReduced && (
        <div
          className="price metadata"
          aria-label={isRecurring ? `Originalpris ${formattedOriginalPrice}` : `Tidligere pris: ${formattedOriginalPrice}`}>
          {formattedOriginalPrice}
        </div>
      )}
    </div>
  );
};

export default ProductPrice;
