import informationIcon from './information-icon.svg?url';
import InformationBox from '../../../../shared/information-box/InformationBox';
import { useSelector } from 'react-redux';
import { customerVerification } from '../../../../../store';

const UserDataInformation = () => {
  const providerName = useSelector(customerVerification.selectors.GetSelectedVerificationProvider);
  const title = `Kontakinformasjonen er hentet fra ${providerName}`;
  const infoText = `Vi har mottatt kontaktinformasjonen din fra ${providerName}. Ta kontakt med ${providerName} sin kundeservice dersom opplysningene ikke stemmer.`;
  return (
    <>
    <InformationBox
          icon={informationIcon}
          color='blue'
          title={title}
          infoText={infoText}
        />
    </>
  );
};

export default UserDataInformation;