import { all } from 'redux-saga/effects';

import campaignSaga from './sagas/campaignSaga';
import channelsSaga from './sagas/channelsSaga';
import basketSaga from './sagas/basketSaga';
import trackingSaga from './sagas/trackingSaga';

export default function* rootSaga() {
  yield all([campaignSaga(), channelsSaga(), basketSaga(), trackingSaga()]);
}
