export interface ProductConditionSet {
  productConditionsAny: ProductCondition[] | null;
  productConditionsAll: ProductCondition[] | null;
}

export interface Notification extends ProductConditionSet {
  title: string;
}

export interface ProductCondition {
  conditionType: ConditionType;
  quantity: number;
  commercialProductId: number;
}

export enum ConditionType {
  GreaterOrEqual = 'GE',
  LessOrEqual = 'LE',
  Equal = 'EQ',
}
