import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormSpy } from 'react-final-form';
import { checkout } from '../../../../../../store';

const FormValuesToRedux = ({ updateFormValues }) => (
  <FormSpy
    subscription={{ values: true }}
    onChange={(state) => {
      updateFormValues(state);
    }}
  />
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFormValues: checkout.creators.updateFormValues,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FormValuesToRedux);
