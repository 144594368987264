import React from 'react';

const usePersistedState = <T>(key: string, initialState: T) => {
  const initializeStorage = () => {
    const item = localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : initialState;
  };

  const [state, setState] = React.useState<T>(initializeStorage());

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return { state, setState };
};

export default usePersistedState;
