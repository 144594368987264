import { ReactComponent as AlertCircleBold } from './alert-circle-bold.svg';
import { ReactComponent as AlertCircle } from './alert-circle.svg';
import { ReactComponent as AlertTriangleBold } from './alert-triangle-bold.svg';
import { ReactComponent as AlertTriangle } from './alert-triangle.svg';
import { ReactComponent as Antenna } from './antenna.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as Box } from './box.svg';
import { ReactComponent as Cabin } from './cabin.svg';
import { ReactComponent as Campingwagon } from './campingwagon.svg';
import { ReactComponent as CartLines } from './cart-lines.svg';
import { ReactComponent as Cart } from './cart.svg';
import { ReactComponent as CheckCircleBold } from './check-circle-bold.svg';
import { ReactComponent as CheckCircle } from './check-circle.svg';
import { ReactComponent as CheckboxChecked } from './checkbox-checked.svg';
import { ReactComponent as CheckboxIndeterminate } from './checkbox-indeterminate.svg';
import { ReactComponent as CheckboxUnchecked } from './checkbox-unchecked.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as Chevron } from './chevron.svg';
import { ReactComponent as CloseCircle } from './close-circle.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as EthernetCable } from './ethernet-cable.svg';
import { ReactComponent as FormCheck } from './form-check.svg';
import { ReactComponent as Form } from './form.svg';
import { ReactComponent as Gift } from './gift.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as InformationCircleBold } from './information-circle-bold.svg';
import { ReactComponent as InformationCircle } from './information-circle.svg';
import { ReactComponent as MailEmail } from './mail-email.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Message } from './message.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as QuestionCircleBold } from './question-circle-bold.svg';
import { ReactComponent as QuestionCircle } from './question-circle.svg';
import { ReactComponent as RadioChecked } from './radio-checked.svg';
import { ReactComponent as RadioUnchecked } from './radio-unchecked.svg';
import { ReactComponent as SearchCircle } from './search-circle.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as StreamingBox } from './streaming-box.svg';
import { ReactComponent as TelevisionWithCog } from './television-with-cog.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Wrench } from './wrench.svg';
export type Icons =
  | 'alertCircleBold'
  | 'alertCircle'
  | 'alertTriangle'
  | 'alertTriangleBold'
  | 'antenna'
  | 'arrow'
  | 'box'
  | 'cabin'
  | 'campingwagon'
  | 'cartLines'
  | 'cart'
  | 'checkCircle'
  | 'checkCircleBold'
  | 'checkboxChecked'
  | 'checkboxIndeterminate'
  | 'checkboxUnchecked'
  | 'checkmark'
  | 'chevron'
  | 'closeCircle'
  | 'close'
  | 'email'
  | 'ethernetCable'
  | 'formCheck'
  | 'form'
  | 'gift'
  | 'house'
  | 'informationCircle'
  | 'informationCircleBold'
  | 'mailEmail'
  | 'menu'
  | 'message'
  | 'plus'
  | 'phone'
  | 'questionCircle'
  | 'questionCircleBold'
  | 'radioChecked'
  | 'radioUnchecked'
  | 'searchCircle'
  | 'search'
  | 'streamingBox'
  | 'televisionWithCog'
  | 'user'
  | 'wrench';

export const IconList: Record<Icons, React.FC<React.SVGProps<SVGSVGElement>>> = {
  alertCircleBold: AlertCircleBold,
  alertCircle: AlertCircle,
  alertTriangle: AlertTriangle,
  alertTriangleBold: AlertTriangleBold,
  antenna: Antenna,
  arrow: Arrow,
  box: Box,
  cabin: Cabin,
  campingwagon: Campingwagon,
  cartLines: CartLines,
  cart: Cart,
  checkCircle: CheckCircle,
  checkCircleBold: CheckCircleBold,
  checkboxChecked: CheckboxChecked,
  checkboxIndeterminate: CheckboxIndeterminate,
  checkboxUnchecked: CheckboxUnchecked,
  checkmark: Checkmark,
  chevron: Chevron,
  closeCircle: CloseCircle,
  close: Close,
  email: Email,
  ethernetCable: EthernetCable,
  formCheck: FormCheck,
  form: Form,
  gift: Gift,
  house: House,
  informationCircle: InformationCircle,
  informationCircleBold: InformationCircleBold,
  mailEmail: MailEmail,
  menu: Menu,
  message: Message,
  plus: Plus,
  phone: Phone,
  questionCircle: QuestionCircle,
  questionCircleBold: QuestionCircleBold,
  radioChecked: RadioChecked,
  radioUnchecked: RadioUnchecked,
  searchCircle: SearchCircle,
  search: Search,
  streamingBox: StreamingBox,
  televisionWithCog: TelevisionWithCog,
  user: User,
  wrench: Wrench,
};
