import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { campaign, channels, RootState } from '../../../../../store';
import { Collapsible, Loader } from '../../../../shared';
import ChannelCategory from './ChannelCategory';

const ChannelsContainer: React.FC = () => {
  const {
    mainSubscriptionName,
    channelListWithCategories,
    radioChannelList,
    isLoading,
    isLoaded,
    isFailed,
  } = useSelector((state: RootState) => mapStateToProps(state));

  const expandedOnStart = window.innerWidth >= 768;
  const [expanded, setExpanded] = useState(expandedOnStart);
  const [expandedCategory, setExpandedCategory] = useState<number | null>(null);
  const header = <h3 className="channels-header">Kanaler i {mainSubscriptionName}</h3>;
  const radioId = channelListWithCategories.length;

  const toggleChannelCategory = (categoryId: number) =>
    categoryId === expandedCategory ? setExpandedCategory(null) : setExpandedCategory(categoryId);

  if (isLoading) return <Loader />;
  if (isFailed) return <div>Kunne ikke laste inn kanallisten</div>;
  if (isLoaded) {
    return (
      <Collapsible header={header} toggleExpanded={() => setExpanded(!expanded)} isOpen={expanded}>
        {channelListWithCategories.map((category, key) => (
          <ChannelCategory
            key={key}
            title={category.title}
            channelList={category.channelList}
            toggleChannelCategory={() => toggleChannelCategory(key)}
            isOpen={key === expandedCategory}
          />
        ))}
        <ChannelCategory
          channelList={radioChannelList}
          title="Radio"
          isRadioChannels
          toggleChannelCategory={() => toggleChannelCategory(radioId)}
          isOpen={radioId === expandedCategory}
        />
      </Collapsible>
    );
  }
  return null;
};

const mapStateToProps = (state: RootState) => ({
  mainSubscriptionName: campaign.selectors.getMainSubscriptionName(state),
  isLoaded: channels.selectors.isLoaded(state),
  isLoading: channels.selectors.isLoading(state),
  isFailed: channels.selectors.isFailed(state),
  radioChannelList: channels.selectors.getRadioChannelList(state),
  channelListWithCategories: channels.selectors.getChannelListWithCategories(state),
});

export default ChannelsContainer;
