import { takeLatest, put, select } from 'redux-saga/effects';
import { getStartingBasket } from '../store/campaign/selectors';
import { creators as basketCreators } from '../store/basket/actions';
import { types as campaignTypes } from '../store/campaign/actions';

function* createBasket() {
  const startingBasket = yield select(getStartingBasket);
  yield put(basketCreators.create(startingBasket));
}

function* main() {
  yield takeLatest(campaignTypes.FETCH_SUCCEEDED, createBasket);
}

export default main;
