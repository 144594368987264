import createSagaMiddleware from 'redux-saga';
import { reducers } from '.';
import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import rootSaga from '../root.saga';
import { persistStore, persistReducer  } from 'redux-persist';
import thunk from 'redux-thunk';
import storageSession from 'redux-persist/lib/storage/session';

const sagaMiddleware = createSagaMiddleware();

const customizedMiddleware = getDefaultMiddleware({ serializableCheck: false, immutableCheck: false });

const middlewares = customizedMiddleware.concat(sagaMiddleware).concat(thunk);

const reducersPersistConfig = {
  key: 'rootPersist',
  storage: storageSession,  
  whitelist: ['basket', 'customerVerification', 'campaign', 'app']
}

const combinedReducer = combineReducers(reducers);
const persistedReducer = persistReducer(reducersPersistConfig, combinedReducer);

// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./index', () => store.replaceReducer(combineReducers(reducers)));
// }

export const removePersistedStore = () => {
  //persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });  
  sessionStorage.clear();
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
