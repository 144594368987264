import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { basket, RootState } from '../../../../../store';
import useModal from '../../../../modal';
import { Button, Checkbox } from '../../../../shared';

import './delayed-activation.sass';

export const DelayedActivation: React.FC = () => {
  const isDelayedActivation = useSelector((state: RootState) => basket.selectors.getIsDelayedActivation(state));
  const [isChecked, setIsChecked] = useState(isDelayedActivation);
  const dispatch = useDispatch();

  const { open } = useModal();

  const handleToggleCheck = () => {
    setIsChecked(!isChecked);
    dispatch(basket.creators.setIsLaterActivation(!isChecked));
  };

  const checkboxLabel = (
    <div aria-label="Jeg ønsker å vente med å aktivere TV-pakken" onClick={handleToggleCheck} onKeyDown={handleToggleCheck}>
      Jeg ønsker å vente med å aktivere TV-pakken
    </div>
  );

  return (
    <div className="activation-container">
      <h1 className="title-3 bottom-margin-medium">Ønsker du en senere aktivering av TV-pakken?</h1>
      <p className="body bottom-margin-small">
        Det kan for eksempel være fordi du venter på at fiber skal installeres, eller ditt nåværende TV-abonnement skal avsluttes.{' '}
        <Button type="link-style" onClick={() => open(<InfoModal />)} aria-label="Les mer om aktivering i modal">
          Les mer om aktivering
        </Button>
      </p>
      <div className="checkbox">
        <Checkbox checked={isChecked} label={checkboxLabel} onChange={handleToggleCheck} size={2} />
      </div>
    </div>
  );
};

const InfoModal: React.FC = () => {
  return (
    <>
      <h1 className="title-3 bottom-margin-medium">Senere aktivering av TV-pakken din</h1>
      <p className="body">
        Dersom du ønsker en senere aktivering av TV-pakken, kan du oppgi dette. TV-pakken aktiverer du enkelt selv når du er klar til å
        begynne å se TV med RiksTV. Abonnementskostnaden begynner ikke å løpe før du har aktivert TV-pakken.
      </p>
    </>
  );
};
