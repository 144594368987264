import React, { useEffect, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';
import './error-label.sass';

interface Props {
  id?: string;
  hasError: boolean;
  typography?: 'body-text' | 'sub-body' | 'metadata';
  focusOnError?: boolean;
}
const ErrorLabel: React.FC<Props> = ({ id = undefined, hasError, children, typography = 'body-text', focusOnError = false }) => {
  const ref = useRef<HTMLDivElement>(null);

  const errorAnimateOptions = {
    duration: 100,
    animateOpacity: true,
    height: hasError ? 'auto' : 0,
    'aria-hidden': children ? false : true,
  };
  const classes = classnames('error-label', typography);

  useEffect(() => {
    if (focusOnError && hasError) {
      ref?.current?.focus();
    }
  }, [hasError, focusOnError]);

  return (
    <AnimateHeight {...errorAnimateOptions}>
      <div ref={ref} className={classes} id={id} tabIndex={-1}>
        {children}
      </div>
    </AnimateHeight>
  );
};

export default ErrorLabel;
