import { Action, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CustomerVerificaitonState,SetSelectedVerificationProvider, INITIAL_STATE } from '.';
import { creators } from './actions';

const onStartVerificationRequestSucceeded = (state: CustomerVerificaitonState, action: PayloadAction<CustomerVerificaitonState>) => {
  state.isVerified = action.payload.isVerified;
  state.verificationDetails = action.payload.verificationDetails;
};

const onResetVerificationData = (state: CustomerVerificaitonState, _: Action) => {
  state = INITIAL_STATE;
};

const onSelecingVerificationProvider = (state: CustomerVerificaitonState, action: PayloadAction<SetSelectedVerificationProvider>) => {
  state.verificationDetails.provider = action.payload.provider;
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(creators.setSelectedVerificationProvider, (state, action) => onSelecingVerificationProvider(state, action))
    .addCase(creators.customerVerificationSucceeded, (state, action) => onStartVerificationRequestSucceeded(state, action))    
    .addCase(creators.resetCutomerVerificationData, (state, action) => onResetVerificationData(state, action));
});
