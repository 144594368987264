import React, { useState, useCallback } from 'react';
import FocusTrap from 'focus-trap-react';
import { Icon } from '../shared';

import './modal.sass';

const ModalContext = React.createContext<IModalContext | null>(null);

const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === null) {
    throw Error('You must use this within the boundaries of the ModalProvider');
  }
  return context;
};

interface IModalContext {
  open: (modalComponent: React.ReactNode) => void;
  close: () => void;
}

export const Modal: React.FC = ({ children }) => {
  const [modalComponent, setModalComponent] = useState<React.ReactNode>(null);

  const open = useCallback((modalComponent: React.ReactNode) => setModalComponent(modalComponent), []);

  const close = () => {
    setModalComponent(null);
  };

  const CloseButton = () => {
    return (
      <button className="close-btn" onClick={close} aria-label="Lukk dialogrute">
        <Icon type="close" size={2.5} />
      </button>
    );
  };

  return (
    <ModalContext.Provider value={{ open, close }}>
      {!!modalComponent && (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: true, clickOutsideDeactivates: true, onDeactivate: () => close() }}>
          <div className="modal-box" role="dialog" aria-modal="true">
            <CloseButton />
            {modalComponent}
          </div>
        </FocusTrap>
      )}
      {!!modalComponent && <div className="backdrop" onClick={() => close()} />}
      {children}
    </ModalContext.Provider>
  );
};

export default useModal;
