import { useEffect, useState } from 'react';

export function useMediaQuery(query: string, onNotSupporter = false) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    try {
      const changeEvent = () => {
        setMatches(media.matches);
      };
      media.addEventListener('change', changeEvent);
      setMatches(media.matches);
      return () => media.removeEventListener('change', changeEvent);
    } catch {
      setMatches(onNotSupporter);
    }
  }, [query, onNotSupporter]);
  return matches;
}

/**
 * Default to false/desktop view if hook functionality (addEventListener) is not supported by the browser
 */
export const useIsMobileView = () => useMediaQuery('(max-width: 720px)');
