import { FeatureIndicatorType } from '../../../models/subscription-comparison/FeatureIndicatorType';

export const title = 'Velg TV-pakken som passer deg';

export const comparableSubscriptionsStaticData = [
  {
    // Rikspakken
    subscriptionIccProductId: 6,
    description: 'Passer for deg som ønsker et stort utvalg av film, serier og sport.',
    featureIndicators: [
      {
        text: '61',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '4',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: 'Strømmeboks',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
    ],
  },
  {
    // SportsPakken
    subscriptionIccProductId: 299,
    description: 'Passer for deg som elsker og vil se mye sport, men som også setter pris på et stort utvalg av TV-kanaler.',
    featureIndicators: [
      {
        text: '58',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '4',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: 'Strømmeboks',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
    ],
  },
  {
    // FleksiPakken
    subscriptionIccProductId: 316,
    description: 'Passer for deg som vil ha en rimelig og fleksibel TV-pakke med de mest populære kanalene.',
    featureIndicators: [ 
      {
        text: '16+6',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '1',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: 'Strømmeboks',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: 'Valgfri til 0,-',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: 'Valgfri til 0,-',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
    ],
  },
  {
    // StrommePakken
    subscriptionIccProductId: 315,
    description:
      'Passer for deg som elsker å boltre deg i et stort utvalg av film og serier, og som også setter pris på et godt utvalg av TV-kanaler.',
    featureIndicators: [
      {
        text: '50',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '4',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: 'Strømmeboks',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: 'Valgfri til 0,-',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: 'Valgfri til 0,-',
        type: FeatureIndicatorType.StrongText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: 'Ikke tilgjengelig',
        type: FeatureIndicatorType.WeakText,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
      {
        text: '',
        type: FeatureIndicatorType.Check,
      },
    ],
  },
];

export const comparisonCategories = [
  {
    name: 'Antall kanaler',
    icon: 'channels',
    displayOnMobile: true,
  },
  {
    name: 'Antall rom / steder',
    icon: 'rooms',
    displayOnMobile: true,
  },
  {
    name: 'TV-boks',
    icon: 'apple-tv',
    displayOnMobile: true,
  },
  {
    name: 'TV 2 Play Basis',
    icon: 'tv2-sport',
    displayOnMobile: false,
  },
  {
    name: 'TV 2 Sport 1 og 2 **',
    icon: 'tv2-sport',
    displayOnMobile: false,
  },
  {
    name: 'Max Basis med reklame',
    icon: 'max',
    displayOnMobile: false,
  },
  {
    name: 'Eurosport **',
    icon: 'eurosport',
    displayOnMobile: false,
  },
  {
    name: 'Nordisk Film+',
    icon: 'nordisk-film-plus',
    displayOnMobile: false,
  },
  {
    name: 'SkyShowtime',
    icon: 'skyshowtime',
    displayOnMobile: false,
  },
  {
    name: 'Film Favoritter',
    icon: 'film-favoritter',
    displayOnMobile: false,
  },
  {
    name: 'V Sport',
    icon: 'viasport',
    displayOnMobile: false,
  },
  {
    name: 'TV via app / nettleser',
    icon: 'web-tv',
    displayOnMobile: true,
  },
  {
    name: 'Gratis hjemlevering',
    icon: 'shipping',
    displayOnMobile: false,
  },
];
