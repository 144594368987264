import config from '../../../config';
import { SanityGenericSubscription, SanityPartnerConfiguration } from '../../../models/customer-filter/CustomerFilter';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getGenericSubscriptions = async (): Promise<SanityGenericSubscription[]> => {
  return fetch(`${config.apiUrl}/campaign/genericsubscriptions/`, {
    method: 'GET',
    headers,
  }).then((response) => response.json());
};

export const getPartnerConfigurations = async (): Promise<SanityPartnerConfiguration[]> => {
  return fetch(`${config.apiUrl}/partner/customerfilter/`, {
    method: 'GET',
    headers,
  }).then((response) => response.json());
};
