import { useEffect } from 'react';

const useEventListener = (event: string, callback: (event: any) => any, condition?: boolean) => {
  useEffect(() => {
    if (condition !== undefined) {
      if (condition) window.addEventListener(event, callback);
      else {
        window.removeEventListener(event, callback);
      }
    } else {
      window.addEventListener(event, callback);

      return () => {
        window.removeEventListener(event, callback);
      };
    }
  }, [condition, event, callback]);
};

export default useEventListener;

/*
  When using this, cache the result of your callbacks function call (memoization - look it up) using useCallBack.
  An example using an event handler and a condition:

  const someCallbackFunction = useCallback(
    (e) => {
      if (someCheck) {
        e.preventDefault();   // if necessary
        someCodetoExecute();
      }
    },
    [someCodetoExecute]
  );

  NB: if you want to reference props or state inside your callback, add these to the dependency array at the bottom.

  https://medium.com/@KTAsim/react-performance-event-handlers-using-usecallback-hook-9e4a06f8bb2f
*/
