/**
 * FlowModes are used to determine
 * how bundles should be presented upon loading.
 * - **Open**: All bundles are open
 * - **Progressive**: A button is available to open the next bundle after a selection has been done
 */
export enum FlowMode {
  Open = 'open',
  Progressive = 'progressive',
}
