import React from 'react';
import { useSelector } from 'react-redux';
import { campaign, RootState } from '../../../../store';
import SubscriptionCard from './subscription-card/SubscriptionCard';
import ProductGroup from '../components/product-group/ProductGroup';
import { DelayedActivation } from '../components/later-activation/DelayedActivation';
import { Layout } from '../../../shared';
import { FlowMode } from '../../../../models/FlowMode';
import { ProductType } from '../../../../models/product/ProductType';

import './campaign-content.sass';

const CampaignContent: React.FC = () => {
  const selectableProducts = useSelector((state: RootState) => campaign.selectors.getSelectableProducts(state));
  const mainSubscriptionName = useSelector((state: RootState) => campaign.selectors.getMainSubscriptionName(state));

  const isFiberOrder = useSelector((state: RootState) => campaign.selectors.isFiberOrder(state));

  return (
    <Layout
      focusOnRender={true}
      wide={true}
      aria-label={`Bestilling av ${mainSubscriptionName}, Steg 1 av 5 - Abonnement og tilleggspakker`}>
      <SubscriptionCard />
      {selectableProducts && <ProductGroup group={selectableProducts.premium} flowMode={FlowMode.Open} type={ProductType.Premium} />}
      {isFiberOrder && <DelayedActivation />}
    </Layout>
  );
};

export default CampaignContent;
