import * as api from './api';
import * as selectors from './selectors';
import { types, creators } from './actions';
import { Channel } from '../../models/channel/Channel';
import { LoadingStatus } from '../../models/loading';

export interface ChannelsState {
  channelList: Channel[];
  status: LoadingStatus;
}

const INITIAL_STATE: ChannelsState = {
  channelList: [],
  status: LoadingStatus.Empty,
};

export { api, selectors, types, creators, INITIAL_STATE };
