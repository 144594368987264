import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const OnRouteChange: React.FC = ({ children }) => {
  const location = useLocation();

  /* Set scroll position to top when navigating
  We need to do this for the App element, as html and body is 100%,
  and our App element handles scrolling the content instead. 
  */
  useEffect(() => {
    const app = document.getElementById('app');
    if (app === null) {
      return;
    }
    try {
      app.scrollTo(0, 0);
    } catch {
      app.scrollTop = 0;
    }
  }, [location.pathname]);

  return <>{children}</>;
};

export default OnRouteChange;
