import React from 'react';
import { useSelector } from 'react-redux';
import BasketList from '../basket-list/BasketList';
import BasketListItem from '../basket-list/basket-list-item/BasketListItem';
import { basket, RootState } from '../../../../../store';
import { formatAmount } from '../../../../../utils/format';
import { ProductType } from '../../../../../models/product/ProductType';

import './simple-total.sass';

const SimpleTotal = () => {
  const summary = useSelector((state: RootState) => basket.selectors.getBasketSummary(state));
  const offerPeriods = useSelector((state: RootState) => basket.selectors.getOfferPeriodsInMonthsSorted(state));
  const hasPremiums = useSelector((state: RootState) => basket.selectors.hasPremiums(state));

  function getMonthIntervalName(index: number) {
    if (index === 0) {
      return `Per måned 1-${offerPeriods[index]}`;
    }
    if (offerPeriods[index] === undefined) {
      return `Per måned etter 12 måneder`;
    }
    return `Per måned ${offerPeriods[index - 1]! + 1}-${offerPeriods[index]}`;
  }

  return (
    <div className="simple-total">
      <BasketList>
        {summary.costIntervals.map((interval, index) => (
          <BasketListItem
            isTotal={true}
            name={getMonthIntervalName(index)}
            priceLabel={formatAmount(interval)}
            id={`Betala ${interval},-`}
          />
        ))}

        {summary.costNonRecurring !== 0 && (
          <BasketListItem
            isTotal={true}
            name="Engangskostnad utstyr (Første faktura)"
            priceLabel={formatAmount(summary.costNonRecurring)}
            id={'costNonRecurring'}
          />
        )}

        <div className="total-price">
          <BasketListItem
            isTotal={true}
            name="Totalt for første år"
            priceLabel={formatAmount(summary.costFirstYear)}
            id={'costFirstYear'}
          />
        </div>
        {hasPremiums && (
          <p className="metadata">Prisen for første år inkluderer 12 mnd med tilleggspakker. Disse kan du avbestille når som helst.</p>
        )}
      </BasketList>
    </div>
  );
};

export default SimpleTotal;
