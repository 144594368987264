import * as selectors from './selectors';
import { types, creators } from './actions';

export interface CustomerVerificationDetails {
  provider: string;
  token: string;
};

export interface CustomerVerificaitonState {
  verificationDetails: CustomerVerificationDetails;
  isVerified: boolean;  
}

export interface SetSelectedVerificationProvider {
  provider: string;
}

const INITIAL_STATE: CustomerVerificaitonState = {
  verificationDetails: {
    provider: '',
    token:''
  },
  isVerified: false,  
};

export { selectors, types, creators, INITIAL_STATE };
