import React from 'react';
import Select from 'react-select';

import CollapsibleStep from './collapsible-step/CollapsibleStep';
import { SanityPartnerConfiguration } from '../../../models/customer-filter/CustomerFilter';

import { selectCustomStyles } from './selectCustomStyles';
import { HousingType } from './CustomerFilter';
import { trackCustomerFilter } from '../../../sagas/trackingSaga';

interface Props {
  selectedPartnerConfig: SanityPartnerConfiguration | undefined;
  partnerConfigurations: SanityPartnerConfiguration[];
  selectedHousingType: HousingType | undefined;
  setPartnerConfig: (newSelection: SanityPartnerConfiguration) => void;
  handleHousingTypeChange: (newSelection: HousingType) => void;
}
export const IPFilter: React.FC<Props> = ({
  selectedPartnerConfig,
  partnerConfigurations,
  selectedHousingType,
  setPartnerConfig,
  handleHousingTypeChange,
}) => {
  const otherPartner: SanityPartnerConfiguration = {
    partnerName: 'Annen internettleverandør',
    partnerAlias: 'kampanje',
    enableMduSale: false,
  };
  const partnerOptions = [...partnerConfigurations, otherPartner];

  const handlePartnerConfigChange = (newSelection: SanityPartnerConfiguration | null) => {
    if (newSelection) {
      setPartnerConfig(newSelection);
      trackCustomerFilter(2, undefined, newSelection.partnerName);
    }
  };

  return (
    <>
      <CollapsibleStep
        title="Steg 2 - Hvilken leverandør får du internett fra?"
        completed={selectedPartnerConfig !== undefined}
        completedText={selectedPartnerConfig?.partnerName}
        animatedEntry
        className="z-index-two">
        <div className="vendor">
          <p>Velg et alternativ</p>

          <Select
            className="vendor-selector"
            value={selectedPartnerConfig}
            onChange={handlePartnerConfigChange}
            getOptionLabel={(sanityPartnerConfiguration: SanityPartnerConfiguration) => sanityPartnerConfiguration.partnerName}
            getOptionValue={(sanityPartnerConfiguration: SanityPartnerConfiguration) => sanityPartnerConfiguration.partnerAlias}
            options={partnerOptions}
            placeholder="Velg"
            isSearchable={false}
            styles={selectCustomStyles}
          />
        </div>
      </CollapsibleStep>

      {selectedPartnerConfig && selectedPartnerConfig.enableMduSale && (
        <CollapsibleStep
          title="Steg 3 - Tilhører du et borettslag eller et sameie?"
          completed={selectedHousingType !== undefined}
          completedText={selectedHousingType === 'MDU' ? 'Ja' : 'Nei'}
          animatedEntry
          className="z-index-one">
          <div className="housing">
            <p>Vi spør om dette for å kartlegge om ditt borettslag eller sameie har en kollektiv avtale med RiksTV</p>
            <div>
              <label onClick={() => handleHousingTypeChange('MDU')}>
                <input type="radio" name="housing" value="mdu" defaultChecked={selectedHousingType === 'MDU'} />
                Ja
              </label>
              <label onClick={() => handleHousingTypeChange('SDU')}>
                <input type="radio" name="housing" value="sdu" defaultChecked={selectedHousingType === 'SDU'} />
                Nei
              </label>
            </div>
          </div>
        </CollapsibleStep>
      )}
    </>
  );
};
