import React from 'react';
import CheckoutForm from './checkout-form/CheckoutForm';
import { Layout, Card, MainTitle } from '../../../shared';

import './checkout.sass';
import UserDataInformation from './user-data-information-box/UserDataInformation';

const Checkout = () => {
  return (
    <Layout focusOnRender={true} aria-label="Steg 4 av 5 - Kontaktinformasjon">
      <Card>
        <div style={{marginBottom: "12px"}}>
          <h2 className="title-3">Din kontaktinformasjon</h2>
        </div>
        <span>Vennligst fyll inn skjemaet med din kontaktinformasjon.</span>
      
        <UserDataInformation />
        <CheckoutForm />
      </Card>
    </Layout>
  );
};

export default Checkout;
