import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Button, Card, Icon, Layout } from '../../shared';
import CollapsibleStep from './collapsible-step/CollapsibleStep';
import GenericSubscriptions from './generic-subscriptions/GenericSubscriptions';
import { IPFilter } from './IPFilter';
import NotFound from '../not-found/NotFound';
import SupportFooter from '../../shared/support-footer/SupportFooter';

import { SanityPartnerConfiguration } from '../../../models/customer-filter/CustomerFilter';
import { trackCustomerFilter, TrackingPoints, trackSteps } from '../../../sagas/trackingSaga';

import { useGenericSubscriptions, usePartnerConfigurations } from './customerFilterHooks';
import { useIsMobileView, useMediaQuery } from '../../../hooks/useMediaQuery';
import { useTitle } from '../../../hooks/useTitle';
import { removePersistedStore } from '../../../store/configureStore';

import './customer-filter.sass';

type SignalType = 'IP' | 'DTT';
export type HousingType = 'SDU' | 'MDU';
export enum HousingTypeUrlParam {
  sdu = 'bolig',
  mdu = 'borettslag',
}

const CustomerFilter: React.FC = () => {
  useTitle('Bli kunde | RiksTV');
  const history = useHistory();
  const isMobileView = useIsMobileView();
  const supportFooterIsCompact = useMediaQuery('(max-width: 860px)');

  const { sanityGenericSubscriptions, sanityGenericSubscriptionsStatus } = useGenericSubscriptions();
  const { sanityPartnerConfigurations, sanityPartnerConfigurationsStatus } = usePartnerConfigurations();

  const [selectedSignalSource, setSelectedSignalSource] = useState<SignalType>();
  const [selectedPartnerConfig, setSelectedPartnerConfig] = useState<SanityPartnerConfiguration>();
  const [selectedHousingType, setSelectedHousingType] = useState<HousingType>();

  // Tracking to GA: Customer filter loads - equals step = 1
  useEffect(() => {
    removePersistedStore();    
    trackSteps(TrackingPoints.CustomerFilter);
  }, []);

  const resolveNextButtonUrl = () => {
    if (selectedSignalSource === 'DTT') {
      return `/kampanje/${HousingTypeUrlParam.sdu}`;
    }

    if (!selectedPartnerConfig?.enableMduSale) {
      return `/${selectedPartnerConfig?.partnerAlias}/${HousingTypeUrlParam.sdu}`;
    }

    if (selectedHousingType) {
      const housingParam = selectedHousingType === 'SDU' ? HousingTypeUrlParam.sdu : HousingTypeUrlParam.mdu;
      return `/${selectedPartnerConfig?.partnerAlias}/${housingParam}`;
    }

    return '';
  };

  const onNext = () => {
    const url = resolveNextButtonUrl();
    trackSteps(TrackingPoints.ProductComparison);
    history.push(url);
  };

  useEffect(() => {
    const app = document.getElementById('app');

    if (app === null) {
      return;
    }

    const supportFooterOffset = supportFooterIsCompact ? 212 : 0;
    const scrollToFooter = app.scrollHeight - window.innerHeight - supportFooterOffset;

    app.scrollTo(0, scrollToFooter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSignalSource, selectedPartnerConfig, selectedHousingType]);

  const handleResetFilter = () => {
    setSelectedSignalSource(undefined);
    setSelectedPartnerConfig(undefined);
    setSelectedHousingType(undefined);
  };

  const handleSelectSignalSource = (newSignalSource: SignalType) => {
    if (selectedSignalSource) {
      handleResetFilter();
    }
    setSelectedSignalSource(newSignalSource);
    trackCustomerFilter(1, newSignalSource);
  };

  const handleHousingTypeChange = (selectedHousingType: HousingType) => {
    setSelectedHousingType(selectedHousingType);
    trackCustomerFilter(3, undefined, undefined, selectedHousingType);
  };

  const isFilterCompletelyFilled =
    selectedSignalSource === 'DTT' ||
    (selectedSignalSource === 'IP' && selectedPartnerConfig && selectedHousingType) ||
    (selectedSignalSource === 'IP' && selectedPartnerConfig && !selectedPartnerConfig.enableMduSale);

  if (sanityGenericSubscriptionsStatus === 'error' || sanityPartnerConfigurationsStatus === 'error') {
    return <NotFound />;
  }

  return (
    <>
      <Layout focusOnRender wide={true} className="customer-filter main-layout">
        <h1 className="display-title">Bli kunde hos RiksTV</h1>

        <ul className="freebies">
          <li>
            <Icon type="box" className="box" size={2} />
            <h2 className="body-text">Gratis frakt</h2>
          </li>

          <li>
            <Icon type="gift" size={1.9} />
            <h2 className="body-text">Gratis prøvetid på strømmetjeneste</h2>
          </li>

          <li>
            <Icon type="streamingBox" size={1.8} fill="none" />
            <h2 className="body-text">Gratis TV-boks</h2>
          </li>
        </ul>

        <h2 className="title-1">Våre TV-pakker</h2>

        {sanityGenericSubscriptionsStatus === 'loaded' && <GenericSubscriptions genericSubscriptions={sanityGenericSubscriptions} />}

        <h2 className="title-1">Tilpass TV-pakken din</h2>
        <p className="text-ingress">For å kunne gi deg riktig pris og løsning, trenger vi litt informasjon.</p>

        <section className="wizard">
          <CollapsibleStep
            title="Steg 1 - Hvordan skal du se TV?"
            completed={selectedSignalSource !== undefined}
            completedText={selectedSignalSource === 'IP' ? 'Via internett' : 'Via antenne'}>
            <p className="instruction">Velg ett av alternativene:</p>

            <ul className="connection">
              <li className={selectedSignalSource === 'IP' ? 'selected' : ''}>
                <Card>
                  <h4 className="title-4">Jeg skal se TV via internett</h4>

                  <div className="paragraph-with-icon">
                    <Icon type="ethernetCable" size={1.6} fill="none" />
                    <p>For deg som har internett der du skal se TV.</p>
                  </div>

                  <div className="actions">
                    {selectedSignalSource === 'IP' ? (
                      <Button type="tertiary" onClick={handleResetFilter} aria-label="Angre valg av: Jeg skal se TV via internett">
                        Angre
                      </Button>
                    ) : (
                      <Button
                        type="secondary"
                        onClick={() => handleSelectSignalSource('IP')}
                        aria-label="Velg: Jeg skal se TV via internett">
                        Velg
                      </Button>
                    )}
                  </div>
                </Card>
              </li>

              <li className={selectedSignalSource === 'DTT' ? 'selected' : ''}>
                <Card>
                  <h4 className="title-4">Jeg skal se TV via antenne</h4>

                  <div className="paragraph-with-icon">
                    <Icon type="antenna" size={1.6} fill="none" />
                    <p>Dette gjelder for deg som ikke har internett der du skal se TV, og derfor kun skal se TV via antenne.</p>
                  </div>

                  <div className="actions">
                    {selectedSignalSource === 'DTT' ? (
                      <Button type="tertiary" onClick={handleResetFilter} aria-label="Angre valg av: Jeg skal se TV via antenne">
                        Angre
                      </Button>
                    ) : (
                      <Button
                        type="secondary"
                        onClick={() => handleSelectSignalSource('DTT')}
                        aria-label="Velg: Jeg skal se TV via antenne">
                        Velg
                      </Button>
                    )}
                  </div>
                </Card>
              </li>
            </ul>
          </CollapsibleStep>

          {selectedSignalSource === 'IP' && sanityPartnerConfigurationsStatus === 'loaded' && (
            <IPFilter
              selectedPartnerConfig={selectedPartnerConfig}
              partnerConfigurations={sanityPartnerConfigurations}
              selectedHousingType={selectedHousingType}
              setPartnerConfig={setSelectedPartnerConfig}
              handleHousingTypeChange={handleHousingTypeChange}
            />
          )}

          {isFilterCompletelyFilled && (
            <div className="proceed">
              <Button type="primary blue" iconMargin="right" onClick={onNext}>
                {isMobileView ? 'Se priser' : 'Se priser og velg pakke'}
                <Icon type="arrow" fill="white" size={1.5} />
              </Button>
            </div>
          )}
        </section>
      </Layout>

      <SupportFooter />
    </>
  );
};

export default CustomerFilter;
