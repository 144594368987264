import { Action, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CheckoutState, INITIAL_STATE } from '.';
import { creators } from './actions';

const onCheckoutSucceeded = (state: CheckoutState, action: PayloadAction<string>) => {
  state.receiptId = action.payload;
};

// FORM VALUES
//---------------
// TODO
const onUpdateFormValues = (state: CheckoutState, action: PayloadAction<{ values: any }>) => {
  state.formValues = action.payload.values;
};

const onUpdateFormMeta = (state: CheckoutState, action: PayloadAction<{ valid: boolean; pristine: boolean }>) => {
  state.isFormValid = action.payload.valid;
};

const onSubmitForm = (state: CheckoutState, _: Action) => {
  state.isSubmitFormPressed = true;
};

const onResetUserData = (state: CheckoutState, _: Action) => {
  state = INITIAL_STATE;
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(creators.checkoutSucceeded, (state, action) => onCheckoutSucceeded(state, action))
    .addCase(creators.updateFormValues, (state, action) => onUpdateFormValues(state, action))
    .addCase(creators.updateFormMeta, (state, action) => onUpdateFormMeta(state, action))
    .addCase(creators.submitForm, (state, action) => onSubmitForm(state, action))
    .addCase(creators.resetUserData, (state, action) => onResetUserData(state, action));
});
