import React from 'react';
import { SelectableItem } from '../../../../../../models/CampaignModel';
import { HatMode } from '../../../../../../models/product/HatConfig';

interface Props {
  product: SelectableItem;
}

const ProductCardHat: React.FC<Props> = ({ product }) => {
  const price = product.pricing;

  const {
    displayData: { hatConfig },
  } = product;
  const isReduced = price && price.originalPrice > 0 && price.originalPrice > price.price;

  const getDefaultLabel = () => {
    if (isReduced && price && price.price === 0 && price.isRecurring) return 'Prøv kostnadsfritt';
    else if (isReduced && price && price.price === 0 && !price.isRecurring) return 'Kostnadsfri';
    else if (isReduced) return 'Redusert pris';
    return '';
  };

  const getLabel = () => {
    if (!hatConfig) return getDefaultLabel();
    switch (hatConfig.hatMode) {
      case HatMode.Off:
        return '';
      case HatMode.Custom:
        return hatConfig.customHatText;
      default:
        return getDefaultLabel();
    }
  };

  return (getLabel() && <div className="tag product-card-hat">{getLabel()}</div>) || null;
};

export default ProductCardHat;
