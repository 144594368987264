import { useEffect, useState } from 'react';

import { getGenericSubscriptions, getPartnerConfigurations } from './api';
import { SanityGenericSubscription, SanityPartnerConfiguration } from '../../../models/customer-filter/CustomerFilter';

export const useGenericSubscriptions = () => {
  const [sanityGenericSubscriptions, setSanityGenericSubscriptions] = useState<SanityGenericSubscription[]>([]);
  const [sanityGenericSubscriptionsStatus, setSanityGenericSubscriptionsStatus] = useState<'loading' | 'loaded' | 'error'>('loading');
  useEffect(() => {
    const fetchGenericSubscriptions = async () => {
      return await getGenericSubscriptions();
    };

    fetchGenericSubscriptions()
      .then((res: SanityGenericSubscription[]) => {
        setSanityGenericSubscriptions(res);
        setSanityGenericSubscriptionsStatus('loaded');
      })
      .catch((err) => setSanityGenericSubscriptionsStatus('error'));
  }, [setSanityGenericSubscriptionsStatus]);

  return { sanityGenericSubscriptions, sanityGenericSubscriptionsStatus };
};

export const usePartnerConfigurations = () => {
  const [sanityPartnerConfigurations, setSanityPartnerConfigurations] = useState<SanityPartnerConfiguration[]>([]);
  const [sanityPartnerConfigurationsStatus, setSanityPartnerConfigurationsStatus] = useState<'loading' | 'loaded' | 'error'>('loading');

  useEffect(() => {
    const fetchPartnerConfigurations = async () => {
      return await getPartnerConfigurations();
    };

    fetchPartnerConfigurations()
      .then((res: SanityPartnerConfiguration[]) => {
        setSanityPartnerConfigurations(res);
        setSanityPartnerConfigurationsStatus('loaded');
      })
      .catch((err) => setSanityPartnerConfigurationsStatus('error'));
  }, [setSanityPartnerConfigurationsStatus]);

  return { sanityPartnerConfigurations, sanityPartnerConfigurationsStatus };
};
