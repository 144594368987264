import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ComparableSubscription } from '../../../../models/subscription-comparison/ComparableSubscriptions';
import { ComparisonCategory } from '../../../../models/subscription-comparison/ComparisonCategory';
import { PartnerIframeConfig } from '../../../../models/subscription-comparison/PartnerIframeConfig';
import { getPartnerIframeConfig } from '../../../routes/choose-subscription/api';
import { useIsMobileView } from '../../../../hooks/useMediaQuery';
import { useIsIframe } from '../../../../hooks/useIsIframe';
import { getIndicatorItem } from '../SubscriptionComparison';
import { IframeButtonGroup } from './IframeButtonGroup';

import './overview-list.sass';

interface Props {
  comparableSubscriptions: ComparableSubscription[];
  comparisonCategories: ComparisonCategory[];
  partnerAlias: string;
  dwellingUnit: string;
}

export const OverviewList: React.FC<Props> = ({ comparableSubscriptions, comparisonCategories, partnerAlias, dwellingUnit }) => {
  const isMobileView = useIsMobileView();
  const isIframe = useIsIframe();
  const [iframeConfig, setIframeConfig] = useState<PartnerIframeConfig | null>(null);

  const getMobileCategoryItem = (subscription: ComparableSubscription, category: ComparisonCategory, categoryIndex: number) => {
    if (category.displayOnMobile) {
      return (
        <li key={categoryIndex}>
          <h3 className={`${category.icon} body-text`}>{category.name}</h3>

          <div className="indicator">{getIndicatorItem(subscription.featureIndicators[categoryIndex])}</div>
        </li>
      );
    }
  };

  useEffect(() => {
    if (isIframe) {
      getPartnerIframeConfig(partnerAlias).then((res: PartnerIframeConfig) => {
        setIframeConfig(res);
      });
    }
  }, [partnerAlias, isIframe]);

  return (
    <ul className="subscriptions">
      {comparableSubscriptions.map((subscription, index) => (
        <li key={index}>
          <div className="comparable-subscription">
            <h2 className="name title-3">{subscription.name}</h2>

            <p className="price title-2">
              {subscription.currentPrice},-
              <span className="sub-body">/mnd. i {subscription.offerPeriodInMonth} mnd*</span>
            </p>

            {subscription.optionalPriceTextMainSubscription ? 
              <p className="terms metadata">{`${subscription.optionalPriceTextMainSubscription}`}</p> :
              subscription.originalPrice && <p className="terms metadata">{`Originalpris ${subscription.originalPrice},-/mnd`}</p>
            }

            <p className="description sub-body">{subscription.description}</p>

            {isMobileView && (
              <ul className="features">
                {comparisonCategories.map((category, categoryIndex) => getMobileCategoryItem(subscription, category, categoryIndex))}
              </ul>
            )}

            {iframeConfig && <IframeButtonGroup iframeConfig={iframeConfig} partnerAlias={partnerAlias} subscription={subscription} />}

            {!iframeConfig && (
              <div className="actions">
                <Link
                  to={`/${partnerAlias}/${dwellingUnit}/${subscription.campaignUrl}`}
                  className="button-base primary"
                  aria-label={`Bestill ${subscription.name}`}>
                  Bestill nå
                </Link>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
