import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormSpy } from 'react-final-form';

import { checkout } from '../../../../../../store';

const FormMetaToRedux = ({ updateFormMeta }) => (
  <FormSpy subscription={{ valid: true, pristine: true }} onChange={(state) => updateFormMeta(state)} />
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFormMeta: checkout.creators.updateFormMeta,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FormMetaToRedux);
