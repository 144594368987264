import React from 'react';
import { useSelector } from 'react-redux';
import { campaign, RootState } from '../../../../../store';
import DefaultBlockContent from '../../../../shared/default-block-content/DefaultBlockContent';
import ChannelsContainer from '../channels/ChannelsContainer';

import './subscription-card.sass';

const SubscriptionCard: React.FC = () => {
  const { subscription } = useSelector((state: RootState) => mapStateToProps(state));
  const displayData = subscription?.displayData;
  return (
    <div className="subscription">
      <h2 className="title">Innhold i {displayData?.name}</h2>
      <div className="column description-column">
        {displayData && (
          <DefaultBlockContent className="description rich-text" blocks={displayData.description} renderContainerOnSingleChild />
        )}
      </div>
      <div className="column channel-column">
        <ChannelsContainer />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  subscription: campaign.selectors.getMainSubscription(state),
});

export default SubscriptionCard;
