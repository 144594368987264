import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useIsIframe = () => {
  const query = useQuery();
  const iframeQueryParam = query.get('iframe');

  const [isIframe, setIsIframe] = useState<boolean>(false);

  useEffect(() => {
    setIsIframe(iframeQueryParam === 'true');
  }, [iframeQueryParam]);

  return isIframe;
};
