import React from 'react';
import { ComparableSubscription } from '../../../../models/subscription-comparison/ComparableSubscriptions';
import { ComparisonCategory } from '../../../../models/subscription-comparison/ComparisonCategory';
import { getIndicatorItem } from '../SubscriptionComparison';

import './features-table.sass';

interface Props {
  comparableSubscriptions: ComparableSubscription[];
  comparisonCategories: ComparisonCategory[];
}

export const FeaturesTable: React.FC<Props> = ({ comparableSubscriptions, comparisonCategories }) => {
  return (
    <table className="comparison-table" aria-label="Produktsammenligningstabell">
      <tbody>
        <tr className="subscription-names-wcag">
          <th scope="col">Egenskap</th>

          {comparableSubscriptions.map((subscription, index) => (
            <th key={index} scope="col">
              {subscription.name}
            </th>
          ))}
        </tr>

        {comparisonCategories.map((category, categoryIndex) => (
          <tr key={categoryIndex}>
            <th scope="row">
              <div className="feature-name">
                <span className={`feature ${category.icon} sub-body`}>{category.name}</span>
              </div>
            </th>

            {comparableSubscriptions.map((subscription, subscriptionIndex) => (
              <td key={subscriptionIndex}>
                <div className="indicator">{getIndicatorItem(subscription.featureIndicators[categoryIndex])}</div>
              </td>
            ))}
          </tr>
        ))}

        <tr className="bottom-row">
          <td />
          <td>
            <div />
          </td>
          <td>
            <div />
          </td>
          <td>
            <div />
          </td>
          <td>
            <div />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
