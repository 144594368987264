import React from 'react';
import config from '../../../config';
import { ExternalLink } from '..';
import { ProductType } from '../../../models/product/ProductType';

import './product-limit.sass';
import { useGroupConfig } from '../../routes/campaign/components/product-group/ProductGroup';

const getMessage = (type: ProductType) => {
  switch (type) {
    case ProductType.Equipment:
      return 'Du har nå valgt maks antall antenner.';
    case ProductType.Receiver:
      return 'Du har nå valgt maks antall dekodere.';
    default:
      return 'Du har nå valgt maks antall produkter.';
  }
};

const shouldShowMessage = (type: ProductType) => type === ProductType.Equipment || type === ProductType.Receiver;

const ProductLimit: React.FC = () => {
  const { type } = useGroupConfig();
  if (!shouldShowMessage(type)) {
    return null;
  }

  return (
    <div className="product-limit error">
      {getMessage(type)} <br />
      For å bestille flere, <ExternalLink href={config.chatLink}>start en chat</ExternalLink> eller ring oss på{' '}
      <a href="tel:21010212">210 10 212</a>.
    </div>
  );
};
export default ProductLimit;
