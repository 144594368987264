import React from 'react';
import { PartnerIframeConfig } from '../../../../models/subscription-comparison/PartnerIframeConfig';
import { ComparableSubscription } from '../../../../models/subscription-comparison/ComparableSubscriptions';

interface IframeButtonGroupProps {
  iframeConfig: PartnerIframeConfig | null;
  partnerAlias: string;
  subscription: ComparableSubscription;
}

export const IframeButtonGroup: React.FC<IframeButtonGroupProps> = ({ iframeConfig, partnerAlias, subscription }) => {
  /* If iframe, we might have a specific setup in Sanity for the current partner */

  return (
    <div className="actions">
      <a
        href={iframeConfig?.bestillButtonUrl}
        target="_parent"
        className="button-base primary"
        aria-label={`Bestill ${subscription.name} via ${partnerAlias} sine sider`}>
        Bestill nå
      </a>
    </div>
  );
};
